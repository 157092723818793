import { createAction, props } from '@ngneat/effects';

export const updatedItems = createAction('[CartService] Update ');
export const proposeDefaultAddress = createAction(
  '[CartService] DefaultAddress',
  props<{ billingAddress: any; shippingAddress: any }>()
);
export const proposePhoneNumber = createAction(
  '[CartService] proposePhoneNumber',
  props<{ phone: string }>()
);
export const dataChanged = createAction(
  '[CartService] dataChanged',
  props<{ data: any }>()
);
export const addressesChanged = createAction(
  '[CartService] AddressChanged',
  props<{ billingAddress: any; shippingAddress: any; sameAddress?: boolean }>()
);

export const subscriptionChanged = createAction(
  '[CartService] SubscriptionChanged',
  props<{ subscriptionAction: string }>());
