import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionVacationService } from '@app/shared/data-subscription';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import {
  SubscriptionVacationEditModalComponent
} from '../subscription-vacation-edit-modal/subscription-vacation-edit-modal.component';
import { BehaviorSubject, mergeMap } from 'rxjs';
@Component({
  selector: 'app-subscription-vacation',
  standalone: true,
  imports: [CommonModule, AgGridModule, MatButtonModule],
  templateUrl: './subscription-vacation.component.html',
  styleUrls: ['./subscription-vacation.component.scss'],
})
export class SubscriptionVacationComponent {
  subVacService = inject(SubscriptionVacationService);
  reload$ = new BehaviorSubject(true);

  vacations$ =  this.reload$.pipe(mergeMap( () =>this.subVacService.getVacations()));
  rowData: any[] | null | undefined;
  columnDefs: (ColGroupDef<any> | ColDef<any>)[] | null | undefined = [
    { field: 'id', sortable: true, filter: true ,minWidth: 10},
    { field: 'customer_id', sortable: true, filter: true ,minWidth: 10},
    { field: 'locked', sortable: true, filter: true, minWidth: 10 },
    { field: 'start_date', sortable: true, filter: true },
    { field: 'end_date', sortable: true, filter: true },

  ];
  rowSelection = 'single' as const;

  onGridReady($event: GridReadyEvent<any>) {
    this.vacations$.subscribe((data) => {
      $event.api.setRowData(data);
      $event.api.sizeColumnsToFit();
    });
    this.api = $event.api;

  }

  private api? : GridApi = undefined;

  modal = inject(MatDialog);
  onEdit() {
    const selected = this.api?.getSelectedRows();
    if (selected && selected.length > 0) {
      const ref = this.modal.open(SubscriptionVacationEditModalComponent, {
        data: selected[0],
      });
      ref.afterClosed().subscribe((result) => {
        if (result?.action === 'save') {
          this.subVacService.updateVacation(selected[0].id, result.data).subscribe(() => this.reload$.next(true));
        }
      });
    }
  }

  onDelete() {
    const selected = this.api?.getSelectedRows();
    //confirm
    const result = confirm('Are you sure you want to delete this vacation?');
    if (result && selected && selected.length > 0) {
      this.subVacService.deleteVacation(selected[0].id).subscribe(() => this.reload$.next(true));
    }

  }

  onAdd() {
  this.modal.open(SubscriptionVacationEditModalComponent, {

  }).afterClosed().subscribe((result) => {
    if (result?.action === 'save') {
      this.subVacService.createVacation(result.data).subscribe(() => this.reload$.next(true));
    }
  });
  }
}
