import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WarehouseService } from '@app/shared/data-stockmanaging';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { mergeMap, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-warehouse-edit',
  templateUrl: './warehouse-edit.component.html',
  styleUrls: ['./warehouse-edit.component.scss'],
})
export class WarehouseEditComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  warehouse$;

  form = new UntypedFormGroup({});

  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      templateOptions: {
        label: 'Lagerbezeichnung',
        placeholder: 'Lagername',
        required: true,
      },
    },
  ];

  constructor(
    private ws: WarehouseService,
    private route: ActivatedRoute,
    private _snack: MatSnackBar
  ) {
    this.warehouse$ = this.route.paramMap.pipe(
      mergeMap((r: any) => {
        if (r.params.id === 'new') {
          return of({});
        }
        this.ws.loadById(r.params.id).subscribe();
        return this.ws.selectWarehouse(r.params.id);
      })
    );
  }

  ngOnInit(): void {}

  onSubmit(model: any) {
    this.ws.upsertWarehouse(model).subscribe(() => {
      this._snack.open('Erfolgreich gespeichert', '', {});
    });
  }
}
