import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UiModule } from './ui/ui.module';
import { APP_CONFIG, RequestModule } from '@pisci/requestManager';
import { DB_CONFIG } from './app.config';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { NetworkInterceptor } from './network.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ProductBatchEditComponent } from './ui/products/product-batch-edit/product-batch-edit.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MenuComponent } from './ui/menu/menu.component';


@NgModule({
  declarations: [AppComponent, ProductBatchEditComponent, MenuComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RequestModule,
    MatProgressSpinnerModule,

    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      enableTracing: true
    }),
    UiModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    AgGridModule,
    MatLegacyButtonModule,
    MatLegacyFormFieldModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    FormsModule
  ],
  providers: [
    { provide: APP_CONFIG, useValue: DB_CONFIG },
    { provide: LOCALE_ID, useValue: 'de-AT' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
