import { Component } from '@angular/core';
interface MenuItem {
  label: string;
  routerLink: string;
  subMenu?: MenuItem[];
  showSubMenu?:boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  menuItems: MenuItem[] = [
    { label: 'Produkte', routerLink: 'products' },
    { label: 'Kunden', routerLink: 'customers' },
    { label: 'Kategorien', routerLink: 'categories' },
    { label: 'Tags', routerLink: 'tags' },
    { label: 'Produzenten', routerLink: 'producers' },
    { label: 'Bestellungen', routerLink: 'orders' },
    { label: 'Rechnungen', routerLink: 'invoices' },
    { label: 'Abos', routerLink: 'subscriptions', subMenu: [
      { label: 'Urlaubszeiten', routerLink: 'sub_vacations' },
      { label: 'Aktionen', routerLink: 'sub_actions' }
        ] },
    { label: 'Exporte', routerLink: 'exports' },
    { label: 'Berichte', routerLink: 'reports' },
    { label: 'Gutscheine / Rabattcodes', routerLink: 'vouchers' },
    { label: 'Seiten', routerLink: 'pages', subMenu: [
      { label: 'Seiten', routerLink: 'pages' },
      { label: 'Special Templates', routerLink: 'pages/special_templates' }
        ] },

    { label: 'Versandoptionen', routerLink: 'shipmentOptions' },
    { label: 'Lagerverwaltung', routerLink: 'stockmanaging' },
    { label: 'Verpackungsgruppen', routerLink: 'packaging_groups' },
    {
      label: 'Einstellungen',
      routerLink: 'settings',
      subMenu: [
        { label: 'Email Einstellungen', routerLink: 'settings/email' }
      ]
    }
  ];

  showMenu = true;
  menuToggle() {
    this.showMenu = !this.showMenu;
  }
}
