<h1>Kunde</h1>
<ng-container *ngIf="customer$|async as customer">
  <mat-tab-group>
    <mat-tab label="Basis">
  <h4>Basisdaten:</h4>
  <cw-base-view-customer [customer]="customer"></cw-base-view-customer>
  <h4>Bestelldetails:</h4>
  <dl>
    <dt>Gesamtumsatz</dt>
    <dd>{{customer.orderCashSum | currency:'EUR'}}</dd>
    <dt>Anzahl Bestellungen</dt>
    <dd> {{customer.orderCount}}</dd>
  </dl>
    </mat-tab>
    <mat-tab label="Abo / Customer Order">
  <ng-container *ngIf="{ subs: subscriptions$|async, products: products$|async } as o">

    <h4>Abos</h4>
    <cw-base-subscription-list *ngIf="o.subs && o.products" [subscriptions]="o.subs" [products]="o.products" (editClicked)="editSubscription($event)"></cw-base-subscription-list>


</ng-container>
  <button class="pisci-btn" (click)="openProductSearchModal()">Produktsuchen und hinzufügen</button>
  <app-admin-order-for-customer [customerId]="customer.id"></app-admin-order-for-customer>
  <button class="pisci-btn" (click)="orderForCustomer()">Für Kunde Bestellen</button>
    </mat-tab>
    <mat-tab label="Aktionen">
       <button (click)="clickChangePassword(customer)">Password Ändern</button>
      <button (click)="logMeInAs(customer)">Im Frontend einloggen</button>
    </mat-tab>
  </mat-tab-group>
</ng-container>
