import { UntypedFormControl, Validators } from '@angular/forms';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { isNumber } from 'lodash';
import { IProduct } from '@app/data-interfaces';
import { CartService } from '@app/shared/data-cart2';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import { HookService } from '@app/base-services';
import { take } from 'rxjs';

@Directive({})
export abstract class AbstractAddToCart {
  @Input()
  subscriptionEnabled = false;
  @Input()
  product?: IProduct;
  @Input()
  allowNegative?: boolean = false;
  @Output()
  subsriptionClicked = new EventEmitter();

  disabled = false;
  freshlyAdded = false;
  minValue = 1;
  maxValue: number | string | null = null;
  amountControl = new UntypedFormControl('1', [Validators.required]);

  constructor(private cartService: CartService, private dialog: MatDialog, private hookService: HookService) {
  }

  ngOnInit(): void {
    this.amountControl.valueChanges.subscribe((value) => {
      if (value == '') {
        this.amountControl.setValue(0);
      }
    });
    if (this.allowNegative === false) {
      this.amountControl.setValidators([
        Validators.required,
        Validators.min(1)
      ]);
    }

    if (this.product?.stock_amount) {
      this.amountControl.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(this.product?.stock_amount)
      ]);
      this.maxValue = this.product?.stock_amount;
    }

    if (this.product?.stock_status == 'out_of_stock') {
      this.amountControl.setValue('0');
      this.amountControl.disable();
      this.disabled = true;
    }
  }

  lostFocus(element: any) {
    if (!isNumber(element.value)) {
      element.setValue(1);
    }
    if (this.maxValue && element.value > this.maxValue) {
      element.setValue(this.maxValue);
    }

    if (this.minValue && element.value < this.minValue) {
      element.setValue(this.minValue);
    }
  }

  clickAdd() {
    if (this.disabled) {
      return;
    }
    if (this.product) {
      this.cartService.addProductQuantity(
        this.product,
        this.amountControl.value
      );
      this.freshlyAdded = true;
      setTimeout(() => {
        this.freshlyAdded = false;
      }, 1000);
    }
  }

  clickLeft() {
    if (this.disabled) {
      return;
    }
    if (this.amountControl.value <= 1) {
      return;
    }
    this.amountControl.setValue(
      Number.parseFloat(this.amountControl.value) - 1
    );
  }

  clickRight() {
    if (this.disabled) {
      return;
    }
    this.amountControl.setValue(
      Number.parseFloat(this.amountControl.value) + 1
    );
  }

  clickAbo() {
    this.subsriptionClicked.next(this.amountControl.value);
    this.hookService.isLoggedIn().pipe(take(1)).subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        return;
      }
      const dialogRef = this.dialog.open(SubscriptionModalComponent, {
        data: {
          product: this.product,
          quantity: this.amountControl.value
        }
      });


    });
  }
}
