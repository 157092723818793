<p>{{customer.email}}</p>
<form [formGroup]="passwordForm">
  <mat-form-field>
    <input matInput placeholder="Neues Passwort" formControlName="password">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Passwort Wiederhole" formControlName="password_confirm">
  </mat-form-field>

  <button mat-raised-button (click)="save()">Speichern</button>

</form>
