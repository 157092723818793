import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { Observable, debounceTime, mergeMap } from 'rxjs';

@Component({
  selector: 'app-modal-product-search',
  templateUrl: './modal-product-search.component.html',
  styleUrls: ['./modal-product-search.component.scss'],
})
export class ModalProductSearchComponent implements OnInit {
  results$: Observable<any[]>;
  searchControl = new UntypedFormControl();
  admin = false;
  constructor(
    private productCatalog: ProductCatalogService,
    private ref: MatDialogRef<ModalProductSearchComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data.admin) {
      this.admin = data.admin;
    }
    if (this.admin === true) {
      this.results$ = this.searchControl.valueChanges.pipe(
        debounceTime(1000),
        mergeMap((v) => this.productCatalog.searchProductAll(v))
      );
    } else {
      this.results$ = this.searchControl.valueChanges.pipe(
        debounceTime(1000),
        mergeMap((v) => this.productCatalog.searchProduct(v))
      );
    }
  }

  ngOnInit(): void {}

  clickResult(data: any) {
    this.ref.close(data);
  }
}
