import { Injectable } from '@angular/core';
import {IProduct, Product} from '@app/data-interfaces';
import { createState, createStore, withProps } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntity,
  selectMany,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { RequestService } from '@pisci/requestManager';
import { Observable, tap } from 'rxjs';

export interface IProductPostBody {
  name: '';
  sku: '';
  data: any;
  status: string;
  description: string;
  short_description: string;
  price?: {
    amountNet: number;
    amountGross: number;
    taxes: { [key: number]: number };
  };
  price_id?: number;
}

const productStore = createStore(
  { name: 'products' },
  withEntities<Product>()
);

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private rm: RequestService) {}

  loadProduct(id: number) {
    return this.rm.get('/product/' + id).pipe(
      tap((response: any) => {
        response = response.map((product: any) => {
          return new Product(product);
        })
        productStore.update(upsertEntities([response]));
      })
    );
  }
  select() {
    return productStore.pipe(selectAllEntities());
  }

  selectProducts() {
    return productStore.pipe(selectAllEntities());
  }

  load() {
    return this.rm.getAllPages('/product').pipe(
      tap((response: any) => {
        response = response.map((product: any) => {
          return new Product(product);
        })
        productStore.update(upsertEntities(response));
      })
    );
  }

  loadAdmin() {
    return this.rm
      .getAllPages('/product?query=all&include=tags,producers')
      .pipe(
        tap((response: any) => {
          response = response.map((product: any) => {
            return new Product(product);
          })
          productStore.update(upsertEntities(response));
        })
      );
  }
  selectById(id: number) {
    return productStore.pipe(selectEntity(id));
  }

  selectByIds(ids: number[], passing_params?: any) {
    return productStore.pipe(selectMany(ids, passing_params));
  }

  loadByIds(ids:number[]) {
    return this.rm.post('/product/search', {
      filters: [{field:'id',
      operator:'in',
      value: ids}]

    }).pipe(
      tap((response: any) => {
        let products = response.data.map((product: any) => {
          return new Product(product);
        });
        productStore.update(upsertEntities(products));
      })
    );
  }

  getProduct(id: number) {
    return this.rm.get('product/' + id) as Observable<IProduct>;
  }
  getProducts() {
    return this.rm.getProducts(); //('/product');
  }

  getProductForAdmin(id: number):Observable<IProduct> {
    return this.rm.get('/product/' + id + '?query=all&include=tags,producers'); //('/product');
  }
  getProductsForAdmin() {
    return this.rm.getAllPagesExpanding('/product?query=all&include=tags,producers', ``,200); //('/product');
  }

  getProductWithStocks(productId: any) {
    return this.rm.get(
      'product/' + productId + '?include=stocks,stocks_history,producers'
    );
  }
  createProduct(body: IProductPostBody) {
    return this.rm.post('/product', body);
  }

  updateProduct(id: number, body: Partial<IProduct>) {
    return this.rm.put('/product/' + id, body);
  }

  uploadImage(product: any, image: File) {
    const data = new FormData();
    data.append('img', image);
    data.append('product_id', product.id);
    return this.rm.post('/product/' + product.id + '/images', data);
  }

  attachProductToCategories(product: any, categoryIdArray: number[]) {
    return this.rm.post('/product/' + product.id + '/categories/attach', {
      resources: categoryIdArray,
    });
  }

  searchProductsForAdmin(searchText: any) {

    return this.rm.searchAllPagesExpanding('product/search?query=all&include=tags,producers&limit=100',{
      sort: [{
        field: 'id', direction: 'desc'
      }],
      scopes: [
        { name: 'fullTextSearch', parameters: [searchText] }
      ]

    });

  }

  batchEdit(ids: any[], product: any) {
    /**
     * // (PATCH) https://myapp.com/api/posts/batch
     * {
     *     "resources" : {
     *         "5" : {
     *             "title" : "My Post 1 (updated)",
     *             "body" : "Example updated body text"
     *         },
     *         "6": {
     *             "title" : "My Post 2",
     *             "body" : "Example body text"
     *         }
     *     }
     * }
     */
    return this.rm.patch('product/batch', {
      resources: ids.reduce((acc, id) => {
        acc[id] = product;
        return acc;
      }, {}),
    });
    }


}
