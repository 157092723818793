import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISubscriptionLineItemDTO } from '@app/data-interfaces';
import { CartService } from '@app/shared/data-cart2';

import { isString } from '@ngneat/elf';

@Component({
  selector: 'app-cart-subscription-quantity-modal',
  templateUrl: './cart-subscription-quantity-modal.component.html',
  styleUrls: ['./cart-subscription-quantity-modal.component.scss'],
})
export class CartSubscriptionQuantityModalComponent {
  readonly subscriptionLine: ISubscriptionLineItemDTO;
  readonly data = inject(MAT_DIALOG_DATA);
  readonly ref = inject(MatDialogRef<CartSubscriptionQuantityModalComponent>);
  readonly cartService = inject(CartService);
  intervalQuantityForm = new FormGroup<{ quantity:FormControl<string|number> }>({
    quantity: new FormControl(),
  });
  constructor() {
    this.subscriptionLine = this.data.subscriptionLine;

    this.intervalQuantityForm.patchValue({
      quantity: this.subscriptionLine.quantity,
    });
  }
  clickSave() {
    this.cartService.updateSubscriptionLineQuantity(
      this.subscriptionLine,
      isString(this.intervalQuantityForm.value.quantity)?Number.parseFloat(this.intervalQuantityForm.value.quantity):this.intervalQuantityForm.value.quantity??0,
    );
    this.ref.close();
  }
  clickPause() {
    this.intervalQuantityForm.patchValue({quantity: 0});
    this.clickSave();
  }

  clickClose() {
    this.ref.close();
  }
}
