import { Component, OnInit } from '@angular/core';
import { PageService } from '@app/shared/data-pages';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  pages$: Observable<any[]>;
  constructor(private ps: PageService) {
    this.pages$ = ps.selectPages();
  }

  ngOnInit(): void {}
}
