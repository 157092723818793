import { Component, inject, OnInit } from '@angular/core';
import { ICategory, IVoucher } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { VoucherService } from '@app/shared/data-vouchers';
import { debounceTime, Observable } from 'rxjs';
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent {
  editingCategory?: ICategory;
  addingCategory = false;
  vouchers$: Observable<any[]>;
  private router = inject(Router);
  constructor(private voucherService: VoucherService) {
    this.vouchers$ = this.voucherService.getVouchers();
  }


  getType(voucher: IVoucher) {
    return this.voucherService.getTypeLiteral(voucher);
  }


  getRowId(params: any) {
    return params.data.id;
  }

  onCellClicked(e: CellClickedEvent): void {
    // this.router.navigateByUrl('invoice/'+e.data.id);
  }
  private gridApi!: GridApi;
  public columnDefs: ColDef[] = [
    { field: 'id' },
    { field: 'code' },
    { field: 'type' },
    { field: 'maximum_uses' },
    { field: 'use_count' },
    { field: 'value' },
    { field: 'created_at' },
    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/vouchers/' + data.id);
            }
          },
          {
            label: 'Orders',
            action: (data: any) => {
              this.router.navigateByUrl('/vouchers/' + data.id + '/orders');
            }
          }
        ]
      }
    }
]
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.vouchers$.pipe(debounceTime(1)).subscribe(values => {
      this.gridApi.setGridOption("rowData",values);
      this.gridApi.sizeColumnsToFit()
    });
  }

}
