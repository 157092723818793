import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { SubscriptionService } from '@app/shared/data-cart2';
import { map, Observable } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { DataTableActionButtonComponent, MatContextMenuTrigger } from '@cw/ui-core';
import { Router } from '@angular/router';
import { ProductService } from '@app/shared/data-products';
import { SubscriptionMenuComponent } from '../subscription-menu/subscription-menu.component';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [CommonModule, AgGridModule, MatMenuModule, MatContextMenuTrigger, SubscriptionMenuComponent],
  providers:[ ProductService],
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent {
  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { field: 'id' },

    {
      field: 'product',
      valueGetter: (params) => this.getProductName(params)
    },
    { field: 'customer', valueGetter: (params) => this.getCustomerName(params) },
    { field: 'next_order' },
    { field: 'interval_days' },
    { field: 'quantity' },
    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/subscriptions/' + data.id);
            }
          },
          {
            label: 'Kunde',
            action: (data: any) => {
              this.router.navigateByUrl('/customers/' + data.customer_id);
            }
          }
        ]
      }
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private service: SubscriptionService, private router: Router, private productService: ProductService) {
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.service.selectSubscriptions();
    this.service.loadAllSubscriptions().subscribe();
    this.agGrid.api.sizeColumnsToFit()
  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  getProductName(params: any) {
   return params.data.product.name;

  }
  getCustomerName(params: any) {
    return params.data.customer.firstname + ' ' + params.data.customer.lastname;
  }
}
