import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsvalueService } from '@cw/data-settings';
import { take } from 'rxjs/operators';
import { LoadingService } from '../../loading.service';
import { finalize, map } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pages-special-templates',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIcon, MatButton, MatSnackBarModule],
  templateUrl: './pages-special-templates.component.html',
  styleUrl: './pages-special-templates.component.scss',
})
export class PagesSpecialTemplatesComponent {
  settingsValueService = inject(SettingsvalueService);
  specialTemplates$ = this.settingsValueService
    .selectKey('custom_template')
    .pipe(map((v) => v.data.map((t: any) => JSON.stringify(t))));
  loadingService = inject(LoadingService);
  snackbar = inject(MatSnackBar)
  constructor() {
    this.settingsValueService.loadKey('custom_template').subscribe();
  }
  clickSave(st: any[]) {
    this.loadingService.setLoading(true);
    try {
      this.settingsValueService
        .saveSetting(
          'custom_template',
          st.map((v) => JSON.parse(v)),
        ).pipe(finalize(() => this.loadingService.setLoading(false)))
        .subscribe(() => {

        });
    }
   catch(e:any) {
      this.snackbar.open(
        'Error: ' + e.message,
        'Close',
        {
          duration: 3000,
        }
      )
    this.loadingService.setLoading(false);
   }
  }
  clickAdd(st: any) {
    st.push('');
  }

  clickDel(st: any[], $index: number) {
    st.splice($index, 1);
  }
}
