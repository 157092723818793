import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ProductService } from '@app/shared/data-products';
@Component({
  selector: 'app-product-batch-modal',
  templateUrl: './product-batch-modal.component.html',
  styleUrls: ['./product-batch-modal.component.scss'],
  imports: [
    MatInputModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule
  ],
  standalone: true
})
export class ProductBatchModalComponent {
  countProducts = 0;
  ids = [];

  product:any = {};
  productService = inject(ProductService);
  private dialogref =  inject(MatDialogRef);
  constructor(@Inject(MAT_DIALOG_DATA) data:any) {
    console.log(data);
    this.countProducts = data.ids.length;
    this.ids = data.ids;
  }

  save() {
    const body:any = {};
    const keys = Object.keys(this.product);
    keys.forEach((key) => {
      if(this.product[key] != null) {
        body[key] = this.product[key];
      }
    });
       this.productService.batchEdit(this.ids, body).subscribe(() => {
      this.dialogref.close();
    });
  }

  close() {
    this.dialogref.close();
  }
}
