import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl, ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { isNumber } from 'lodash';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-number-button-input',
  templateUrl: './number-button-input.component.html',
  styleUrls: ['./number-button-input.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule]

})
export class NumberButtonInputComponent implements ControlValueAccessor, OnInit, Validator {
  @Input()
  allowNegative?: boolean = false;
  @Output()
  clicked = new EventEmitter();
  disabled = false;
  freshlyAdded = false;
  minValue = 1;
  maxValue: number | string | null = null;
  amountControl = new FormControl<string>('1', [Validators.required]);

  constructor(@Optional() @Self() public controlDir: NgControl) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }

  }

  ngOnInit(): void {
    this.amountControl.valueChanges.subscribe((value: any) => {
      this._onChange(value);
      this._onTouched();
    });
  }

  public registerOnChange(fn: (value: number | null) => undefined): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  public writeValue(value: any): void {
    this.amountControl.setValue(value);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return Validators.required(control);
  }

  lostFocus(element: any) {
    if (!isNumber(element.value)) {
      element.setValue(1);
    }
    if (this.maxValue && element.value > this.maxValue) {
      element.setValue(this.maxValue);
    }

    if (this.minValue && element.value < this.minValue) {
      element.setValue(this.minValue);
    }
  }

  clickLeft() {
    if (this.disabled) {
      return;
    }
    if (Number.parseFloat(this.amountControl.value ?? '2') <= 1) {
      return;
    }
    this.amountControl.setValue(
      (Number.parseFloat(this.amountControl.value ?? '') - 1).toString()
    );
  }

  clickRight() {
    if (this.disabled) {
      return;
    }
    this.amountControl.setValue(
      (Number.parseFloat(this.amountControl.value ?? '') + 1).toString()
    );
  }

  private _onChange = (value: number | null) => undefined;

  private _onTouched = () => undefined;
}

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor =
  {
    writeValue(): void {
    },
    registerOnChange(): void {
    },
    registerOnTouched(): void {
    }
  };
