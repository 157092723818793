import { Component, OnInit } from '@angular/core';
import { AbstractAddToCart } from '../abstract-add-to-cart';

@Component({
  selector: 'addtocartmini',
  templateUrl: './addtocartmini.component.html',
  styleUrls: ['./addtocartmini.component.scss'],
})
export class AddtocartminiComponent extends AbstractAddToCart implements OnInit {

}
