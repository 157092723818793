import { Injectable } from '@angular/core';

import { ICart } from '@pisci/data-cart';
import { RequestService } from '@pisci/requestManager';
import { map, tap } from 'rxjs';
import { InvoiceQuery } from './state/invoice.query';
import { InvoiceStore } from './state/invoice.store';
import { Order } from '@app/data-interfaces';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(
    private rm: RequestService,
    private store: InvoiceStore,
    private query: InvoiceQuery
  ) {
  }

  createInvoiceFromCart(cart: ICart, data?: any) {
    let body;
    if (data) {
      body = { ...cart, ...data };
    } else {
      body = { ...cart };
    }
    return this.rm.post('invoice', body).pipe(
      map((resp: any) => {
        this.store.update({ currentInvoice: resp });
        return resp;
      })
    );
  }

  update(id: number | string, body: any) {
    return this.rm.put('invoice/' + id, body).pipe(
      tap(() => {
        //     this.loadOrder(id).subscribe();
      })
    );
  }

  getAllInvoices() {
    return this.searchInvoices('');
  }

  searchInvoices(searchText: string) {
    return this.rm.searchAllPagesExpanding('invoice/search?limit=50', {
      sort: [{
        field: 'id', direction: 'desc'
      }],
      scopes: [
        { name: 'fullTextSearch', parameters: [searchText] }
      ]
    });
  }

  get(id: number | string) {
    return this.rm.get('invoice/' + id).pipe(
      map((resp: any) => {
        return new Order(resp);
      })
    );
  }

  storno(id: number, reason?: string) {
    const body: any = { invoice_id: id };
    if (reason) {
      body.reason = reason;
    }
    return this.rm.post('/invoice/storno', body);
  }
}
