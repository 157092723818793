
  <div class="actionbar">
    <div *ngIf="false">
      <mat-form-field
      ><label>Sortierung</label
      >
        <mat-select [formControl]="sortControl"
        >
          <mat-option value="id">ID</mat-option>
          <mat-option value="created_at">Datum</mat-option>
        </mat-select
        >
      </mat-form-field
      >
      <mat-form-field
      ><label>Sortierung</label
      >
        <mat-select [formControl]="sortOrder"
        >
          <mat-option value="asc">Aufsteigend</mat-option>
          <mat-option value="desc">Absteigend</mat-option>
        </mat-select
        >
      </mat-form-field
      >
    </div>
    <div>
      <label
      ><input type="checkbox" [formControl]="showAllControl" />Zeige auch
        unfertige Bestellungen an.</label
      >
    </div>
    <button mat-button (click)="clickViewPackagingSlips()">
      Lieferscheine anzeigen
    </button>
    <button mat-button (click)="clickExportSet()">Exportset erstellen</button>
  </div>
  <input placeholder="Suche" [formControl]="searchControl" />



    <ag-grid-angular
      style="width: 100%; height: 100%"
      class="ag-theme-alpine"
      [getRowId]="getRowId"
      [pagination]='true'
      [paginationAutoPageSize]=true
      [rowSelection]="'multiple'"
      [tooltipInteraction]="true"
      (tooltipShow)="showRowTooltip($event)"
      [suppressRowClickSelection]="true"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      (cellClicked)="onCellClicked($event)"
      (gridReady)="onGridReady($event)"

      >

    </ag-grid-angular>


