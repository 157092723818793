<div *ngIf="category$ | async as category">
  <div>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput placeholder="name" [(ngModel)]="category.name" />
    </mat-form-field>
    <div>
      <mat-label>Bild</mat-label>
      <input type="file" placeholder="img" (change)="handleFileInput($event)" />
    </div>
  </div>
  <div *ngIf="category.media?.length > 0">
    <div><img src="{{ category.media[0].original_url }}" /></div>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" (click)="saveTag(category)">
      Speichern
    </button>
    <button mat-raised-button color="secondary" routerLink="/categories">
      Zurück
    </button>
    <button mat-raised-button color="error" (click)="deleteTag(category)">
      Löschen
    </button>
  </div>
</div>
