import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IProducer } from '@app/data-interfaces';
import { ProducerService } from '@cw/data-producers';
import { mergeMap, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-producer-detail',
  templateUrl: './producer-detail.component.html',
  styleUrls: ['./producer-detail.component.scss'],
})
export class ProducerDetailComponent implements OnInit {
  producer$: Observable<IProducer | null>;
  fb = inject(UntypedFormBuilder);

  producerForm = this.fb.group({
    id: this.fb.control(null),
    name: this.fb.control('', [Validators.required]),
    image_path: this.fb.control(' ', [Validators.required]),
    description: this.fb.control('', [Validators.required]),
    data: this.fb.group({
      location: this.fb.control('', [Validators.required]),
      website: this.fb.control(''),
    }),
  });
  constructor(
    private route: ActivatedRoute,
    private producerService: ProducerService,
    private router: Router
  ) {
    this.producer$ = this.route.params.pipe(
      mergeMap((params: any) => {
        if (params['id']) {
          if (params['id'] == 'new') {
            return of({
              name: 'test',

              data: {},

              description: '',
              image_path: '',
            });
          } else {
            return this.producerService.getProducer(params['id']);
          }
        } else {
          return of(null);
        }
      }),
      tap((producer) => {
        if (producer) {
          this.producerForm.patchValue(producer);
        }
      })
    );
  }

  ngOnInit(): void {}

  saveProducer() {
    this.producerService
      .upsertProducer(this.producerForm.value)
      .subscribe(() => {
        this.router.navigateByUrl('/producers');
      });
  }
}
