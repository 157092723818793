<form [formGroup]="formGroup">
  <div>
    <mat-form-field>
      <label>Title</label>
      <input matInput formControlName="title" />
    </mat-form-field>
  </div>
  <div>
    @if(editor) {
      <div class="NgxEditor__Wrapper">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
        <ngx-editor [editor]="editor" formControlName="body" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
      </div>
    }
  </div>
  <button (click)="clickSave()">Speichern</button>
</form>
