import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { InvoiceService } from '@app/shared/data-invoice';
import { EMPTY, mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent {
  order$: Observable<any>;
  id: any = null;
  constructor(private route: ActivatedRoute, private service: InvoiceService) {
    this.order$ = this.route.params.pipe(
      mergeMap((params) => {
        if (!params['id']) {
          return EMPTY;
        }
        this.id = params['id'];
        return this.service.get(params['id']);
      })
    );
  }

  clickStorno() {
    this.service.storno(this.id).subscribe(() => {
      alert('Storno erfolgreich');
    });
  }
}
