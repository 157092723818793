import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private uiState: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.uiState.tenant_id != null) {
      req = req.clone({
        headers: req.headers.set('X-Tenant', this.uiState.tenant_id),
      });
    }
    if (this.uiState.jwt != '') {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + this.uiState.jwt),
      });
    }

    return next.handle(req);
  }
}
