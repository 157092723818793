import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CartService } from '@app/shared/data-cart2';
import { ProductService } from '@app/shared/data-products';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { mergeMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-ui-cart-subscriptions-changes',
  templateUrl: './ui-cart-subscriptions-changes.component.html',
  styleUrls: ['./ui-cart-subscriptions-changes.component.scss'],
})
export class UiCartSubscriptionsChangesComponent {
  cartLines$;
  total: any;
  changes$;
  products$;
  @Input()
  removeIconPath = '';
  constructor(
    public cartService: CartService,
    public cd: ChangeDetectorRef,
    private productService: ProductService,
  ) {
    this.cartLines$ = this.cartService.selectCartLines();
    this.changes$ = this.cartService.selectSubscriptionChanges();
    this.products$ = this.changes$.pipe(
      mergeMap((changes) => {
        return this.productService.selectByIds(
          _.map(changes, 'line.product_id'),
        );
      }),
    );
  }

  ngOnInit(): void {
    this.changes$.pipe(untilDestroyed(this)).subscribe((changes) => {
      const ids = _.map(changes, 'line.product_id');
      this.productService.loadByIds(ids).subscribe();
    });
  }

  removeLineClicked(index: any) {
    this.cartService.removeSubscriptionActionOnIndex(index);
  }
  getProductName(products: any[], product_id: any) {
    return products.find((p) => p.id == product_id).name ?? '';
  }
}
