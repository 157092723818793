import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { AuthQuery } from './auth.query';

import { RequestService } from './request.service';

import { AuthService, AuthState } from './auth.service';
import { AuthHelper } from './auth.helper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  loginUrl = 'user/login';
  latestUrl = '';

  constructor(
    public router: Router,
    public authService: AuthService,
    public request: RequestService
  ) {
  }

  setLoginUrl(loginUrl: string) {
    this.loginUrl = loginUrl;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.toString() == this.loginUrl) {
      return true;
    }

    return this.authService
      .select((authState: AuthState) => authState)
      .pipe(
        mergeMap((authState) => {
          if (this.isTokenExpired(authState.jwt)) {
            return this.request.refresh().pipe(mergeMap( (response:any) => {
              if(response === false) {
                setTimeout(() => {
                  this.authService.deleteLoginData();
                }, 1);
                //this.authService.updateProps({ user: undefined, jwt: '' });
                this.router.navigateByUrl(this.loginUrl);
                this.latestUrl = route.url.toString();
                return of(false);
              }
              return of(true);

            }));


          }

          if (authState.jwt == '' || authState.jwt == undefined) {
            this.router.navigateByUrl(this.loginUrl);
            this.latestUrl = route.url.toString();
            return of(false);
          }

          if (authState.user == null) {
            if (authState.tenant_id != null) {
              console.log('GET USER ', authState);
              return this.request.getMe().pipe(
                map((user) => {
                  console.log(user);
                  if (user !== undefined) {
                    setTimeout(() => {
                      this.authService.updateProps({ user: user });
                    }, 1);
                    return true;
                  } else {
                    setTimeout(() => {
                      this.authService.updateProps({ jwt: '' });
                    }, 1);
                    this.router.navigateByUrl(this.loginUrl);
                    return false;
                  }
                }),
                catchError(() => {
                  this.router.navigateByUrl(this.loginUrl);
                  return of(false);
                })
              );
            }
          }
          return of(true);
        }),
        take(1),

        catchError(() => {
          this.router.navigateByUrl(this.loginUrl);
          return of(false);
        })
      );
  }

  isTokenExpired(token?: string): boolean {
    return AuthHelper.isTokenExpired(token);
  }
}
