<h1>Batch Produkt Edit</h1>
<p>Anzahl Produkte: {{countProducts}}</p>
<div>
  <mat-form-field
  ><mat-label>Status</mat-label
  ><mat-select [(ngModel)]="product.status">
    <mat-option [value]="null">Nicht ändern</mat-option>
    <mat-option value="published">Veröffentlicht</mat-option>
    <mat-option value="draft">Entwurf</mat-option>
    <mat-option value="internal">Intern</mat-option>
  </mat-select>
  </mat-form-field>
</div>
<div>
  <mat-form-field
  ><mat-label>Abo Status</mat-label
  ><mat-select [(ngModel)]="product.subscription_available">
    <mat-option [value]="null">Nicht ändern</mat-option>
    <mat-option [value]="true">Abo Produkt</mat-option>
    <mat-option [value]="false">Abo nicht möglich</mat-option>
  </mat-select>
  </mat-form-field>
</div>

<div>
  <mat-form-field
  ><mat-label>Availability</mat-label
  ><mat-select [(ngModel)]="product.availability">
    <mat-option [value]="null">Nicht ändern</mat-option>
    <mat-option [value]="1">Verfügbar</mat-option>
    <mat-option [value]="0">Nicht verfügbar</mat-option>
  </mat-select>
  </mat-form-field>
</div>

<button mat-raised-button color="primary" (click)="save()">Speichern</button>
<button mat-raised-button color="warn" (click)="close()">Schließen</button>
