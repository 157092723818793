import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IProduct } from '@app/data-interfaces';

export interface OrderState extends EntityState<Order> {
  ui: {
    basket: { [key: string]: BasketLine };
  };
}

export interface BasketLine {
  amount: number;
  product: IProduct;
}
export interface Order {
  id: number;
}

const initialState = {
  ui: {
    basket: {},
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order' })
export class OrderStore extends EntityStore<OrderState> {
  constructor() {
    super(initialState);
  }
}
