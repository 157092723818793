import { Component, inject } from '@angular/core';
import { AdminSubscriptionService } from '@pisci/data-orders';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastController } from '@ionic/angular';
import { catchError, finalize } from 'rxjs';
import { LoadingService } from '../../../loading.service';

@Component({
  selector: 'app-subscription-close-drafts',
  templateUrl: './subscription-close-drafts.component.html',
  styleUrls: ['./subscription-close-drafts.component.scss']
})
export class SubscriptionCloseDraftsComponent {
  subscriptionService = inject(AdminSubscriptionService);
  modalRef = inject(MatDialogRef<SubscriptionCloseDraftsComponent>);
  toast = inject(ToastController);
  loadingService = inject(LoadingService);
  closeOpenDraftOrdersForToday() {
    this.loadingService.setLoading(true);
    this.subscriptionService.closeOpenDraftOrdersForToday().pipe(catchError(() => {
      this.toast.create({
        message: 'Failed to close orders',
        duration: 3000,
        position: 'top'
      }).then(toast => toast.present());
      this.modalRef.close();
      throw new Error('Failed to close orders');

    }), finalize(() => {
      this.loadingService.setLoading(false);
    })).subscribe(() => {
      this.toast.create({
        message: 'Orders closed successfully',
        duration: 3000,
        position: 'top'
      }).then(toast => toast.present());
      this.modalRef.close();
    });
  }
}
