import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ITag } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { forkJoin, Observable, of } from 'rxjs';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-tags-detail',
  templateUrl: './tags-detail.component.html',
  styleUrls: ['./tags-detail.component.scss'],
})
export class TagsDetailComponent implements OnInit {
  category$?: Observable<Partial<ITag>>;
  img: any;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private catalogService: ProductCatalogService
  ) {
    this.route.params.subscribe((params: any) => {
      if (params['id'] !== 'new') {
        this.category$ = this.catalogService.getTag(
          params['id']
        ) as Observable<ITag>;
      } else {
        this.category$ = of({ name: '', ordering: 0 });
      }
    });
  }

  handleFileInput(files: Event | FileList) {
    if (files instanceof FileList) {
      this.img = files.item(0);
    }
    if (files instanceof Event) {
      let event: any = files as any;
      this.img = event.target.files[0];
    }
  }
  ngOnInit(): void {}
  saveTag(category: Partial<ITag>): void {
    this.loading = true;
    let requests = [this.catalogService.saveTag(category)];
    if (this.img) {
      requests.push(this.catalogService.saveTagImage(category.id, this.img));
    }
    forkJoin(requests).subscribe(() => {
      this.loading = false;
      this.router.navigateByUrl('/tags');
    });
  }

  deleteTag(category: Partial<ITag>) {
    let result = confirm('Wollen Sie den Tag wirklich löschen?');
    if (result) {
      this.catalogService.deleteTag(category).subscribe(() => {
        this.router.navigateByUrl('/tags');
      });
    }
  }
}
