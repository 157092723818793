<div *ngIf="order$ | async as order" class="order-detail">
  <button (click)="clickStorno()" mat-raised-button>Storniere Rechnung</button>
  <button mat-raised-button routerLink="/invoices/{{ order.id }}/edit">
    Editieren
  </button>
  <div *ngIf="order.created_at">
    {{ order.created_at | date: 'dd.mm.yyyy HH:mm' }}
  </div>
  <div class="address" *ngIf="order.billing_address">
    <h4>Rechnungsadresse</h4>
    <div>
      {{ order.billing_address.name }}<br />{{ order.billing_address.street }}
      {{ order.billing_address.street_number }}
      {{ order.billing_address.street_2 }}<br />{{ order.billing_address.zip }}
      {{ order.billing_address.city }}<br />{{ order.billing_address.country }}
    </div>
  </div>
  <div class="address" *ngIf="order.shipping_address">
    <h4>Lieferadresse</h4>
    <div>
      {{ order.shipping_address.name }}<br />{{
        order.shipping_address.street
      }}
      {{ order.shipping_address.street_number }}
      {{ order.shipping_address.street_2 }}<br />{{
        order.shipping_address.zip
      }}
      {{ order.shipping_address.city }}<br />{{
        order.shipping_address.country
      }}
    </div>
  </div>
  <div *ngIf="order.lineItems" class="lines">
    <table>
      <thead>
        <th>Name</th>
        <th>Menge</th>
        <th>Preis</th>
        <th>Zeilensumme</th>
      </thead>
      <tr *ngFor="let line of order.lineItems" class="line">
        <td>{{ line.name }}</td>
        <td>{{ line.quantity }}</td>
        <td>{{ line.price.getAmountGross() ?? 0 | number: '1.2' }} €</td>
        <td>
          {{ line.price.getTotalAmountGross(line.quantity) | number: '1.2' }} €
        </td>
      </tr>
    </table>
  </div>
  <div class="total" *ngIf="order.totalPrice">
    <div>Brutto {{ order.totalPrice.getAmountGross() | number: '1.2' }} €</div>
    <div>Netto {{ order.totalPrice.getAmountNet() | number: '1.2' }} €</div>
    <div *ngFor="let tax of order.totalPrice.taxes">
      {{ tax.taxgroup.label }} {{ tax.getAmount() | number: '1.2' }} €
    </div>
  </div>
</div>
<pre class="hidden">{{ order$ | async | json }}</pre>
