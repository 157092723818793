<div>
  <form [formGroup]="invoiceForm">
    <div>
      <mat-form-field>
        <label>Name</label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Adresse</label>
        <textarea matInput formControlName="address"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Footer</label>
        <textarea matInput formControlName="footer"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Bildpfad</label>
        <input matInput formControlName="image" />
      </mat-form-field>
    </div>
  </form>

  <form [formGroup]="stripe">
    <div>
      <mat-form-field>
        <label>Testmodus</label>
        <mat-select formControlName="test_mode">
          <mat-option [value]="false">Nein</mat-option>
          <mat-option [value]="true">Ja</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Private Key</label>
        <textarea matInput formControlName="private_key"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Public Key</label>
        <textarea matInput formControlName="public_key"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Private Key Test</label>
        <textarea matInput formControlName="private_key_test"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Public Key Test</label>
        <textarea matInput formControlName="public_key_test"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <label>Webhook Secret</label>
        <textarea matInput formControlName="webhook_secret"></textarea>
      </mat-form-field>
    </div>
  </form>

  <form [formGroup]="invoiceNumber">
    <div formGroupName="invoice">
      <mat-form-field>
        <label>Nächste Rechnungsnummer</label>
        <input matInput formControlName="number" />
      </mat-form-field>
    </div>
    <div formGroupName="order">
      <mat-form-field>
        <label>Nächste Bestellnummer</label>
        <input matInput formControlName="number" />
      </mat-form-field>
    </div>
  </form>

  <form [formGroup]="additional">
    <div>
      <mat-form-field formGroupName="product_url">
        <label>Product Frontpage</label>
        <textarea matInput formControlName="data"></textarea>
      </mat-form-field>
    </div>
  </form>

  <button (click)="save()">Speichern</button>

  <button (click)="clickExportProducts()">
    Produkte für Facebook Exportieren
  </button>
</div>
