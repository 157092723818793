import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { PackagingGroupService } from '@app/shared/data-packaging-groups';
import * as _ from 'lodash';
import { map, take } from 'rxjs';

@Component({
  selector: 'app-packaging-group-ordering',
  templateUrl: './packaging-group-ordering.component.html',
  styleUrls: ['./packaging-group-ordering.component.scss'],
})
export class PackagingGroupOrderingComponent implements OnInit {
  list$;

  constructor(private dataService: PackagingGroupService) {
    this.list$ = this.dataService.selectAll().pipe(
      map((list) => {
        return _.orderBy(list, 'order', 'asc');
      })
    );
  }

  ngOnInit(): void {
    this.dataService.loadAll().subscribe();
  }

  drop(list: any, event: CdkDragDrop<string[]>) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }
  clickSave(list: any) {
    this.dataService
      .batchUpdate(this.listToIdAndOrderNumbers(list))
      .subscribe();
    console.log();
  }
  listToIdAndOrderNumbers(list: any) {
    return _.map(list, (v, i) => ({ id: v.id, order: i }));
  }
}
