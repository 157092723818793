import { HttpClient, HttpResponse } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';

import { Observable, OperatorFunction, of, pipe, throwError, expand, reduce, EMPTY, scan } from 'rxjs';
import {
  auditTime,
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  retry,
  retryWhen,
  take,
  tap
} from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from './config';

import { AuthService } from './auth.service';
import jwtDecode from 'jwt-decode';
import { pick } from 'lodash';
import { AuthHelper } from './auth.helper';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  tenant_id = '';
  base_url = '';
  refreshTimerRef: any;
  http = inject(HttpClient);
  uiState = inject(AuthService);
  config = inject(APP_CONFIG);

  constructor(
  ) {
    if (this.config?.default_tenant_id) {
      this.setTenantId(this.config?.default_tenant_id);
    }
    if (this.config?.base_url) {
      this.base_url = this.config?.base_url;
    }
    const apiUrlForDev = localStorage.getItem('apiUrl');
    if (apiUrlForDev) {
      this.base_url = apiUrlForDev;
    }
    this.uiState.onJwtRestored().subscribe(() => {

      this.startRefreshTimer(this.uiState.jwt);
    })
  }

  defaultErrorHandlerPipe(): OperatorFunction<any, any> {
    return pipe(
      catchError((err) => {
        if (err.status == 401) {
          return of({ errorInternal: err, errorCode: 401 });
        }

        return of({ errorInternal: err });
      }),
      mergeMap((input: any) => {
        if ('errorInternal' in input && input.errorCode == 401) {
          return this.refresh().pipe(
            auditTime(1),
            map(() => {
              throwError(() => input.errorInternal);
            })
          );
        } else if ('errorInternal' in input) {
          throwError(() => input.errorInternal);
        }

        return of(input);
      }),
      retry(1)
    );
  }

  checkUrlSuffix(url: string) {
    if (url.substr(0, 1) != '/') {
      return '/' + url;
    }
    return url;
  }

  getBaseUrl() {
    return this.base_url;
  }

  setTenantId(tenant_id: string) {
    this.uiState.setTenantId(tenant_id);
    this.tenant_id = tenant_id;
  }

  getMe() {
    return this.http.get(this.base_url + this.checkUrlSuffix('/auth/me'));
  }

  confirmUser(token: string) {
    return this.http.get(this.base_url + '/confirmUser/' + token);
  }

  login(body: any) {
    if (this.tenant_id) {
      //Nothing
    } else if (body.hasOwn('tenant_id')) {
      this.tenant_id = body.tenant_id;
      this.uiState.tenant_id = body.tenant_id;
      this.uiState.updateProps({ tenant_id: body.tenant_id });
    }
    return this.http
      .post(this.base_url + '/auth/login', pick(body, ['email', 'password']))
      .pipe(
        tap((response: any) => {
          this.setJwt(response.access_token, response.expires_in);
        }),
        mergeMap(() => this.getMe()),
        tap((user) => this.uiState.updateProps({ user: user }))
      );
  }

  setJwt(jwt: string, expires: number) {
    this.startRefreshTimer(jwt);
    this.uiState.setJwt(jwt, expires);
  }

  startRefreshTimer(jwt:string) {
    if (this.refreshTimerRef) {
      clearTimeout(this.refreshTimerRef);
    }
    const exp = AuthHelper.getTokenExpirationDate(jwt)?.getTime();
    if (exp) {
      const now = new Date().getTime();
      const seconds = exp - now;


      this.refreshTimerRef = setTimeout(() => {
        this.refresh().subscribe();
      }, seconds  - 5000);

    }
  }

  refresh() {
    return this.uiState
      .select((state) => state.jwt)
      .pipe(
        take(1),
        mergeMap((jwt) => {
          if ((jwtDecode(jwt) as any).exp < (new Date().getTime() - 6000) ) {
            return this.http.post(this.base_url + '/auth/refresh', {}).pipe(
              tap((response: any) => {
                this.setJwt(response.access_token, response.expires_in);
              }),
              catchError((err) => {
                if(err.status == 500) {
                  this.uiState.logout();
                }
                return EMPTY;
              })
            );
          } else {
            return of(false);
          }
        })
      );
  }

  register(userForm: any) {
    const body: {
      firstname?: string;
      lastname?: string;
      email?: string;
      password?: string;
    } = {};
    if (userForm.firstname && userForm.lastname) {
      body.firstname = userForm.firstname;
      body.lastname = userForm.lastname;
    }
    body.email = userForm.email;
    body.password = userForm.password;
    return this.http.post(this.base_url + '/user', body);
  }

  get<T = Object>(url: string): Observable<T> {
    return this.http.get(
      this.getBaseUrl() + this.checkUrlSuffix(url)
    ) as Observable<T>;
  }

  getFile(url: string) {
    const httpOptions: any = {
      responseType: 'arraybuffer',
      observe: 'response'
    };
    return this.http.get(
      this.getBaseUrl() + this.checkUrlSuffix(url),
      httpOptions
    );
  }

  postGetFile(url: string, body: any): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.post(this.getBaseUrl() + this.checkUrlSuffix(url), body, {
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  post<T = unknown>(url: string, body: any) {
    return this.http
      .post<T>(this.getBaseUrl() + this.checkUrlSuffix(url), body)
      .pipe(this.defaultErrorHandlerPipe());
  }

  put(url: string, body: any) {
    return this.http.put(this.getBaseUrl() + this.checkUrlSuffix(url), body);
  }

  delete(url: string, body?: any) {
    if (body) {
      return this.http.delete(this.getBaseUrl() + this.checkUrlSuffix(url), {
        body
      });
    }
    return this.http.delete(this.getBaseUrl() + this.checkUrlSuffix(url));
  }

  patch(url: string, body: any) {
    return this.http.patch(this.getBaseUrl() + this.checkUrlSuffix(url), body);
  }

  searchAllPagesExpanding(url: string, searchBody: any) {
    let appendix = '?';
    if (url.indexOf('?') !== -1) {
      appendix = '&';
    }
    return this
      .post(url, searchBody)
      .pipe(
        expand((response: any) => {
          if (response?.links?.next) {
            return this.post(url + appendix + 'page=' + (parseInt(response.meta?.current_page) + 1), searchBody);
          } else {
            return EMPTY;
          }
        }),
        scan((acc, curr: any) => {
          acc = acc.concat(curr.data);
          return acc;
        }, []));


  }

  getAllPagesExpanding(url: string, suffix = '', limit = 100): Observable<any> {
    let result: any[];
    let appendix = '?';
    if (url.indexOf('?') !== -1) {
      appendix = '&';
    }
    return this.http
      .get(
        this.base_url +
        this.checkUrlSuffix(url) +
        appendix +
        'limit=' +
        limit +
        suffix
      ).pipe(expand((response: any) => {
          if (response.links.next != null) {
            return this.http.get(this.base_url +
              this.checkUrlSuffix(url) +
              appendix +
              'limit=' +
              limit +
              suffix + '&page=' + (Number.parseInt(response.meta.current_page) + 1));
          }
          return EMPTY;
        }),
        scan((acc, value: any) => {
          return acc.concat(value.data);
        }, []));
  }

  getAllPages(url: string, suffix = '', limit = 100): Observable<any> {
    let result: any[];
    let appendix = '?';
    if (url.indexOf('?') !== -1) {
      appendix = '&';
    }
    return this.http
      .get(
        this.base_url +
        this.checkUrlSuffix(url) +
        appendix +
        'limit=' +
        limit +
        suffix
      )
      .pipe(
        concatMap((response: any) => {
          result = response.data;
          if (response.data === undefined) {
            return of(response);
          }
          if (response.links.next != null) {
            return this.getNextPage(
              this.base_url +
              this.checkUrlSuffix(url) +
              appendix +
              'limit=' +
              limit +
              suffix,
              response.meta,
              result
            );
          }
          if (response.meta.current_page == 1) {
            return of(response.data);
          }
          return of([]);
        }),
        concatMap((data: any) => {
          return of(data);
        })
      );
  }

  getProducts() {
    const url = '/product?limit=100&include=tags,producers';
    let result: any[];
    return this.http.get(this.base_url + url).pipe(
      concatMap((response: any) => {
        result = response.data;
        if (response.links.next != null) {
          return this.getNextPage(this.base_url + url, response.meta, result);
        }
        if (response.meta.current_page == 1) {
          return of(response.data);
        }
        return of([]);
      }),
      concatMap((data: any) => {
        return of(data);
      })
    );
  }

  getNextPage(
    baseurl: string,
    meta: { current_page: string; last_page: string },
    result: any[]
  ): Observable<any> {
    return this.http
      .get(baseurl + '&page=' + (Number.parseInt(meta.current_page) + 1))
      .pipe(
        concatMap((response: any) => {
          if (meta.current_page + 1 < meta.last_page) {
            result = [...response.data, ...result];
            return this.getNextPage(baseurl, response.meta, result);
          }
          result = [...response.data, ...result];
          return of(result);
        })
      );
  }

  getBalance() {
    return this.http.get(this.base_url + '/customer/accounting');
  }

  loginAs(param: any) {
    const body = {
      auth_token: param
    }

    return this.http
      .post(this.base_url + '/auth/loginAs', pick(body, ['auth_token']))
      .pipe(
        tap((response: any) => {
          this.setJwt(response.access_token, response.expires_in);
        }),
        mergeMap(() => this.getMe()),
        tap((user) => this.uiState.updateProps({ user: user }))
      );
  }

  log(event:string, data:any) {
    return this.http.post(this.base_url + '/log', {event, data});
  }
}
