import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-debug',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements AfterViewInit {
  ngAfterViewInit(): void {
      this.backendUrlForm.setValue(localStorage.getItem('admin_base_url'));
  }
  backendUrlForm = new FormControl();
  setBackendUrl() {
    localStorage.setItem('admin_base_url', this.backendUrlForm.getRawValue());
    window.location.reload();
  }
}
