<form [formGroup]="formGroup">
  <div>
    <mat-form-field>
      <label>Title</label>
      <input matInput formControlName="title" />
    </mat-form-field>
  </div>
  <div>
    <ckeditor formControlName="body" [editor]="Editor"></ckeditor>
  </div>
  <button (click)="clickSave()">Speichern</button>
</form>
