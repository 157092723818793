<ng-container *ngIf="settings">
  <form [formGroup]="form" (ngSubmit)="onSubmit(settings)">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="settings"
    ></formly-form>
    <button type="submit" class="btn btn-default" mat-raised-button>
      <mat-icon>save</mat-icon>Speichern
    </button>
  </form>
</ng-container>

{{ settings$ | async | json }}
