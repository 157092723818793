import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategory } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { Observable, of } from 'rxjs';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss'],
})
export class CategoryDetailComponent implements OnInit {
  category$?: Observable<Partial<ICategory>>;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private catalogService: ProductCatalogService
  ) {
    this.route.params.subscribe((params: any) => {
      if (params['id'] !== 'new') {
        this.category$ = this.catalogService.getCategory(
          params['id']
        ) as Observable<ICategory>;
      } else {
        this.category$ = of({ name: '', ordering: 0 });
      }
    });
  }

  ngOnInit(): void {}
  saveCategory(category: Partial<ICategory>): void {
    this.loading = true;
    this.catalogService.saveCategory(category).subscribe(() => {
      this.loading = false;
      this.router.navigateByUrl('categories');
    });
  }

  deleteCategory(category: Partial<ICategory>) {
    let result = confirm('Wollen Sie die Kategorie wirklich löschen?');
    if (result) {
      this.catalogService.deleteCategory(category).subscribe(() => {
        this.router.navigateByUrl('categories');
      });
    }
  }
}
