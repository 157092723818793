<h3 class="label">{{label}}</h3>
<app-expandable [label]="labelHelpText">
<p class="helpText" *ngIf="helpText != ''">
  {{helpText}}
</p>
</app-expandable>
<div class="ui-mini-cart-widget cart">
  <table class="ui-mini-cart-table cart-table">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <thead>
    <th>Name</th>
    <th>Menge</th>
    <th></th>
    <th>Preis</th>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource" [class.isSubscription]="isSubscriptionLine(element)">
      <td class="name" data-label="Name">
        <ng-container *ngIf="element.productId"><a
          [routerLink]="getProductLink(element.productId)">{{ element.name }}</a></ng-container>

        <ng-container *ngIf="!element.productId">{{ element.name }} </ng-container>
      </td>
      <td class="quantity" data-label="Menge">
        <ng-container *ngIf="isElementEditable(element)">
          <form>
            <div class="addtocart-wrapper">
              <div class="amount" *ngIf="!element.undeletable">
                  <span class="navigation" (click)="clickLeft(element)">
                    <span class="material-icons">chevron_left</span> </span
                  ><input
                [formControl]="element.formControl"
                (focusout)="lostFocus(element)"
              />
                <span class="navigation" (click)="clickRight(element)">
                    <span class="material-icons">chevron_right</span>
                  </span>
              </div>
              <div class="amount undeletable" *ngIf="element.undeletable">
                {{ element.quantity }}
              </div>

            </div>
          </form>
        </ng-container>
      </td>
      <td>
      </td>
      <ng-container *ngIf="!multiplyPrice">
        <td class="price" data-label="Einzelpreis">
          {{ element.price?.amountGross??0 | number: '1.2' }} €
        </td>
      </ng-container>
      <ng-container *ngIf="multiplyPrice">
        <td class="price" data-label="Positionspreis">
          {{ (element?.price?.amountGross??0)*element.quantity | number: '1.2' }} €
        </td>
      </ng-container>
      <td class="actions">
        <button
          class="btn_remove clickable"
          *ngIf="!element.undeletable"
          (click)="removeLineClicked(element)"
        >
          <img *ngIf="removeIconPath" src="{{ removeIconPath }}" />
          <mat-icon *ngIf="!removeIconPath" fontIcon="delete"
          >delete
          </mat-icon
          >
        </button>
      </td>
    </tr>
    <ng-container *ngIf="subscriptionLines$| async as lines">
      <tr *ngFor="let element of lines" [class.isSubscription]="true">
        <td class="name" data-label="Name">
          <ng-container *ngIf="element.product"><a
            [routerLink]="getProductLink(element.product)">{{ element.name }} <ng-container *ngIf="getHelpTextForLine(element) as text"><span *ngIf="text != ''" class="helpTextLine">{{text}}</span></ng-container></a></ng-container>

          <ng-container *ngIf="!element.product">{{ element.name }}</ng-container>
        </td>
        <td class="quantity" data-label="Menge">
          <ng-container *ngIf="isElementEditable(element)">
            <form>
              <div class="addtocart-wrapper">
                <div class="amount undeletable">
                  {{ element.quantity }}
                </div>
                <button [disabled]="isEditSubscriptionButtonDisabled(element)" class="edit-button"
                        (click)="clickEditQuantitySubscriptionLine(element)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </form>
          </ng-container>
        </td>
        <td>
          <span class="subscription_label" (click)="clickSubscriptionButton(element)">Abo</span>

        </td>
        <ng-container *ngIf="!multiplyPrice">
        <td class="price" data-label="Einzelpreis">
          {{ element.price.amountGross | number: '1.2' }} €
        </td>
        </ng-container>
        <ng-container *ngIf="multiplyPrice">
          <td class="price" data-label="Positionspreis">
            {{ element.price.amountGross*element.quantity | number: '1.2' }} €
          </td>
        </ng-container>
        <td class="actions">

        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<ng-container *ngIf="vouchers$ | async as vouchers">
  <ng-container *ngIf="vouchers.length > 0">
    <h3>Gutscheine</h3>
    <div class="ui-mini-cart-widget">
      <div *ngFor="let v of vouchers">
        Gutscheincode: {{ v.code }} {{ voucherService.getTypedString(v) }}
        <button class="btn_remove clickable" (click)="removeVoucher(v)">
          <img *ngIf="removeIconPath" src="{{ removeIconPath }}" />
          <mat-icon *ngIf="!removeIconPath" fontIcon="delete">delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
<h3>Gutscheincode hinzufügen</h3>
<div class="voucher">
  <div class="inputLine">
    <input [formControl]="formVoucherControl" />
    <button
      class="clickable"
      (click)="addVoucher()"
    >
      <span class="material-icons">add</span>
    </button>
  </div>
</div>
