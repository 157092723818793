<h1>Lagerbestand verändern</h1>

<ng-container *ngIf="stock$ | async as ws">
  <div>
    <input [formControl]="searchControl" placeholder="Productname Filtern" />
    <form [formGroup]="form" (ngSubmit)="onSubmit(ws)">
      <formly-form [form]="form" [fields]="fields" [model]="ws"></formly-form>
      <button type="submit" class="btn btn-default" mat-raised-button>
        <mat-icon>save</mat-icon>Speichern
      </button>
    </form>
  </div>
  <div>
    <button
      mat-button
      routerLink="/stockmanaging/stocks-history/{{ ws.warehouse_id }}/{{
        ws.product_id
      }}"
    >
      Historie anzeigen
    </button>
  </div>
</ng-container>

<h1>Einstellungen</h1>
<ng-container *ngIf="productSettings$ | async as ps">
  <form
    [formGroup]="formSettings"
    (ngSubmit)="onSubmitSettings(ps, ps.data.stockmanagement)"
  >
    <formly-form
      [form]="formSettings"
      [fields]="fieldsSettings"
      [model]="ps.data.stockmanagement"
    ></formly-form>
    <button type="submit" class="btn btn-default" mat-raised-button>
      <mat-icon>save</mat-icon>Speichern
    </button>
  </form>
</ng-container>
