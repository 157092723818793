import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public get loading() {
    return this.loadingSubject.getValue();
  }
  public get loading$() {
    return this.loadingSubject.asObservable();
  }
  loadingSubject = new BehaviorSubject(false);
  constructor() {}
  setLoading(state: boolean) {
    this.loadingSubject.next(state);
  }

  show(): void {
    this.setLoading(true);
  }
  hide(): void {
    this.setLoading(false);
  }
}
