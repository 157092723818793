<ng-container *ngIf="producer$ | async as producer">
  <div [formGroup]="producerForm">
    <div>
      <mat-form-field
        ><mat-label>Name</mat-label><input matInput formControlName="name"
      /></mat-form-field>
    </div>
    <div formGroupName="data">
      <mat-form-field
        ><mat-label>Ort</mat-label><input matInput formControlName="location"
      /></mat-form-field>
      <mat-form-field
        ><mat-label>Website</mat-label
        ><input matInput formControlName="website"
      /></mat-form-field>
    </div>
    <button mat-raised-button (click)="saveProducer()">Speichern</button
    ><button mat-raised-button routerLink="producers">Zurück</button>
  </div>
</ng-container>
