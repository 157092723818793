import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@pisci/requestManager';
import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
import { CategoryComponent } from './category/category.component';
import { CustomersComponent } from './customers/customers.component';
import { ExportsComponent } from './exports/exports.component';
import { ImporterComponent } from './importer/importer.component';
import { InvoiceEditComponent } from './invoices/invoice-edit/invoice-edit.component';
import { InvoiceDetailComponent } from './invoices/invoices-detail/invoice-detail.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrdersComponent } from './orders/orders.component';
import { PageDetailComponent } from './pages/page-detail/page-detail.component';
import { PagesComponent } from './pages/pages.component';
import { ProducerDetailComponent } from './producer/producer-detail/producer-detail.component';
import { ProducerComponent } from './producer/producer.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { ReportComponent } from './report/report.component';
import { SettingsComponent } from './settings/settings.component';
import {
  ShippingMethodDetailComponent
} from './shipping-methods/shipping-method-detail/shipping-method-detail.component';
import { ShippingMethodsComponent } from './shipping-methods/shipping-methods.component';
import { StockmanagingComponent } from './stockmanaging/stockmanaging/stockmanaging.component';
import { StocksEditComponent } from './stockmanaging/stocks-edit/stocks-edit.component';
import { StocksComponent } from './stockmanaging/stocks/stocks.component';
import { StockssettingComponent } from './stockmanaging/stockssetting/stockssetting.component';
import { WarehouseEditComponent } from './stockmanaging/warehouse-edit/warehouse-edit.component';
import { WarehouseComponent } from './stockmanaging/warehouse/warehouse.component';
import { TagsDetailComponent } from './tags/tags-detail/tags-detail.component';
import { TagComponent } from './tags/tags.component';
import { VoucherDetailComponent } from './voucher/voucher-detail/voucher-detail.component';
import { VoucherComponent } from './voucher/voucher.component';
import { StocksHistoryComponent } from './stockmanaging/stocks-history/stocks-history.component';
import { PackagingGroupComponent } from './packaging_group/packaging-group/packaging-group.component';
import {
  PackagingGroupOrderingComponent
} from './packaging_group/packaging-group-ordering/packaging-group-ordering.component';
import {
  PackagingGroupProductComponent
} from './packaging_group/packaging-group-product/packaging-group-product.component';
import { SubscriptionComponent } from './subscriptions/subscription/subscription.component';
import { SubscriptionDetailComponent } from './subscriptions/subscription-detail/subscription-detail.component';
import { CustomerDetailComponent } from './customers/customer-detail/customer-detail.component';
import { DebugComponent } from './debug/debug.component';
import { ProductBatchEditComponent } from './products/product-batch-edit/product-batch-edit.component';
import { EmailComponent } from './settings/email/email.component';
import { VoucherOrdersComponent } from './voucher/voucher-orders/voucher-orders.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'user/login', component: LoginPageComponent },
  {
    path: 'debug',
    component: DebugComponent
  },
  {
    path: '',
    children: [
      { path: 'products', component: ProductsComponent },
      { path: 'products/batch-edit', component: ProductBatchEditComponent},
      { path: 'products/:id', component: ProductDetailComponent },
      { path: 'categories/:id', component: CategoryDetailComponent },
      { path: 'categories', component: CategoryComponent },
      { path: 'tags', component: TagComponent },
      { path: 'tags/:id', component: TagsDetailComponent },
      { path: 'producers', component: ProducerComponent },
      { path: 'producers/:id', component: ProducerDetailComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'orders/:id', component: OrderDetailComponent },
      { path: 'invoices', component: InvoicesComponent },
      { path: 'invoices/:id', component: InvoiceDetailComponent },
      { path: 'invoices/:id/edit', component: InvoiceEditComponent },
      { path: 'vouchers', component: VoucherComponent },
      { path: 'vouchers/:id', component: VoucherDetailComponent },
      { path: 'vouchers/:id/orders', component: VoucherOrdersComponent },
      { path: 'importer', component: ImporterComponent },
      { path: 'pages', component: PagesComponent },
      { path: 'pages/:id', component: PageDetailComponent },
      { path: 'shipmentOptions', component: ShippingMethodsComponent },
      { path: 'shipmentOptions/:id', component: ShippingMethodDetailComponent },
      { path: 'customers', component: CustomersComponent },
      { path: 'customers/:id', component: CustomerDetailComponent },
      { path: 'reports', component: ReportComponent },
      { path: 'exports', component: ExportsComponent },
      { path: 'packaging_groups', component: PackagingGroupComponent },
      {
        path: 'packaging_groups/ordering',
        component: PackagingGroupOrderingComponent,
      },
      {
        path: 'packaging_groups/products',
        component: PackagingGroupProductComponent,
      },

      {
        path: 'stockmanaging',
        component: StockmanagingComponent,
        children: [
          {
            path: 'warehouse',
            component: WarehouseComponent,
          },
          {
            path: 'warehouse/:id',
            component: WarehouseEditComponent,
          },
          {
            path: 'stocks',
            component: StocksComponent,
          },
          {
            path: 'stocks/:warehouse/:product',
            component: StocksEditComponent,
          },
          {
            path: 'stocks-history/:warehouse/:product',
            component: StocksHistoryComponent,
          },
          {
            path: 'settings',
            component: StockssettingComponent,
          },
        ],
      },

      { path: 'settings', component: SettingsComponent },
      { path: 'settings/email', component: EmailComponent },
      {
        path: 'subscriptions', children: [
          { path: '', component: SubscriptionComponent },
          { path: ':id', component: SubscriptionDetailComponent },
        ]
      }],
    canActivate: [AuthGuardService],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UiRoutingModule {
}
