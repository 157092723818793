import { Component, OnInit } from '@angular/core';
import { RequestService } from '@pisci/requestManager';

@Component({
  selector: 'app-shipping-methods',
  templateUrl: './shipping-methods.component.html',
  styleUrls: ['./shipping-methods.component.scss'],
})
export class ShippingMethodsComponent implements OnInit {
  methods$;
  constructor(private rm: RequestService) {
    this.methods$ = this.rm.getAllPages('/shipmentOptions');
  }

  ngOnInit(): void {}
}
