<!--
  $text = str_replace("{order_number}", $order->number, $text);
        $text = str_replace("{order_date}", $order->created_at->format("d.m.Y"), $text);
        $text = str_replace("{order_time}", $order->created_at->format("H:i"), $text);
        $text = str_replace("{order_total}", $order->total, $text);
        $text = str_replace("{order_url}", $this->frontend_url . "/order/" . $order->id, $text);
        $text = str_replace("{customer_name}", $order->customer->name, $text);
-->

<div [formGroup]="subscriptionNotification" class="subscription-notification">
<p class="placehodlers">
  Platzhalter:
  <span>&#123;order_number&#125;</span>
  <span>{{ "{" }}order_date&#125;</span>
  <span>&#123;order_time&#125;</span>
  <span>&#123;order_total&#125;</span>
  <span>&#123;order_url&#125;</span>
  <span>&#123;customer_name&#125;</span>
</p>
    <mat-label>Begrüßung</mat-label>
  @if(editors[0]) {
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editors[0]" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editors[0]" formControlName="greeting" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  }

    <mat-error *ngIf="subscriptionNotification.get('greeting')?.hasError('required')">
      Bitte geben Sie eine Begrüßung ein.

    </mat-error>

  <!-- footer -->

    <mat-label>Footer</mat-label>
  @if(editors[1]) {
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editors[1]" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editors[1]" formControlName="footer" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  }    <mat-error *ngIf="subscriptionNotification.get('footer')?.hasError('required')">
      Bitte geben Sie einen Footer ein.
    </mat-error>

  <!-- signature -->

    <mat-label>Signatur</mat-label>
  @if(editors[2]) {
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editors[2]" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editors[2]" formControlName="signature" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  }    <mat-error *ngIf="subscriptionNotification.get('signature')?.hasError('required')">
      Bitte geben Sie eine Signatur ein.
    </mat-error>

</div>
<div [formGroup]="emails" >
  <h2>Bestell Email</h2>
  @if(editors[3]) {
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editors[3]" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editors[3]" formControlName="order_created_text" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  }
  <h2>Abo Bestellung Abgebrochen Email</h2>
  @if(editors[4]) {
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editors[4]" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor [editor]="editors[4]" formControlName="order_cancelled_text" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor>
    </div>
  }
  <h2>Betreff Email</h2>
  <input formControlName="subject_prefix" name="subject_prefix" required>

</div>
<button mat-raised-button (click)="save()">Speichern</button>
