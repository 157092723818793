<pre>{{ method | json }}</pre>

<div>
  <mat-form-field>
    <label>Versandname(ohne leerzeichen)</label>
    <input matInput [(ngModel)]="method.name" />
  </mat-form-field>
</div>
<div>
  <mat-form-field>
    <label>Versandbezeichnung</label>
    <input matInput [(ngModel)]="method.label" />
  </mat-form-field>
</div>
<div>
  <mat-form-field>
    <label>Preis</label>
    <input matInput [(ngModel)]="price.amountGross" />
  </mat-form-field>
</div>
<div>
  <mat-form-field *ngIf="taxgroups$ | async as taxgroups">
    <label>MwSt.</label>
    <mat-select [(ngModel)]="price.taxgroups_id">
      <mat-option
        [value]="group.value.id"
        *ngFor="let group of taxgroups | keyvalue"
        >{{ group.value.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<button (click)="clickSave()">Speichern</button>
