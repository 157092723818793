import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICartLine, OrderDTO } from '@app/data-interfaces';
import { CartService } from '@app/shared/data-cart2';
import { Observable } from 'rxjs';

const ELEMENT_DATA: Partial<ICartLine>[] = [];

@Component({
  selector: 'ui-cart-overview',
  templateUrl: './ui-cart-overview.component.html',
  styleUrls: ['./ui-cart-overview.component.scss']
})
export class UiCartOverviewComponent implements OnInit {
  cartLines$;
  total: any;
  orderDTO$: Observable<OrderDTO>;

  constructor(
    public cartService: CartService,
    public cd: ChangeDetectorRef
  ) {
    this.cartLines$ = this.cartService.selectCartLines();
    this.orderDTO$ = this.cartService.selectOrderDTO();
  }

  ngOnInit(): void {
  }

  getLinesOrdered(orderDTO: OrderDTO) {
      return orderDTO.getAllLines();
  }
}
