import { createEffect, ofType } from '@ngneat/effects';
import { authLogoutDoneAction } from '@pisci/requestManager';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CartService } from './cart.service';

@Injectable({ providedIn: 'root' })

export class CartEffects {
  logoutEffect$ = createEffect(actions => actions.pipe(
    ofType(authLogoutDoneAction),
    tap(() => this.cartService.clearCart())
  ));

  constructor(private cartService: CartService) {
  }
}
