import { Inject, Injectable, Optional } from '@angular/core';

export const SUBSCRIPTION_CONFIG = 'SUBSCRIPTION_CONFIG';

export interface SubscriptionConfig {
  interval_options: {
    label: string;
    interval_days: number;
  } [];
  showDatePicker: boolean;
  possibleDates?: Date[];
  possibleDatesLabels?: string[];
  deliveryDateHandler?: (v: Date | null) => Date;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionConfigService {
  private config: SubscriptionConfig = {
    interval_options: [
      { label: 'Wöchentlich', interval_days: 7 },
      { label: 'Alle 2 Wochen', interval_days: 14 },
      { label: 'Alle 3 Wochen', interval_days: 21 },
      { label: 'Alle 4 Wochen', interval_days: 28 }
    ],
    showDatePicker: false
  } satisfies SubscriptionConfig;

  constructor(@Inject(SUBSCRIPTION_CONFIG) @Optional() config: SubscriptionConfig) {
    if (config) {
      this.config = config;
    }
  }

  getConfig() {
    return this.config;
  }

  setPossibleDates(dates: Date[]) {
    this.config.possibleDates = dates;
  }

  setPossibleDatesLabels(labels: string []) {
    this.config.possibleDatesLabels = labels;
  }

  getDeliveryDate(next_order: Date | null): Date {
    if (this.config.deliveryDateHandler) {
      return this.config.deliveryDateHandler(next_order);
    }
    if (next_order === null) {
      return new Date();
    }
    return next_order;
  }

}
