import { Component, inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RequestService } from '@pisci/requestManager';
import { addDays, addHours, addMinutes } from 'date-fns';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  formGroup = new UntypedFormGroup({
    fromDate: new UntypedFormControl('', Validators.required),
    fromTime: new UntypedFormControl('', Validators.required),
    toDate: new UntypedFormControl('', Validators.required),
    toTime: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('bookkeeping', Validators.required),
  });

  constructor( private rm: RequestService) {}

  ngOnInit(): void {
    const today = new Date();
    this.formGroup.patchValue({
      fromDate: today,
      toDate: addDays(today, 7),
      toTime: '12:00',
      fromTime: '12:00',
    });
  }
  clickGenerate() {
    if (this.formGroup.valid) {
      let startDate = this.formGroup.value.fromDate;
      let endDate = this.formGroup.value.toDate;
      startDate.setHours(this.formGroup.value.fromTime.split(':')[0]);
      startDate.setMinutes(this.formGroup.value.fromTime.split(':')[1]);
      endDate.setHours(this.formGroup.value.toTime.split(':')[0]);
      endDate.setMinutes(this.formGroup.value.toTime.split(':')[1]);
      startDate = addMinutes(startDate, startDate.getTimezoneOffset());
      endDate = addMinutes(endDate, startDate.getTimezoneOffset());
      console.log(startDate, endDate);
      this.rm
        .postGetFile('order/report', {
          from: startDate,
          to: endDate,
          type: this.formGroup.value.type,
        })
        .subscribe((response: any) => {
          const blob = new Blob([response.body], {
            type: response.headers.get('content-type'),
          });
          // let fileName =  response.headers.get('content-disposition').split(';')[0];
          const fileName = response.headers.get('filename', 'file');
          const file = new File([blob], fileName, {
            type: response.headers.get('content-type'),
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    }

    console.log(this.formGroup);
  }
}
