import { Component, inject } from '@angular/core';
import { RequestService } from '@pisci/requestManager';
import { LoadingService } from '../../../loading.service';
import { actions } from '@ngneat/effects';

@Component({
  selector: 'app-subscription-actions',
  templateUrl: './subscription-actions.component.html',
  styleUrls: ['./subscription-actions.component.scss']
})
export class SubscriptionActionsComponent {
  rm = inject(RequestService);
  loading = inject(LoadingService)
  clickNotification() {
    this.loading.setLoading(true);
    this.rm.post("/subscription/action", {type:"notify"}).subscribe(() => {
      this.loading.setLoading(false);
    })
  }

  actions$ = this.rm.get<any[]>("/subscription/action");
}
