import { Component, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@app/shared/data-pages';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, mergeMap, Observable, tap } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from "lodash";
@UntilDestroy()
@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss'],
})
export class PageDetailComponent  {
  private fb = inject(UntypedFormBuilder);
  public Editor = ClassicEditor;
  id?: number;
  formGroup = this.fb.group({
    title: this.fb.control('', Validators.required),
    body: this.fb.control('', Validators.required),
    data: this.fb.group({}),
  });
  constructor(
    private ps: PageService,
    private route: ActivatedRoute,

    private router: Router
  ) {
    this.route.params
      .pipe(
        untilDestroyed(this),
        filter((params) => {
          if (params['id'] === 'new') return false;
          return true;
        }),
        mergeMap((params) => this.ps.selectPage(params['id'])),
        tap((page: any) => {
          this.id = page.id;
          this.formGroup.patchValue(page);
        })
      )
      .subscribe();
  }



  clickSave() {
    let body =  _.omit(this.formGroup.value, ["data"]);
    if (this.id) {
      body = { ...body, id: this.id };
    }
    this.ps.upsertPage(body).subscribe(() => {
      this.router.navigateByUrl('pages');
    });
  }
}
