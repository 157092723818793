<div class="header" [hidden]="!hasContent" (click)="clickExpand()"><p class="label" >{{label}}</p>
<mat-icon class="icon" *ngIf="isExpanded()">expand_less</mat-icon>

  <mat-icon class="icon" *ngIf="!isExpanded()">expand_more</mat-icon>
</div>
<div class="expandable" [ngClass]="{'expanded' :isExpanded()}">
<ng-content #content></ng-content>
</div>


