import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AdminSubscriptionService } from '@pisci/data-orders';
import { MatDialogRef } from '@angular/material/dialog';

import {catchError, finalize, Observable} from 'rxjs';
import { ToastController } from '@ionic/angular';
import { LoadingService } from '../../../loading.service';
import {SubscriptionService} from "@app/shared/data-cart2";

@Component({
  selector: 'app-subscription-generate-order-modal',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, FormsModule, MatInputModule],
  templateUrl: './subscription-generate-order-modal.component.html',
  styleUrls: ['./subscription-generate-order-modal.component.scss']
})
export class SubscriptionGenerateOrderModalComponent {

  date: any;
  subscriptionService = inject(AdminSubscriptionService);
  modalRef = inject(MatDialogRef<SubscriptionGenerateOrderModalComponent>);
  toast = inject(ToastController);
  loading = false;
  loadingService = inject(LoadingService);
  $dates:Observable<any[]> = inject(SubscriptionService).getNextOrderDates();
  generateSubscription() {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.subscriptionService.generateDraftOrders(this.date).pipe(catchError(() => {
      this.toast.create({message: 'Failed to generate orders', duration: 3000, position: 'top'}).then(toast => toast.present());


      throw new Error('Failed to generate orders');
    }), finalize(() => {
      this.loading = false;
      this.loadingService.setLoading(false);
    })).subscribe(() => {

      this.toast.create({message: 'Orders generated successfully', duration: 3000, position: 'top'}).then(toast => toast.present());
      this.modalRef.close();
    });

  }
}
