import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IProduct, ISubscription } from '@app/data-interfaces';

@Component({
  selector: 'cw-base-subscription-list',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './base-subscription-list.component.html',
  styleUrls: ['./base-subscription-list.component.scss']
})
export class BaseSubscriptionListComponent {
  @Input() subscriptions: ISubscription[] = [];
  @Input() products : IProduct[] = [];
  @Output() editClicked = new EventEmitter<ISubscription>();
  clickEdit(subscription: ISubscription) {
    this.editClicked.emit(subscription);
  }


  getIntervalString(days: number) {
    if (days === 7) {
      return 'wöchentlich';
    }
    if (days === 14) {
      return 'zweiwöchig';
    }
    if (days === 30) {
      return 'monatlich';
    }
    return days + ' tägig';
  }
  getProduct(id: number): IProduct | undefined {
    return this.products.find(p => p.id === id);
  }
}
