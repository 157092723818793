import { IProduct } from './product';

export interface IWarehouse {
  id: number | string;
  name: string;
  stocks: IStock[];
}

export interface IStock {
  amount: number;
  id: number | string;
  product_id: number;
  warehouse_id: number;
  warehouse?: IWarehouse;
  product?: IProduct;
}
