import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import {
  SubscriptionGenerateOrderModalComponent
} from '../subscription-generate-order-modal/subscription-generate-order-modal.component';
import { SubscriptionCloseDraftsComponent } from '../subscription-close-drafts/subscription-close-drafts.component';

@Component({
  selector: 'app-subscription-menu',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatButtonModule],
  templateUrl: './subscription-menu.component.html',
  styleUrls: ['./subscription-menu.component.scss']
})
export class SubscriptionMenuComponent {

  modal = inject(MatDialog)
  generateDraftOrders() {
    this.modal.open(SubscriptionGenerateOrderModalComponent, {});
  }

  closeDrafts() {
    this.modal.open(SubscriptionCloseDraftsComponent);
  }
}
