<form [formGroup]="formGroup">
  <div class="datepicker-wrapper">
    <div>
      <h4>Datum</h4>

      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="fromDate"
            placeholder="Start date"
          />
          <input matEndDate formControlName="toDate" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div>
      <div class="24hr-example">
        <label>Uhrzeit von</label>
        <input
          placeholder="24hr format"
          aria-label="24hr format"
          formControlName="fromTime"
          [ngxTimepicker]="fullTime"
          [format]="24"
          readonly
        />
        <ngx-material-timepicker #fullTime></ngx-material-timepicker>
      </div>
      <div class="24hr-example">
        <label>Uhrzeit bis</label>
        <input
          placeholder="24hr format"
          aria-label="24hr format"
          formControlName="toTime"
          [ngxTimepicker]="fullTime2"
          [format]="24"
          readonly
        />
        <ngx-material-timepicker #fullTime2></ngx-material-timepicker>
      </div>
    </div>
  </div>
  <label>Typ</label>
  <mat-select formControlName="type" name="type">
    <mat-option value="bookkeeping">Buchhaltung</mat-option>
    <mat-option value="orders_by_product">Produktbestellungen</mat-option>
    <mat-option value="orders_by_customer"
      >Bestellungen gruppiert nach Kunden</mat-option
    >
    <mat-option value="orders_with_products"
      >Bestellungen mit Produktsummen</mat-option
    >
  </mat-select>
  <button mat-raised-button (click)="clickGenerate()">Erstelle Bericht</button>
</form>
