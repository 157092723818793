import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from '@pisci/requestManager';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CustomerChangePasswordComponent } from './customer-change-password/customer-change-password.component';
import { format, parseISO } from 'date-fns';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],

})
export class CustomersComponent implements OnInit {
  customers$;


  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      field: 'created_at',
      headerName: 'Registriert am',
      valueFormatter: (params) => {
        return format(parseISO(params.value), 'dd.MM.yyyy');
      }
    },
    { field: 'email' },

    {
      field: 'firstname',

    },
    { field: 'lastname' },
    {
      field: 'orderCount',
      maxWidth: 100
    },
    { field: 'orderCashSum', maxWidth: 100 },
    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/customers/' + data.id);
            }
          },

        ]
      }
    }
  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;
  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private rm: RequestService, private router: Router, private modalService: MatDialog) {
    this.customers$ = this.selectCustomers();
    this.searchControl.valueChanges.subscribe(v => {
      if(v)
      this.agGrid.api.setGridOption("quickFilterText",v);
    })
  }

  selectCustomers() {
    return this.rm.getAllPages('customer');
  }

  ngOnInit(): void {
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.rm.getAllPages('customer');
    this.agGrid.api.sizeColumnsToFit()
  }

  // Example of consuming Grid Event
  searchControl= new FormControl('');
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }



}
