import { Injectable } from '@angular/core';
import { expand, filter, map, mergeMap, reduce, tap } from 'rxjs/operators';

import { RequestService } from '@pisci/requestManager';

import { ICategory, IProduct, ITag } from '@app/data-interfaces';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  addEntities,
  entitiesPropsFactory,
  selectActiveEntity,
  selectAllEntities,
  selectAllEntitiesApply,
  selectEntities,
  selectEntity,
  selectMany,
  selectManyByPredicate,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import {
  withRequestsCache,
  createRequestsCacheOperator,
  updateRequestCache
} from '@ngneat/elf-requests';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { ProducerService } from '@cw/data-producers';
import { isString, toNumber } from 'lodash';
import { ISubscription } from '@app/data-interfaces';

const { categoryEntitiesRef, withCategoryEntities } =
  entitiesPropsFactory('category');
const { tagEntitiesRef, withTagEntities } = entitiesPropsFactory('tag');
const productCatalogStore = createStore(
  { name: 'product-catalog' },

  withEntities<IProduct>(),
  withCategoryEntities<ICategory>(),
  withTagEntities<ITag>(),
  withProps<{
    loading: { products: boolean; product: boolean };
    activeCategoryId?: ICategory['id'];
  }>({
    loading: { product: false, products: false }
  }),
  withRequestsCache<'product-catalog' | `product-catalog-${string}`>(),
  withActiveId()
);

export const skipWhileCatalogCache =
  createRequestsCacheOperator(productCatalogStore);

export const persistCatalog = persistState(productCatalogStore, {
  key: 'product-catalog-persist',
  storage: localStorageStrategy
});

@Injectable({ providedIn: 'root' })
export class ProductCatalogService {
  constructor(
    private rm: RequestService,
    private producerService: ProducerService
  ) {
  }

  selectProductsByTag(id: number) {
    return productCatalogStore.pipe(
      selectAllEntitiesApply({
        filterEntity: (e) =>
          e.tags && e.tags?.findIndex((tag) => tag.id === id) != -1
      })
    );
  }

  selectProductById(id: number) {
    return productCatalogStore.pipe(selectEntity(id));
  }

  selectProductByIds(ids: number[]): Observable<IProduct[]> {
    return productCatalogStore.pipe(selectMany(ids));
  }

  searchProduct(searchText: string) {
    return this.rm
      .post('product/search', {
        search: { value: searchText, case_sensitive: false }
      })
      .pipe(map((result: any) => result.data));
  }

  searchProductAll(searchText: string) {
    return this.rm
      .post('product/search?query=all', {
        search: { value: searchText, case_sensitive: false }
      })
      .pipe(
        expand((response: any) => {
          if(response?.links?.next) {
            return this.rm.post('product/search?query=all&page='+(parseInt(response.meta?.current_page)+1), {
              search: { value: searchText, case_sensitive: false }
            });
          } else {
            return EMPTY;
          }

        }),
        reduce((acc, curr) => {
          acc = acc.concat(curr.data);
          return acc;
        },[]));


  }

  filterProductsByCategory(products: IProduct[], id: number) {
    return products.filter((product) => {
      if (product.categories.findIndex((c) => c.id == id) !== -1) {
        return true;
      }
      return false;
    });
  }

  filterProductsByTag(products: IProduct[], id: number) {
    return products.filter((product) => {
      if (product.tags.findIndex((c) => c.id == id) !== -1) {
        return true;
      }
      return false;
    });
  }

  loadAdminCategories() {
    return this.rm.getAllPages('/category?select=all').pipe(
      tap((response: any) => {
        this.updateCategories(response);
      }),
      skipWhileCatalogCache('product-catalog-categories')
    );
  }

  loadCategories() {
    return this.rm.getAllPages('/category').pipe(
      tap((response: any) => {
        this.updateCategories(response);
      }),
      skipWhileCatalogCache('product-catalog-categories')
    );
  }

  getCategory(id: any) {
    return this.rm.get('/category/' + id);
  }

  saveCategory(cat: Partial<ICategory>) {
    if (cat.id) {
      return this.rm.put('/category/' + cat.id, cat);
    } else {
      return this.rm.post('/category', cat);
    }
  }

  deleteCategory(cat: Partial<ICategory>) {
    if (cat.id) {
      return this.rm.delete('/category/' + cat.id);
    } else {
      return of([]);
    }
  }

  selectCategories() {
    return productCatalogStore.pipe(
      selectAllEntities({ ref: categoryEntitiesRef })
    );
  }

  getTag(id: any) {
    return this.rm.get('/tags/' + id + '?include=media');
  }

  saveTag(cat: Partial<ICategory>) {
    if (cat.id) {
      return this.rm.put('/tags/' + cat.id, cat);
    } else {
      return this.rm.post('/tags', cat);
    }
  }

  saveTagImage(id: any, image: any) {
    const data = new FormData();
    data.append('img', image);
    return this.rm.post('/tags/' + id + '/image', data);
  }

  deleteTag(cat: Partial<ICategory>) {
    if (cat.id) {
      return this.rm.delete('/tags/' + cat.id);
    } else {
      return of([]);
    }
  }

  loadTags() {
    return this.rm.getAllPages('/tags?include=media').pipe(
      tap((response: any) => {
        this.updateTags(response);
      }),
      skipWhileCatalogCache('product-catalog-tags')
    );
  }

  selectTags() {
    return productCatalogStore.pipe(selectAllEntities({ ref: tagEntitiesRef }));
  }

  selectTag(id: any) {
    return productCatalogStore.pipe(selectEntity(id, { ref: tagEntitiesRef }));
  }

  getProductsForCategory(id: number) {
    productCatalogStore.update(setProps({ activeCategoryId: id }));
  }

  setActiveProduct(id: number) {
    productCatalogStore.update(setActiveId(id));
  }

  selectActiveCategoryProducts() {
    return combineLatest([
      productCatalogStore.pipe(select((state) => state.activeCategoryId)),
      productCatalogStore.pipe(selectAllEntities())
    ]).pipe(
      map(([categoryId, products]) => {
        return products.filter(
          (product) =>
            product.categories.findIndex((c) => c.id === categoryId) == -1
        );
      })
    );
  }

  selectProductsForCategory(id: number | string) {
    if (isString(id)) {
      id = toNumber(id);
    }

    return productCatalogStore.pipe(selectAllEntities()).pipe(
      map((products) => {
        console.log(products, id);
        return products.filter(
          (product) => product.categories.findIndex((c) => c.id === id) !== -1
        );
      })
    );
  }

  selectProducts() {
    return productCatalogStore.pipe(selectAllEntities());
  }

  selectProductActive() {
    return productCatalogStore.pipe(selectActiveEntity());
  }

  loadProductActive(id: number) {
    productCatalogStore.update(
      setProps({
        loading: { product: true, products: false }
      })
    );
    this.rm
      .get<Object>('/product/' + id + '/variations')
      .subscribe((response) => {
        productCatalogStore.update(
          updateEntities(id, (product) => ({ ...product, ...response }))
        );
        productCatalogStore.update(
          setProps({
            loading: { product: false, products: false }
          })
        );
      });
  }

  loadProducts() {
    return this.rm.getAllPages('/product').pipe(
      mergeMap((products: IProduct[]) =>
        this.producerService.getProducers().pipe(
          map((producers) => {
            return products.map((product: IProduct) => {
              if (product.producers) {
                product.producers = product.producers.map((id: any) => {
                  let found = producers.find(
                    (producer: any) => producer.id === id
                  );
                  if (found) {
                    return found;
                  } else return id;
                });
              }

              return product;
            });
          })
        )
      ),
      tap((response) => {
        productCatalogStore.update(
          setEntities(response),
          updateRequestCache('product-catalog', { ttl: 1000 })
        );
      }),
      skipWhileCatalogCache('product-catalog')
    );
  }

  isLoadingProduct() {
    return productCatalogStore.pipe(select((state) => state.loading.product));
    //return this.query.select((state) => state.loading.product);
  }

  /**
   * Only sorted by ID right now
   * @returns Observable<ICategory[]>
   */
  selectOrderedCategories() {
    return productCatalogStore.pipe(
      selectAllEntities({ ref: categoryEntitiesRef })
    );
  }

  updateCategories(responseCategories: any[]) {
    /*  let categories: any = { items: {}, ordering: [] };
    responseCategories.forEach((respCat) => {
      categories.items[respCat.id] = respCat;
      categories.ordering[respCat.ordering] = respCat.id;
    });
*/
    productCatalogStore.update(
      setEntities(responseCategories, { ref: categoryEntitiesRef })
    );
  }

  updateTags(responseTags: any[]) {
    let tags: any = [];
    responseTags.forEach((respCat) => {
      tags.push(respCat);
    });

    productCatalogStore.update(setEntities(tags, { ref: tagEntitiesRef }));
  }
}
