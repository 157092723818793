import { Pipe, PipeTransform } from '@angular/core';
import {Tax, TaxDTO} from "@app/data-interfaces";

@Pipe({
  name: 'taxrateTransform'
})
export class TaxrateTransformPipe implements PipeTransform {

  transform(value: Tax|TaxDTO, ...args: unknown[]): unknown {
    if(this.isTaxDTO(value)) {
      return value.taxrate + '%';
    }
    return value.taxgroup.name + '%'
  }

  isTaxDTO(value: Tax|TaxDTO): value is TaxDTO {
    return (value as TaxDTO).taxrate !== undefined;
  }

}
