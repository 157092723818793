<ng-container *ngIf="producers$ | async as producers">
  <input placeholder="Produzent" [formControl]="searchControl" /><button
    mat-raised-button
    color="primary"
    [routerLink]="['/producers/new']"
  >
    Produzent hinzufügen
  </button>
  <div
    class="product-list-item"
    [routerLink]="['/producers/', producer.id]"
    *ngFor="
      let producer of getPaginatedProducts(getFilteredProducts(producers));
      trackBy: identify
    "
  >
    {{ producer.name }}
  </div>
  <button *ngIf="pageNumber > 0" (click)="pageNumber = pageNumber - 1">
    Zurück</button
  ><button
    *ngIf="hasNextPage(getFilteredProducts(producers))"
    (click)="pageNumber = pageNumber + 1"
  >
    Nächste
  </button>
</ng-container>
