import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IStock } from '@app/data-interfaces';
import { ProductService } from '@app/shared/data-products';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import {
  StocksService,
  WarehouseService,
} from '@app/shared/data-stockmanaging';
import { forkJoin, map, mergeMap, Observable, of, scan, switchMap } from 'rxjs';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss'],
})
export class StocksComponent implements OnInit {
  stocks$: Observable<IStock[]>;

  constructor(
    private stocks: StocksService,
    private ws: WarehouseService,
    private products: ProductService,
    private router: Router
  ) {
    this.stocks$ = this.stocks.select();
  }

  ngOnInit(): void {
    this.stocks.load().subscribe();
    this.ws.load().subscribe();
  }

  getWareHouseName(id: number) {
    return this.ws.selectWarehouse(id).pipe(map((ws: any) => ws.name));
  }

  clickStock(stock: IStock) {
    this.router.navigateByUrl(
      '/stockmanaging/stocks/' + stock.warehouse_id + '/' + stock.product_id
    );
  }

  clickAddStock() {
    this.router.navigateByUrl('/stockmanaging/stocks/n/n');
  }
}
