import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMiniCartComponent } from './ui-mini-cart/ui-mini-cart.component';

import { IonicModule } from '@ionic/angular';
import { MatSortModule } from '@angular/material/sort';

import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiCartOverviewComponent } from './ui-cart-overview/ui-cart-overview.component';
import { UiCartSubscriptionsChangesComponent } from './ui-cart-subscriptions-changes/ui-cart-subscriptions-changes.component';
import { CartSubscriptionQuantityModalComponent } from './cart-subscription-quantity-modal/cart-subscription-quantity-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AddtocartModule } from '@cw-addtocart';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SubscriptionCartListComponent } from './subscription-cart-list/subscription-cart-list.component';
import { TaxrateTransformPipe } from './taxrate-transform.pipe';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { SubscriptionChangesListComponent } from './subscription-changes-list/subscription-changes-list.component';
import { ExpandableComponent } from '@app/ui/common/common-ui-elements';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    UiMiniCartComponent,
    UiCartOverviewComponent,
    UiCartSubscriptionsChangesComponent,
    CartSubscriptionQuantityModalComponent,
  SubscriptionCartListComponent, SubscriptionChangesListComponent,TaxrateTransformPipe,SubscriptionChangesListComponent,],
  imports: [
    CommonModule,
    FormsModule,
    MatSortModule,
    MatTableModule,
    IonicModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    AddtocartModule,
    MatSlideToggleModule,
    MatButtonModule,
    RouterLink,
    ExpandableComponent

  ],
  exports: [
    UiMiniCartComponent,
    MatTableModule,
    MatSortModule,
    UiCartOverviewComponent,
    UiCartSubscriptionsChangesComponent,
    SubscriptionCartListComponent,
    SubscriptionChangesListComponent

  ]
})
export class UiMiniCartModule {}
