<h1>Verpackungsgruppen</h1>
<button mat-raised-button routerLink="/packaging_groups/ordering">
  Sortieren
</button>
<button mat-raised-button routerLink="/packaging_groups/products">
  Produkte zuordnen
</button>
<button mat-raised-button (click)="clickAdd()">Hinzufügen</button>
<div *ngIf="list$ | async as list">
  <div *ngFor="let item of list">
    {{ item.name }} <mat-icon (click)="clickItem(item)">edit</mat-icon>
  </div>
</div>
