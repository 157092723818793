import { Injectable } from '@angular/core';
import { IInvoice } from '@app/data-interfaces';
import { Store, StoreConfig } from '@datorama/akita';

export interface InvoiceState {
  currentInvoice?: IInvoice;
  offlineInvoice: IInvoice[];
}

export function createInitialState(): InvoiceState {
  return {
    offlineInvoice: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invoice' })
export class InvoiceStore extends Store<InvoiceState> {
  constructor() {
    super(createInitialState());
  }
}
