import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddtocartComponent } from './addtocart/addtocart.component';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { AddtocartminiComponent } from './addtocartmini/addtocartmini.component';

import { SubscriptionModalComponent } from './subscription-modal/subscription-modal.component';
import { SubscriptionAddComponent } from './subscription-add/subscription-add.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NumberButtonInputComponent } from './number-button-input/number-button-input.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    AddtocartComponent,
    AddtocartminiComponent,
    SubscriptionAddComponent,

  ],
  imports: [
    SubscriptionModalComponent,
    NumberButtonInputComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule
  ],
  exports: [AddtocartComponent, AddtocartminiComponent, NumberButtonInputComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberButtonInputComponent)
    }
  ]

})
export class AddtocartModule {
}
