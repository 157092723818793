import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatButtonModule } from '@angular/material/button';

export type ActionButton = { label: string, action: (data: any) => void };

@Component({
  selector: 'app-data-table-action-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './data-table-action-button.component.html',
  styleUrls: ['./data-table-action-button.component.scss']
})
export class DataTableActionButtonComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams & { buttons: ActionButton[] }
  buttons: ActionButton[] = [];

  agInit(params: ICellRendererParams & { buttons: ActionButton[] }): void {
    this.params = params;
    this.updateButtons(params.buttons)
  }

  refresh(params: ICellRendererParams & { buttons: ActionButton[] }): boolean {
    this.params = params;
    this.updateButtons(params.buttons);
    return false;
  }

  updateButtons(buttons: any[]) {
    this.buttons = buttons;
  }

  clickButton(button: ActionButton['action']) {
    button(this.params.data);
  }
}
