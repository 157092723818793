<button mat-raised-button routerLink="/vouchers/new">
  Gutschein hinzufügen
</button>
<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-alpine"
  [getRowId]="getRowId"
  [pagination]='true'
  [paginationAutoPageSize]=true
  [rowSelection]="'multiple'"
  [tooltipInteraction]="true"
  [suppressRowClickSelection]="true"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  (cellClicked)="onCellClicked($event)"
  (gridReady)="onGridReady($event)"

>

</ag-grid-angular>
<div>
  <div class="list">
    <!--ng-container *ngFor="let v of vouchers$ | async">
      <div>{{ v.code }} {{ getType(v) }} {{ v.value | number: '1.2' }}</div>
      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/vouchers', v.id]"
        >
          Bearbeiten
        </button>
      </div>
    </ng-container-->
  </div>
</div>
