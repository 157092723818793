import { Component, OnInit } from '@angular/core';
import { AbstractAddToCart } from '../abstract-add-to-cart';

@Component({
  selector: 'addtocart',
  templateUrl: './addtocart.component.html',
  styleUrls: ['./addtocart.component.scss'],
})
export class AddtocartComponent extends AbstractAddToCart implements OnInit {

}
