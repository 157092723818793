import { Injectable } from '@angular/core';
import { IWarehouse } from '@app/data-interfaces';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { tap } from 'rxjs';

const warehouseStore = createStore(
  { name: 'warehouse' },
  withEntities<IWarehouse>()
);

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private rs: RequestService) {}
  load() {
    return this.rs.getAllPages('warehouse').pipe(
      tap((response) => {
        warehouseStore.update(setEntities(response));
      })
    );
  }

  loadById(id: string) {
    return this.rs.get('warehouse/' + id).pipe(
      tap((response: any) => {
        warehouseStore.update(upsertEntities(response));
      })
    );
  }

  selectWarehouses() {
    return warehouseStore.pipe(selectAllEntities());
  }
  selectWarehouse(id: any) {
    return warehouseStore.pipe(selectEntity(id));
  }

  upsertWarehouse(data: IWarehouse) {
    if (data.id) {
      return this.rs
        .put('warehouse/' + data.id, _.omit(data, 'id'))
        .pipe(tap((result: any) => this.loadById(result.id).subscribe()));
    } else {
      return this.rs
        .post('warehouse', data)
        .pipe(tap((result: any) => this.loadById(result.id).subscribe()));
    }
  }
}
