import {
  AfterContentInit,
  AfterViewInit,
  Component, inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';

import { OrderService } from '@pisci/data-orders';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { isArray, isObject, isString } from 'lodash';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  delay,
  finalize,
  map,
  mergeAll,
  Observable,
  startWith,
  Subject, switchMap,
  tap
} from 'rxjs';
import { LoadingService } from '../../loading.service';
import { IOrder } from '@app/data-interfaces';
import { CellClickedEvent, ColDef, GridApi, GridOptions, GridReadyEvent, TooltipShowEvent } from 'ag-grid-community';
import { format, parseISO } from 'date-fns';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrderTooltipComponent } from './order-tooltip/order-tooltip.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements AfterViewInit, OnInit {
  searchControl = new UntypedFormControl('');
  sortControl = new UntypedFormControl('id');
  sortOrder = new UntypedFormControl('desc');
  selectedOrderIds: any[] = [];
  orders$: Observable<IOrder[]>;
  pageSubject$ = new BehaviorSubject(1);
  searchText = '';
  showAllControl = new UntypedFormControl(false);
  selectedOrders = [];
  public pageNumber = 0;
  limit = 50;
  orderFormGroup = new UntypedFormGroup({
    orderIds: new UntypedFormArray([])
  });
  orderIdControlArray = new UntypedFormArray([]);
  ordersData: any[] = [];

  loading = false;
  dtOptions: any = {};
  dtTrigger: BehaviorSubject<any> = new BehaviorSubject<any>(1);
  orderCount = 0;
  tableInit = false;
  @ViewChild('dt1')
  dt1: any;
  cols: any = [];

  /*
   <td [routerLink]="['/orders/', order.id]">{{ order.id }}</td>
          <td>{{ order['status'] }}</td>
          <td>{{ order['payment_method'] }}</td>
          <td>{{ order.number }}</td>
          <td [routerLink]="['/orders/', order.id]">
            {{ order.billing_address?.name }} {{ order.billing_address?.city }}
          </td>
          <td>{{ order.customer?.email ?? '' }}</td>
          <td>{{ order.created_at | date: 'dd.MM.yyyy HH:mm' }}</td>
          <td>{{ order['total'] }} €</td>
   */

  router = inject(Router);
  public columnDefs: ColDef[] = [
    {
      field: 'select_column',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      field: 'id',
      minWidth: 100,
      tooltipComponent: OrderTooltipComponent,
      tooltipValueGetter: (data) => {
        console.log(data);
        return JSON.stringify(data.data);
      },
      onCellClicked: (data) => {
        this.router.navigateByUrl('/orders/' + data.data.id);

      }
    },
    {
      field: 'status',
      minWidth: 100
    },
    {
      field: 'number',
      minWidth: 100
    },
    {
      field: 'customer.email',
      minWidth: 100
    },
    {
      field: 'billing_address.name',
      minWidth: 100
    },
    {
      field: 'billing_address.city',
      minWidth: 100
    },

    {
      field: 'payment_method',
      minWidth: 100

    },
    {
      field: 'created_at',
      valueFormatter: (params) => {
        return format(parseISO(params.value), 'dd.MM.yyyy');
      }
    },
    { field: 'total',
    minWidth:50},

    {
      field: 'action',
      minWidth: 100,
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/orders/' + data.id);
            }
          }
        ]
      }
    }
  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;
  private gridApi!: GridApi;

  constructor(
    private orderService: OrderService,
    private rm: RequestService,
    private loadingService: LoadingService
  ) {
    this.orders$ = combineLatest([

      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(2000)),
      //   this.sortControl.valueChanges.pipe(startWith('id')),
      //   this.sortOrder.valueChanges.pipe(startWith('desc')),
      // this.pageSubject$.asObservable().pipe(startWith('1')),
      this.showAllControl.valueChanges.pipe(startWith(false))
    ]).pipe(
      switchMap(([searchText, showAll]) => {
        if (searchText != '') {
          return this.orderService.searchOrders(searchText);
        } else {
          return this.orderService.searchOrders(searchText);
        }


      }),
      takeUntilDestroyed()
    );
    this.searchControl.valueChanges.subscribe(() => {

    });
  }


  searchFunction = (object: any, search: string) => {
    return JSON.stringify(object).indexOf(search) !== -1;
  };


  ngOnInit(): void {
    //this.orderService.loadOrders().subscribe();

  }

  ngAfterViewInit(): void {
    console.timeEnd('load');
  }

  getRowId(params: any) {
    return params.data.id.toString();
  }

  onCellClicked(e: CellClickedEvent): void {
    // this.router.navigateByUrl('invoice/'+e.data.id);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.orders$.pipe(debounceTime(1)).subscribe(orders => {

      this.gridApi.setGridOption("rowData", orders);
      this.gridApi.sizeColumnsToFit();
    });
  }


  clickViewPackagingSlips() {
    console.log();
    this.loadingService.setLoading(true);
    return this.rm
      .postGetFile('/order/packaging/pdf', {
        orderIds: this.gridApi.getSelectedRows().map((i) => i.id)
      })
      .pipe(
        finalize(() => {
          this.loadingService.setLoading(false);
        }),
        catchError((err) => {
          console.error(err);
          throw err;
        })
      )
      .subscribe((response: any) => {
        const blob = new Blob([response.body], {
          type: response.headers.get('content-type')
        });
        //  let fileName =  response.headers.get('content-disposition').split(';')[0];
        const fileName = 'report.pdf';
        const file = new File([blob], fileName, {
          type: response.headers.get('content-type')
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  clickExportSet() {
    this.rm
      .post('exports', {
        lines: { order: this.gridApi.getSelectedRows().map((i) => i.id) },
        type: 'packaging'
      })
      .subscribe(() => alert('Exportset Erstellt'));
  }

  clear(table: Table) {
    table.clear();
  }

  filterText(e: any) {
    this.dt1.filterGlobal(e.target.value, 'contains');
  }

  showRowTooltip($event: TooltipShowEvent<any>) {
    // $event.tooltipGui.innerHTML = $event.context;
  }
}
