<!--
  $text = str_replace("{order_number}", $order->number, $text);
        $text = str_replace("{order_date}", $order->created_at->format("d.m.Y"), $text);
        $text = str_replace("{order_time}", $order->created_at->format("H:i"), $text);
        $text = str_replace("{order_total}", $order->total, $text);
        $text = str_replace("{order_url}", $this->frontend_url . "/order/" . $order->id, $text);
        $text = str_replace("{customer_name}", $order->customer->name, $text);
-->

<div [formGroup]="subscriptionNotification" class="subscription-notification">
<p class="placehodlers">
  Platzhalter:
  <span>&#123;order_number}</span>
  <span>{{ "{" }}order_date}</span>
  <span>&#123;order_time}</span>
  <span>&#123;order_total}</span>
  <span>&#123;order_url}</span>
  <span>&#123;customer_name}</span>
</p>
    <mat-label>Begrüßung</mat-label>
    <ckeditor [editor]="editor" formControlName="greeting" name="greeting" required></ckeditor>

    <mat-error *ngIf="subscriptionNotification.get('greeting')?.hasError('required')">
      Bitte geben Sie eine Begrüßung ein.

    </mat-error>

  <!-- footer -->

    <mat-label>Footer</mat-label>
    <ckeditor [editor]="editor" formControlName="footer" name="footer" required></ckeditor>
    <mat-error *ngIf="subscriptionNotification.get('footer')?.hasError('required')">
      Bitte geben Sie einen Footer ein.
    </mat-error>

  <!-- signature -->

    <mat-label>Signatur</mat-label>
    <ckeditor [editor]="editor" formControlName="signature" name="signature" required></ckeditor>
    <mat-error *ngIf="subscriptionNotification.get('signature')?.hasError('required')">
      Bitte geben Sie eine Signatur ein.
    </mat-error>

</div>
<div [formGroup]="emails" >
  <h2>Bestell Email</h2>
  <ckeditor [editor]="editor" formControlName="order_created_text" name="order_created_text" required></ckeditor>
  <h2>Abo Bestellung Abgebrochen Email</h2>
  <ckeditor [editor]="editor" formControlName="order_cancelled_text" name="order_cancelled_text" required></ckeditor>
  <h2>Betreff Email</h2>
  <input formControlName="subject_prefix" name="subject_prefix" required>

</div>
<button mat-raised-button (click)="save()">Speichern</button>
