import jwt_decode from 'jwt-decode';

export class AuthHelper {
  static getTokenExpirationDate(token: string): Date | undefined {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) return;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  static isTokenExpired(token?: string): boolean {
    //if(!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }
}
