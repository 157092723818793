<button mat-raised-button (click)="saveChanges()">Speichern </button>
<input [(ngModel)]="search" (ngModelChange)="triggerChange()">
<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-alpine"
  [getRowId]="getRowId"
  [pagination]='true'
  [paginationAutoPageSize]=true
  [rowSelection]="'multiple'"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"

  (gridReady)="onGridReady($event)">
</ag-grid-angular>


