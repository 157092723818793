<h1>Verpackungsgruppen</h1>
<button mat-raised-button routerLink="/packaging_groups">Übersicht</button>
<ng-container *ngIf="list$ | async as list">
  <button mat-raised-button (click)="clickSave(list)">Speichern</button>

  <div (cdkDropListDropped)="drop(list, $event)" cdkDropList>
    <div cdkDrag *ngFor="let item of list">
      <mat-icon>list</mat-icon>{{ item.name }}
    </div>
  </div>
</ng-container>
