<div
  *ngIf="order$ | async as order"
  class="order-detail"
  [formGroup]="formGroup"
>
  <div>{{ order['status'] }}</div>
  <div *ngIf="order.created_at">
    {{ order.created_at | date: 'dd.mm.yyyy HH:mm' }}
  </div>
  <div class="address" *ngIf="order.billing_address">
    <h4>Rechnungsadresse</h4>
    <div *ngIf="!editing">
      {{ order.billing_address.name }}<br />{{ order.billing_address.street }}
      {{ order.billing_address.street_number }}
      {{ order.billing_address.street_2 }}<br />{{ order.billing_address.zip }}
      {{ order.billing_address.city }}<br />{{ order.billing_address.country }}
    </div>
    <div *ngIf="editing" formGroupName="billing_address">
      <div>
        <label>Name (Vorname Nachname oder Firmenbezeichnung)</label
        ><input type="text" formControlName="name" />
      </div>

      <div class="street">
        <div>
          <label>Straße </label><input type="text" formControlName="street" />
        </div>
        <div>
          <label>Hausnummer </label
          ><input type="text" formControlName="street_number" />
        </div>
      </div>
      <div>
        <label>Zusatz </label><input type="text" formControlName="street_2" />
      </div>
      <div><label>Plz </label><input type="text" formControlName="zip" /></div>
      <div><label>Ort </label><input type="text" formControlName="city" /></div>
    </div>
  </div>
  <div class="address" *ngIf="order.shipping_address">
    <h4>Lieferadresse</h4>
    <div *ngIf="!editing">
      {{ order.shipping_address.name }}<br />{{ order.shipping_address.street }}
      {{ order.shipping_address.street_number }}
      {{ order.shipping_address.street_2 }}<br />{{
        order.shipping_address.zip
      }}
      {{ order.shipping_address.city }}<br />{{
        order.shipping_address.country
      }}
    </div>
    <div *ngIf="editing" formGroupName="shipping_address">
      <div>
        <label>Name (Vorname Nachname oder Firmenbezeichnung)</label
        ><input type="text" formControlName="name" />
      </div>

      <div class="street">
        <div>
          <label>Straße </label><input type="text" formControlName="street" />
        </div>
        <div>
          <label>Hausnummer </label
          ><input type="text" formControlName="street_number" />
        </div>
      </div>
      <div>
        <label>Zusatz </label><input type="text" formControlName="street_2" />
      </div>
      <div><label>Plz </label><input type="text" formControlName="zip" /></div>
      <div><label>Ort </label><input type="text" formControlName="city" /></div>
    </div>
  </div>

  <div *ngIf="order.lineItems" class="lines">
    <table>
      <thead>
        <th>Name</th>
        <th>Menge</th>
        <th>Preis</th>
        <th>Zeilensumme</th>
      </thead>
      <tbody formArrayName="lines">
        <tr
          *ngFor="let line of lines.controls; let i = index"
          class="line"
          [formGroupName]="i"
        >
          <td>{{ order.lineItems[i].name }}</td>
          <td>
            <input formControlName="quantity" />
          </td>
          <td>
            <input formControlName="amountGross" />
          </td>
          <td>
            {{
              order.lineItems[i].price.getTotalAmountGross(
                order.lineItems[i].quantity
              ) | number: '1.2'
            }}
            €
          </td>
        </tr>
      </tbody>
      <tbody formArrayName="newCustomLines">
        <tr
          *ngFor="let line of newCustomLines.controls; let i = index"
          class="line"
          [formGroupName]="i"
        >
          <td>
            <ng-container *ngIf="editing"
              ><input formControlName="name"
            /></ng-container>
          </td>
          <td>
            <ng-container *ngIf="editing"
              ><input formControlName="quantity"
            /></ng-container>
          </td>
          <td>
            <ng-container *ngIf="editing"
              ><input formControlName="amountGross"
            /></ng-container>
          </td>
          <td></td>
        </tr>
      </tbody>
      <tbody formArrayName="newLines">
        <tr
          *ngFor="let line of newLines.controls; let i = index"
          class="line"
          [formGroupName]="i"
        >
          <td>{{ line.value.productId }}</td>
          <td>
            <ng-container *ngIf="!editing">{{
              line.value.quantity
            }}</ng-container>
            <ng-container *ngIf="editing"
              ><input formControlName="quantity"
            /></ng-container>
          </td>
          <td>
            <ng-container *ngIf="!editing"></ng-container>
            <ng-container *ngIf="editing"></ng-container>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-container *ngIf="editing">
    <div>
      <input formControlName="newProductId" /><button
        (click)="clickAddProduct()"
      >
        Produkt hinzufügen
      </button>
    </div>
    <div>
      <button (click)="clickAddLine()">Zeile hinzufügen</button>
    </div>
  </ng-container>
  <div class="total" *ngIf="order.totalPrice">
    <div>Brutto {{ order.totalPrice.getAmountGross() | number: '1.2' }} €</div>
    <div>Netto {{ order.totalPrice.getAmountNet() | number: '1.2' }} €</div>
    <div *ngFor="let tax of order.totalPrice.taxes">
      {{ tax.taxgroup.label }} {{ tax.getAmount() | number: '1.2' }} €
    </div>
  </div>
  <ng-container *ngIf="editing"
    ><button color="primary" mat-raised-button (click)="clickSave()">
      Speichern
    </button></ng-container
  >
  <div *ngIf="order.invoices">
    <h3>Rechnungen</h3>
    <div>
      <div *ngFor="let invoice of order.invoices">
        RechnungsNr. {{ invoice.number }}
        <button routerLink="/invoices/{{ invoice.id }}">zur Rechnung</button>
      </div>
    </div>
  </div>
</div>

<pre class="hidden">{{ order$ | async | json }}</pre>
