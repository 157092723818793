<ng-container *ngIf="products$ | async as products">
  <div class="actionbar">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/products/new']"
    >
      Produkt hinzufügen
    </button>

    <button     mat-raised-button
                (click)="batchEdit()">Batch Edit</button>
  </div>
  <input placeholder="Produktname" [formControl]="searchControl" />


</ng-container>



<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-alpine"
  [getRowId]="getRowId"
  [pagination]='true'
  [paginationAutoPageSize]=true
  [rowSelection]="'multiple'"
  [tooltipInteraction]="true"
  (tooltipShow)="showRowTooltip($event)"
  [suppressRowClickSelection]="true"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  (cellClicked)="onCellClicked($event)"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>
