import { Component, OnInit } from '@angular/core';
import { ICategory } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  editingCategory?: ICategory;
  addingCategory = false;
  categories$: Observable<ICategory[]>;
  constructor(private categoryService: ProductCatalogService) {
    this.categories$ = this.categoryService.selectCategories();
    this.categoryService.loadAdminCategories().subscribe();
  }

  editCategory(category: ICategory) {
    this.editingCategory = category;
  }
  isEditingCategory(category: ICategory) {
    return this.editingCategory === category;
  }

  addCategory() {
    this.addingCategory = true;
  }
  saveCategory(category: ICategory) {}

  removeCategory(category: ICategory) {}

  ngOnInit(): void {}
}
