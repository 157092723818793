import { IAddress } from './cart';
import { ILineItem } from './lineitem';
import { IPrice } from './price';

export interface IInvoice {
  id?: string;
  status?: string;
  total?: number;
  total_tax: number;
  discount_total: number;
  shipping_total: number;
  shipping_tax: number;
  discount_tax: number;
  payment_method: string;
  customer_note: string;
  number: string;
  order_key: string;
  total_price_id: string;
  total_price: IPrice;
  data: any;
  line_items: ILineItem[];
  created_at: string;
  billing_address?: IAddress;
}
