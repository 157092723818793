<ui-mini-cart [disableSubscriptionAction]="true"
></ui-mini-cart>

<app-subscription-cart-list [disableActions]="true"></app-subscription-cart-list>

<div>
  <!--<label>Adresse Übernehmen</label>
  <input type="checkbox" value="1" [formControl]="changeAddressControl">-->
  <app-base-duo-address-form [formControl]="addressControl"></app-base-duo-address-form>
  <div>Notiz
  <textarea [formControl]="noteControl"></textarea>
  </div>
</div>
