import { Component, inject } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


import { InvoiceService } from '@app/shared/data-invoice';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { EMPTY, map, mergeMap, Observable } from 'rxjs';
import { Order } from '@app/data-interfaces';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss'],
})
export class InvoiceEditComponent  {



  private fb = inject(UntypedFormBuilder);
  order$: Observable<Order>;
  editing = true;
  id = '';

  formGroup = new UntypedFormGroup({
    lines: new UntypedFormArray([]),
    billing_address: this.getAddressForm(),
    shipping_address: this.getAddressForm(),
    newProductId: new UntypedFormControl(),
    newLines: new UntypedFormArray([]),
    newCustomLines: new UntypedFormArray([]),
  });

  get lines() {
    return this.formGroup.get('lines') as UntypedFormArray;
  }

  get newLines() {
    return this.formGroup.get('newLines') as UntypedFormArray;
  }

  get newCustomLines() {
    return this.formGroup.get('newCustomLines') as UntypedFormArray;
  }
  private getAddressForm() {
    return this.fb.group({
      firstname: this.fb.control(''),
      lastname: this.fb.control(''),
      name: this.fb.control(''),
      street: this.fb.control('', Validators.required),
      street_number: this.fb.control('', Validators.required),
      street_2: this.fb.control(''),
      zip: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      country: this.fb.control('AT'),
    });
  }
  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,

    private rm: RequestService,
    private router: Router
  ) {
    this.formGroup.valueChanges.subscribe((value) => {
      console.log(value);
    });
    this.order$ = this.route.params.pipe(
      mergeMap((params) => {
        if (!params['id'] || params['id'] == '') {
          return EMPTY;
        }
        this.id = params['id'];

        return this.invoiceService.get(this.id).pipe(
          map((invoice) => {
            const order = new Order(invoice);
            const lineForm = this.lines;
            lineForm.clear();
            order.line_items.forEach((lineItem) => {
              lineForm.push(
                new UntypedFormGroup({
                  id: new UntypedFormControl(lineItem.id),
                  quantity: new UntypedFormControl(lineItem.quantity),
                  amountGross: new UntypedFormControl(lineItem.amount_gross),
                })
              );
            });
            console.log(lineForm);
            this.formGroup.patchValue(
              _.pick(order, ['billing_address', 'shipping_address'])
            );

            return order;
          })
        );
      })
    );
  }



  clickAddLine() {
    const lineForm = this.formGroup.get('newCustomLines') as UntypedFormArray;
    lineForm.push(
      new UntypedFormGroup({
        name: new UntypedFormControl('Zeile'),
        quantity: new UntypedFormControl(1),
        amountGross: new UntypedFormControl(1),
        amountNet: new UntypedFormControl(1),
      })
    );
  }
  clickAddProduct() {
    const id = this.formGroup.value.newProductId;
    this.addProductLine(id);
  }
  addProductLine(id: string) {
    const lineForm = this.formGroup.get('newLines') as UntypedFormArray;
    lineForm.push(
      new UntypedFormGroup({
        productId: new UntypedFormControl(id),
        quantity: new UntypedFormControl(1),
      })
    );
  }
  clickEditable() {
    this.editing = true;
  }
  clickSave() {
    const body: any = {
      lines: [],
      ..._.pick(this.formGroup.value, ['shipping_address', 'billing_address']),
    };

    const lines = this.formGroup.get('lines') as UntypedFormArray;

    lines.controls.forEach((lineFormGroup) => {
      body.lines.push(lineFormGroup.value);
    });
    const newLines = this.formGroup.get('newLines') as UntypedFormArray;
    newLines.controls.forEach((lineFormGroup) => {
      body.lines.push(lineFormGroup.value);
    });

    const newCustomLines = this.formGroup.get(
      'newCustomLines'
    ) as UntypedFormArray;
    newCustomLines.controls.forEach((lineFormGroup) => {
      const line = lineFormGroup.value;
      line.amountNet = line.amountGross;
      body.lines.push(line);
    });

    this.invoiceService.update(this.id, body).subscribe(() => {
      this.router.navigateByUrl('/invoices/' + this.id);
    });
  }
}
