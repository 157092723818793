import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  upsertEntitiesById,
  withEntities,
} from '@ngneat/elf-entities';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { delay, mergeMap, tap } from 'rxjs';

export interface IPackagingGroup {
  id: number;
  name: string;
  order: number;
  products?: any[];
}

const store = createStore(
  { name: 'packagingGroups' },
  withEntities<IPackagingGroup>()
);
@Injectable({
  providedIn: 'root',
})
export class PackagingGroupService {
  constructor(private rs: RequestService) {}

  loadAll() {
    return this.rs.getAllPages('/packaging_group').pipe(
      tap((response: any) => {
        store.update(upsertEntities(response));
      })
    );
  }

  loadId(id: number) {
    return this.rs.get('/packaging_group/' + id).pipe(
      tap((response: any) => {
        store.update(upsertEntitiesById(id, response));
      })
    );
  }

  selectAll() {
    return store.pipe(selectAllEntities());
  }

  selectId(id: number) {
    return store.pipe(selectEntity(id));
  }

  attachGroupForProduct(productId: number, groupIds: number[]) {
    return this.rs.post('product/' + productId + '/packaging_group/attach', {
      resources: groupIds,
    });
  }

  detachGroupForProduct(productId: number, groupIds: number[]) {
    return this.rs.delete('product/' + productId + '/packaging_group/detach', {
      resources: groupIds,
    });
  }

  save(item: any) {
    item = _.omitBy(item, (v) => v === null);
    if (item.id) {
      return this.rs
        .put('packaging_group/' + item.id, _.omit(item, ['id']))
        .pipe(mergeMap(() => this.loadId(item.id)));
    } else {
      return this.rs
        .post('packaging_group', item)
        .pipe(mergeMap(() => this.loadAll()));
    }
  }

  batchUpdate(items: any) {
    let body = {
      resources: _.reduce(
        items,
        (acc: any, item) => {
          acc[item.id] = _.omit(item, ['id']);
          return acc;
        },
        {}
      ),
    };
    return this.rs.patch('packaging_group/batch', body);
  }
}
