<div>
  <div *ngFor="let tag of tags$ | async">
    <div class="category">
      <div>{{ tag.name }}</div>
      <div class="actions">
        <button
          mat-raised-button
          color="primary"
          [routerLink]="['/tags', tag.id]"
        >
          Bearbeiten
        </button>
      </div>
    </div>
  </div>

  <button mat-raised-button routerLink="/tags/new">Tag hinzufügen</button>
</div>
