<div>
  <input placeholder="Suche" [formControl]="searchControl" />
  <ag-grid-angular
    style="width: 100%; height: 100%"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData$ | async"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
  ></ag-grid-angular>


</div>
