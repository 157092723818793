import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stockmanaging',
  templateUrl: './stockmanaging.component.html',
  styleUrls: ['./stockmanaging.component.scss'],
})
export class StockmanagingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
