import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, RequestService } from '@pisci/requestManager';
import { catchError, of } from 'rxjs';
import de from '@angular/common/locales/de';
import { SettingsService } from '@cw/data-settings';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'admin';

  constructor(
    public authService: AuthService,
    public rm: RequestService,
    public router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    public loadingService: LoadingService
  ) {
    authService;
  }

  clickLogout() {
    this.authService.logout();
  }
  refreshAuth() {

    if(window.location.href.endsWith('debug')) {
      return
    }
    if (this.authService.jwt) {
      this.rm
        .refresh()
        .pipe(
          catchError(() => {
            console.log('error auth');
            setTimeout(() => {
              this.authService.deleteLoginData();
              this.router.navigateByUrl('/login');
            }, 1);
            return of([]);
          })
        )
        .subscribe();
      console.log('refresh auth');
    } else {
      this.router.navigateByUrl('/login');
    }
  }
  ngOnInit(): void {
    this.settingsService.loadSettings().subscribe();
    registerLocaleData(de);
    this.refreshAuth();
    setInterval(() => {
      this.refreshAuth();
    }, 60 * 1000);
  }
  showMenu = true;

  menuToggle() {
    this.showMenu = !this.showMenu;
  }
}
