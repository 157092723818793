import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StocksService } from '@app/shared/data-stockmanaging';
import { map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-stocks-history',
  templateUrl: './stocks-history.component.html',
  styleUrls: ['./stocks-history.component.scss'],
})
export class StocksHistoryComponent implements OnInit {
  history$;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stockService: StocksService
  ) {
    this.history$ = this.route.paramMap.pipe(
      mergeMap((p: any) => {
        return this.stockService.getStocksHistory(
          p.params.product,
          p.params.warehouse
        );
      }),
      map((res: any) => res.data)
    );
  }

  ngOnInit(): void {}
}
