import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isArray } from 'lodash';
import { map, tap } from 'rxjs/operators';

import { IOrder, Order } from '@app/data-interfaces';
import { createStore, filterNil } from '@ngneat/elf';
import {
  deleteEntities,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  updateRequestCache,
  withRequestsCache,
} from '@ngneat/elf-requests';
import { ICart } from '@pisci/data-cart';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { OrderQuery } from './order.query';
import { OrderStore } from './order.store';

const orderStore = createStore(
  { name: 'orders' },
  withEntities<IOrder & { id: string }>(),
  withRequestsCache(),
);
export const skipWhileTodosCached = createRequestsCacheOperator(orderStore);

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private orderStore: OrderStore,
    private orderQuery: OrderQuery,
    private requestService: RequestService,
  ) {}
  searchOrder(term: string) {
    return this.requestService
      .post('/order/search', {
        search: {
          value: term,
        },
      })
      .pipe(map((result: any) => result.data));
  }

  scopeSearch(scope:string, params: string[]) {
    return this.requestService
      .post('/order/search', {
        scopes:[
          {"name" : scope, "parameters" : params}
        ],
      })
      .pipe(map((result: any) => result.data));
  }

  update(id: string, body: any) {
    return this.requestService.put('order/' + id, body).pipe(
      tap(() => {
        this.loadOrder(id).subscribe();
      }),
    );
  }

  getMyOrders(): Observable<Order[]> {
    return this.requestService.get('me/orders').pipe(
      map((orderResponse) => {
        if (isArray(orderResponse)) {
          return _.orderBy(
            orderResponse.map((order) => new Order(order)) as Order[],
            'id',
            'desc',
          );
        } else {
          return [];
        }
      }),
    );
  }
  getOrders() {
    return orderStore.pipe(selectAllEntities());
  }

  deleteOrder(id: any) {
    return this.requestService.delete('order/' + id).pipe(
      tap(() => {
        orderStore.update(updateRequestCache('orders'), deleteEntities(id));
      }),
    );
  }

  loadOrders() {
    return this.requestService.getAllPages('order').pipe(
      tap((orders) => {
        orderStore.update(updateRequestCache('orders'), setEntities(orders));
      }),
      skipWhileTodosCached('orders'),
    );
  }

  getOrder(id: string): Observable<Order> {
    return orderStore.pipe(
      selectEntity(id),
      filterNil(),
      map((orderRespone) => new Order(orderRespone)),
    );
    return this.requestService
      .get<IOrder>('order/' + id + '?include=invoices')
      .pipe(map((orderRespone) => new Order(orderRespone)));
  }

  loadOrder(id: string) {
    return this.requestService
      .get<IOrder>('order/' + id + '?include=invoices')
      .pipe(
        tap((orderRespone) => {
          orderStore.update(upsertEntities(orderRespone));
        }),
      );
  }
  createOrder(
    basket: ICart,
    billing_address: any,
    shipping_address: any,
    user_id: number | undefined,
  ) {
    let body: {
      basket: ICart;
      billing_address: any;
      shipping_address: any;
      user_id?: any;
    } = {
      basket,
      billing_address,
      shipping_address,
    };
    if (user_id != undefined) {
      body.user_id = user_id;
    }
    return this.requestService.post('/order', body);
  }

  createSubscription(
    basket: ICart,
    billing_address: any,
    shipping_address: any,
    user_id: number | undefined,
  ) {
    let body: {
      basket: ICart;
      billing_address: any;
      shipping_address: any;
      user_id?: any;
    } = {
      basket,
      billing_address,
      shipping_address,
    };
    if (user_id != undefined) {
      body.user_id = user_id;
    }
    return this.requestService.post('/subscription', body);
  }

  searchOrders(searchText: string) {
    return this.requestService.searchAllPagesExpanding('order/search?limit=100',{
      sort: [{
        field: 'id', direction: 'desc'
      }],
      scopes: [
        { name: 'fullTextSearch', parameters: [searchText] }
      ]

    });
  }
}
