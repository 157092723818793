<div>
  <table>
    <thead>
      <th>Datum</th>
      <th>Menge</th>
      <th>Änderung</th>
      <th>Begründung</th>
    </thead>
    <tbody>
      <tr *ngFor="let row of history$ | async">
        <td>{{ row.created_at | date: 'dd.MM.Y HH:mm:ss' }}</td>
        <td>{{ row.amount }}</td>
        <td>{{ row.change }}</td>
        <td>{{ row.reason }}</td>
      </tr>
    </tbody>
  </table>
</div>
