import { Injectable } from '@angular/core';
import { ITaxgroup } from '@app/data-interfaces';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  createRequestsCacheOperator,
  updateRequestCache,
  withRequestsCache,
} from '@ngneat/elf-requests';

import { RequestService } from '@pisci/requestManager';
import { map, Observable, shareReplay, tap } from 'rxjs';
export interface ISettingsStore {
  taxgroups: { [key: string]: ITaxgroup };
}
const settingsStore = createStore(
  { name: 'settings' },
  withProps<ISettingsStore>({ taxgroups: {} }),
  withRequestsCache()
);

export const skipSettingsCache = createRequestsCacheOperator(settingsStore);

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(public rm: RequestService) {}

  loadSettings() {
    return this.rm.get('/taxgroup').pipe(
      shareReplay(1),
      map((response: any) => {
        if (!response.data) {
          return {};
        }
        return response.data.reduce((obj: any, value: any) => {
          obj[value.id] = value;
          return obj;
        }, {});
      }),
      tap((data: any) => {
        settingsStore.update(
          updateRequestCache('taxgroups'),
          setProps({ taxgroups: data })
        );
      }),
      skipSettingsCache('taxgroups')
    );
  }

  getStripePublicKey(): Observable<string> {
    return this.rm.get<string>('/setting/stripe').pipe(shareReplay());
  }

  selectTaxgroups(): Observable<{ [key: number]: ITaxgroup }> {
    return settingsStore.pipe(select((state) => state.taxgroups));
  }

  getTaxgroups() {
    return settingsStore.getValue().taxgroups;
  }

  selectTaxgroupById(id: string) {
    return settingsStore.pipe(
      tap((state) => {
        if (!state.taxgroups[id]) {
          this.loadTaxgroups().subscribe();
        }
      },shareReplay(1)),
      select((state) => state.taxgroups),
      map((taxgroups) => taxgroups[id])
    );
  }

  private loadTaxgroups() {
    return this.rm.get('/taxgroup').pipe(
      map((response: any) => {
        if (!response.data) {
          return {};
        }
        return response.data.reduce((obj: any, value: any) => {
          obj[value.id] = value;
          return obj;
        }, {});
      }),
      tap((data: any) => {
        settingsStore.update(
          updateRequestCache('taxgroups'),
          setProps({ taxgroups: data })
        );
      }),
      skipSettingsCache('taxgroups')
    );
  }
}
