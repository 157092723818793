<div class="addtocart-wrapper">
  <app-number-button-input
    id="subAmount"
    [formControl]="amountControl"
  ></app-number-button-input>
  <button
    class="cart"
    [disabled]="disabled"
    (click)="clickAdd()"
    [class.freshlyAdded]="freshlyAdded"
  >
    <span class="material-icons">shopping_cart</span>
  </button>

  <button [class.hidden]="subscriptionEnabled === false" class="abo" (click)="clickAbo()">Abo</button>
</div>
