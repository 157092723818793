import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IPackagingGroup,
  PackagingGroupService,
} from '@app/shared/data-packaging-groups';
import { ModalProductSearchComponent } from '../../products/modal-product-search/modal-product-search.component';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { combineLatest, delay, map, mergeMap } from 'rxjs';
import * as _ from 'lodash';
import { ProductService } from '@app/shared/data-products';

@Component({
  selector: 'app-packaging-group-product',
  templateUrl: './packaging-group-product.component.html',
  styleUrls: ['./packaging-group-product.component.scss'],
})
export class PackagingGroupProductComponent implements OnInit {
  list$;
  products$;

  productPackaging: any = {};
  constructor(
    private dataService: PackagingGroupService,
    private modalService: MatDialog,
    private productService: ProductService
  ) {
    this.products$ = this.productService.selectProducts();
    this.list$ = combineLatest([
      this.dataService.selectAll(),
      this.products$,
    ]).pipe(
      map(([groups, products]: any): IPackagingGroup[] => {
        for (let group of groups) {
          if (group.products) {
            let found = [];
            for (let pid of group.products) {
              let p = _.find(products, (p) => p.id == pid);
              if (p) found.push(p);
            }
            if (found.length > 0) {
              group.products = found;
            }
          }
        }
        return groups;
      })
    );
  }
  ngOnInit(): void {
    this.dataService.loadAll().subscribe();
    this.productService.loadAdmin().subscribe();
  }

  clickItem(item: any) {
    let ref = this.modalService.open(ModalProductSearchComponent, {
      data: { admin: true },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.addProductToItemList(item, result);
      }
    });
  }

  clickDelete(item: any, product: any) {
    this.deleteProductFromItemList(item, product);
  }

  addProductToItemList(item: any, product: any) {
    this.dataService
      .attachGroupForProduct(product.id, [item.id])
      .pipe(delay(1))
      .subscribe(() => {
        this.dataService.loadAll().subscribe();
      });
  }

  deleteProductFromItemList(item: any, product: any) {
    this.dataService
      .detachGroupForProduct(product.id, [item.id])
      .pipe(delay(1))
      .subscribe(() => {
        this.dataService.loadAll().subscribe();
      });
  }
}
