import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RequestService } from '@pisci/requestManager';
import { ExportService } from 'libs/shared/data-exports/src';
import * as _ from 'lodash';

import {
  catchError,
  combineLatest,
  EMPTY,
  forkJoin,
  map,
  mergeMap,
  startWith,
  Subject,
  tap,
} from 'rxjs';
import { ExportsAddModalComponent } from './exports-add-modal/exports-add-modal.component';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
})
export class ExportsComponent implements OnInit {
  refresh$ = new Subject();
  exports$;
  ids: any[] = [];
  constructor(
    private exportService: ExportService,
    private dia: MatDialog,
    private rm: RequestService
  ) {
    this.exports$ = this.refresh$.pipe(startWith(1)).pipe(
      mergeMap(() =>
        this.exportService.getAll().pipe(
          map((exports: any) => {
            return _.orderBy(
              _.map(exports, (e) => {
                e.lines = _.orderBy(e.lines, (line: any) => line.id, 'desc');
                return e;
              }),
              (e) => e.id,
              'desc'
            );
          })
        )
      )
    );
  }

  ngOnInit(): void {}
  isSelected(id: any) {
    return this.ids.indexOf(id) !== -1;
  }
  select(id: any, event: any) {
    if (event.target.checked) {
      this.ids.push(id);
    } else {
      const index = this.ids.findIndex((i) => i == id);
      this.ids.splice(index);
    }
  }
  clickAdd(id: any) {
    const ref = this.dia.open(ExportsAddModalComponent, { data: { id: id } });
    ref.afterClosed().subscribe(() => {
      this.refresh$.next(0);
    });
  }

  clickReport(type: string, responseType: string = 'binary') {
    forkJoin(
      this.ids.map((id) => {
        if (responseType == 'binary') {
          return this.rm
            .postGetFile('/exports/report', {
              type: type,
              id: id,
              filter: 'order',
            })
            .pipe(
              tap((response: any) => {
                console.log(response);
                const blob = new Blob([response.body], {
                  type: response.headers.get('content-type'),
                });
                //  let fileName =  response.headers.get('content-disposition').split(';')[0];
                const fileName = 'report.pdf';
                const file = new File([blob], fileName, {
                  type: response.headers.get('content-type'),
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
              })
            );
        }
        if (responseType === 'json') {
          return this.rm
            .post('/exports/report', {
              type: type,
              id: id,
              filter: 'order',
            })
            .pipe(
              tap((response: any) => {
                if (response.status === 'ok') {
                  alert('Successful');
                } else {
                  alert('Fehler');
                }
              })
            );
        }
        return EMPTY;
      })
    )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      )
      .subscribe();
  }
}
