import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class HookService {
  authHook : (() => Observable<boolean>) | undefined;
  setLoggedIn(func: () => Observable<boolean>) {
    this.authHook = func;

  }
  isLoggedIn():Observable<boolean>
  {
    if(this.authHook)
    {
      return this.authHook();
    }
    return of(false);
  }

}
