<div class="amount">
    <span class="navigation" (click)="clickLeft()">
      <span class="material-icons">chevron_left</span>
    </span>
    <input
      [formControl]="amountControl"
      type="number"
      [min]="minValue"
      (focusout)="lostFocus(amountControl)"
    />
    <span class="navigation" (click)="clickRight()">
      <span class="material-icons">chevron_right</span>
    </span>
  </div>