import { Injectable } from '@angular/core';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';

import { mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private rm: RequestService) {}

  getAll() {
    return this.rm.getAllPages('exports');
  }

  addLines(exportId: any, lineIds: any[], type: string) {
    return this.rm.get('exports/' + exportId).pipe(
      mergeMap((e: any) => {
        e.lines = _.groupBy(e.lines, (line: any) => line.lineable_type);
        const lines = _.pick(e.lines, [type]);
        if (_.values(lines).length == 0) {
          lines[type] = [];
        }

        let result: any = {};
        lines[type] = _.map(lines[type], (line) => line.lineable_id);
        result[type] = lines[type].concat(lineIds);
        return this.rm.put('exports/' + exportId, { lines: result });
      })
    );
  }
}
