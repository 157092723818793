  <form [formGroup]="form">
    <mat-checkbox formControlName="all_customers">Alle Kunden</mat-checkbox>
    <mat-form-field>
      <mat-label>Kunden</mat-label>
      <mat-select formControlName="customers" multiple>
        <mat-option *ngFor="let customer of customers" [value]="customer.id">{{ customer.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Startdatum</mat-label>
      <input matInput formControlName="start_date" [matDatepicker]="start_date_picker">
      <mat-datepicker-toggle matSuffix [for]="start_date_picker"></mat-datepicker-toggle>
      <mat-datepicker #start_date_picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Enddatum</mat-label>
      <input matInput formControlName="end_date" [matDatepicker]="end_date_picker">
      <mat-datepicker-toggle matSuffix [for]="end_date_picker"></mat-datepicker-toggle>
      <mat-datepicker #end_date_picker></mat-datepicker>
    </mat-form-field>
   <button mat-button (click)="onSave()">Speichern</button>

  </form>
