import * as _ from 'lodash';
import { ILineItem, LineItem } from './lineitem';
import { IPrice, Price } from './price';
import { IProduct } from './product';

export interface ILineOptions {
  undeletable?: boolean;
  subscription_result?: boolean;
  readonly?: boolean;
  subscriptionChangeIndex?: number;
  subscriptionLine?: boolean;
  preferedTop?:boolean
}

export interface ICartLine {
  id: number;
  quantity: number;
  name: string;
  amountNet: number;
  amountGross: number;
  productId?: number;
  price?: IPrice;
  discount?: number;
  product?: IProduct;
  voucherCode?: string;
  undeletable?: boolean;
  options?: ILineOptions;
  subscriptionChangeIndex?: number;
}

export interface ISubscription {
  onCurrentOrder?: boolean;
  id: number;
  quantity: number;
  interval_days: number;
  startingFrom?: string | null;
  product_id: number;
  next_order: string|Date | undefined;
  price_id?: number;
  price?: Price;
  uuid?: string;
  customer_id?: number;
  next_order_changed?:boolean;
}

export interface ICart {
  lines: ICartLine[];
  shippingAddress?: IAddress;
  billingAddress?: IAddress;
  paymentMethod?: string;
  shipmentOption?: any;
  subscription_changes?: ISubscriptionAction[];
}
export interface IOrder {
  id?: string;
  number?: string;
  created_at?: string;
  customer_note: string;
  customer?: ICustomer;
  total_price: IPrice;
  billing_address?: IAddress;
  shipping_address?: IAddress;
  line_items?: ILineItem[];
  [key: string]: any;
}
export interface IAddress {
  id?: string;
  name: string;
  street: string;
  street_2: string;
  zip: string;
  city: string;
  country?: string;
  street_number: string;
}

export interface ICustomer {
  id?: number;
  firstname: string;
  lastname: string;
  email: string;
  date_of_birth: string;
  phone_number: string;
  gender:string;
  orderCount?: number;
  orderCashSum?: number;
}

export type ISubscriptionActionType = 'add' | 'remove' | 'update' | 'none';

export interface ISubscriptionAction {
  uuid?: string;
  action: ISubscriptionActionType;
  subscription: Partial<ISubscription>;
}

export class Order implements IOrder {
  public id: string;
  public totalPrice: Price;
  public line_items: ILineItem[];
  public lineItems: LineItem[];
  public created_at: string;
  public customer_note: string;
  public customer?: ICustomer;
  public billing_address?: IAddress;
  public shipping_address?: IAddress;
  public invoices?: any[];
  public total_price: IPrice;
  [key: string]: any;
  constructor(order: IOrder) {
    _.extend(this, order);

    this.total_price = order.total_price;
    this.id = order.id ?? '';
    this.customer_note = order.customer_note;
    this.totalPrice = new Price(order.total_price);
    this.created_at = order.created_at ?? '';
    this.line_items = order.line_items ?? [];
    this.billing_address = order.billing_address;
    this.shipping_address = order.shipping_address;
    this.invoices = order['invoices'];
    this.lineItems = this.line_items.map((lineItem) => {
      lineItem.price = new Price(lineItem.price);
      return lineItem as LineItem;
    });
  }
}
