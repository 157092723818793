import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEditorModule } from 'ngx-editor';

import { UiRoutingModule } from './ui-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './login-page/login-page.component';
import { MATERIAL_MODULES } from '@app/shared/material-imports';
import { ProductsComponent } from './products/products.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { CategoryComponent } from './category/category.component';
import { CategoryDetailComponent } from './category/category-detail/category-detail.component';
import { TagComponent } from './tags/tags.component';
import { TagsDetailComponent } from './tags/tags-detail/tags-detail.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProducerComponent } from './producer/producer.component';
import { ProducerDetailComponent } from './producer/producer-detail/producer-detail.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { VoucherComponent } from './voucher/voucher.component';
import { VoucherDetailComponent } from './voucher/voucher-detail/voucher-detail.component';
import { ImporterComponent } from './importer/importer.component';
import { PagesComponent } from './pages/pages.component';
import { PageDetailComponent } from './pages/page-detail/page-detail.component';
import { ShippingMethodsComponent } from './shipping-methods/shipping-methods.component';
import { ShippingMethodDetailComponent } from './shipping-methods/shipping-method-detail/shipping-method-detail.component';
import { SettingsComponent } from './settings/settings.component';
import { CustomersComponent } from './customers/customers.component';
import { ReportComponent } from './report/report.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceDetailComponent } from './invoices/invoices-detail/invoice-detail.component';
import { ExportsComponent } from './exports/exports.component';
import { ExportsAddModalComponent } from './exports/exports-add-modal/exports-add-modal.component';
import { DataTablesModule } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import { InvoiceEditComponent } from './invoices/invoice-edit/invoice-edit.component';
import { WarehouseComponent } from './stockmanaging/warehouse/warehouse.component';
import { StockmanagingComponent } from './stockmanaging/stockmanaging/stockmanaging.component';
import { StocksComponent } from './stockmanaging/stocks/stocks.component';
import { StockssettingComponent } from './stockmanaging/stockssetting/stockssetting.component';
import { WarehouseEditComponent } from './stockmanaging/warehouse-edit/warehouse-edit.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { StocksEditComponent } from './stockmanaging/stocks-edit/stocks-edit.component';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { StocksHistoryComponent } from './stockmanaging/stocks-history/stocks-history.component';
import { PackagingGroupComponent } from './packaging_group/packaging-group/packaging-group.component';
import { PackagingGroupOrderingComponent } from './packaging_group/packaging-group-ordering/packaging-group-ordering.component';
import { PackagingGroupDetailComponent } from './packaging_group/packaging-group-detail/packaging-group-detail.component';
import { PackagingGroupProductComponent } from './packaging_group/packaging-group-product/packaging-group-product.component';
import { ModalProductSearchComponent } from './products/modal-product-search/modal-product-search.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomerDetailComponent } from './customers/customer-detail/customer-detail.component';
import { BaseSubscriptionListComponent, BaseViewCustomerComponent } from '@cw/ui-base-views';
import {
    AdminOrderForCustomerComponent
} from '../../../../../libs/shared/admin-order-manager/src/lib/admin-order-for-customer/admin-order-for-customer.component';
import { SubscriptionCloseDraftsComponent } from './subscriptions/subscription-close-drafts/subscription-close-drafts.component';
import { ProductBatchModalComponent } from './products/product-batch-modal/product-batch-modal.component';
import { EmailComponent } from './settings/email/email.component';
import { MenuComponent } from './menu/menu.component';
import { VoucherOrdersComponent } from './voucher/voucher-orders/voucher-orders.component';
import { SubscriptionActionsComponent } from './subscriptions/subscription-actions/subscription-actions.component';
import { SubscriptionMenuComponent } from './subscriptions/subscription-menu/subscription-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    LoginPageComponent,
    ProductsComponent,
    ProductListComponent,
    ProductDetailComponent,
    CategoryComponent,
    CategoryDetailComponent,
    TagComponent,
    TagsDetailComponent,
    ProducerComponent,
    ProducerDetailComponent,
    OrdersComponent,
    OrderDetailComponent,
    VoucherComponent,
    VoucherDetailComponent,
    ImporterComponent,
    PagesComponent,
    PageDetailComponent,
    ShippingMethodsComponent,
    ShippingMethodDetailComponent,
    SettingsComponent,
    CustomersComponent,
    ReportComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    ExportsComponent,
    ExportsAddModalComponent,
    InvoiceEditComponent,
    WarehouseComponent,
    StockmanagingComponent,
    StocksComponent,
    StockssettingComponent,
    WarehouseEditComponent,
    StocksEditComponent,
    StocksHistoryComponent,
    PackagingGroupComponent,
    PackagingGroupOrderingComponent,
    PackagingGroupDetailComponent,
    PackagingGroupProductComponent,
    ModalProductSearchComponent,
    CustomerDetailComponent,
    SubscriptionCloseDraftsComponent,
    EmailComponent,
    VoucherOrdersComponent,
    SubscriptionActionsComponent,
  ],
  imports: [
    MatDialogModule,
    MATERIAL_MODULES,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    UiRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    DataTablesModule,
    TableModule,
    FormlyModule.forChild(),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    ProductBatchModalComponent,
    NgxEditorModule,
    MatSnackBarModule,
    AgGridModule,
    BaseViewCustomerComponent,
    BaseSubscriptionListComponent,
    AdminOrderForCustomerComponent,
    SubscriptionMenuComponent,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
})
export class UiModule {}
