import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { OrderService } from '@pisci/data-orders';
import { ExportService } from 'libs/shared/data-exports/src';
import { debounceTime, map, tap } from 'rxjs';

@Component({
  selector: 'app-exports-add-modal',
  templateUrl: './exports-add-modal.component.html',
  styleUrls: ['./exports-add-modal.component.css'],
})
export class ExportsAddModalComponent implements OnInit {
  loading = false;
  searchForm = new UntypedFormControl();
  result: any[] = [];
  selected: any;
  exportId: any;
  type = 'order';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<ExportsAddModalComponent>,
    private exportService: ExportService
  ) {
    if (data.id) {
      this.exportId = data.id;
    }
    this.searchForm.valueChanges
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(1000)
      )
      .subscribe((value) => {
        this.orderService
          .getOrders()
          .pipe(
            map((results: any[]) =>
              results.filter((result) => {
                return JSON.stringify(result).indexOf(value) !== -1;
              })
            ),
            tap(() => (this.loading = false))
          )
          .subscribe((orderResult) => {
            this.result = orderResult;
          });
      });
  }

  ngOnInit(): void {}
  clickSelect(r: any) {
    this.selected = r;
    this.result = [];
  }
  clickSave() {
    this.exportService
      .addLines(this.exportId, [this.selected.id], this.type)
      .subscribe(() => {
        this.dialogRef.close(this.selected);
      });
  }
}
