<h1>Bestellung abschließen</h1>
<div *ngIf="showErrors()" class="errors">{{ getErrors() | json }}</div>
<form [formGroup]="form">
  <div formGroupName="billing_address">
    <h4>Rechnungsadresse</h4>
    <div>
      <label>Name (Vorname Nachname oder Firmenbezeichnung)   <span *ngIf="isRequiredField('billing_address.name')"
      >*</span></label
      ><input type="text" formControlName="name" />
    </div>

    <div class="street">
      <div>
        <label
          >Straße
          <span *ngIf="isRequiredField('billing_address.street')"
            >*</span
          ></label
        ><input type="text" formControlName="street" />
      </div>
      <div>
        <label
          >Hausnummer
          <span *ngIf="isRequiredField('billing_address.street_number')"
            >*</span
          > </label
        ><input type="text" formControlName="street_number" />
      </div>
    </div>
    <div>
      <label
        >Zusatz
        <span *ngIf="isRequiredField('billing_address.street_2')"
          >*</span
        ></label
      ><input type="text" formControlName="street_2" />
    </div>
    <div>
      <label
        >Plz
        <span *ngIf="isRequiredField('billing_address.zip')">*</span></label
      ><input type="text" formControlName="zip" />
    </div>
    <div>
      <label
        >Ort
        <span *ngIf="isRequiredField('billing_address.city')">*</span></label
      ><input type="text" formControlName="city" />
    </div>
  </div>

  <div>
    <h4>Lieferadresse</h4>

    <div [hidden]="shippingEqualsInvoice" formGroupName="shipping_address">
      <div>
        <label
          >Name
          <span *ngIf="isRequiredField('shipping_address.name')">*</span></label
        ><input type="text" formControlName="name" />
      </div>
      <div>
        <label
          >Straße
          <span *ngIf="isRequiredField('shipping_address.street')"
            >*</span
          ></label
        ><input type="text" formControlName="street" />
      </div>
      <div>
        <label
          >Hausnummer&nbsp;<span
            *ngIf="isRequiredField('shipping_address.street_number')"
            >*</span
          ></label
        ><input type="text" formControlName="street_number" />
      </div>
      <div>
        <label
          >Zusatz
          <span *ngIf="isRequiredField('shipping_address.street_2')"
            >*</span
          ></label
        ><input type="text" formControlName="street_2" />
      </div>
      <div>
        <label
          >Plz
          <span *ngIf="isRequiredField('shipping_address.zip')">*</span></label
        ><input type="text" formControlName="zip" />
      </div>
      <div>
        <label
          >Ort
          <span *ngIf="isRequiredField('shipping_address.city')">*</span></label
        ><input type="text" formControlName="city" />
      </div>
    </div>
    <label class="isEquals"
      >Gleich wie Rechnungsadresse<input
        type="checkbox"
        formControlName="sameAddress"
        type="checkbox"
    /></label>
  </div>

  <div class="customer_note">
    <div formGroupName="data">
      <label
        >Telefonnummer
        <span *ngIf="isRequiredField('data.phone')">*</span></label
      >
      <input formControlName="phone" />
    </div>
    <div>
      <label>Zusätzliche Anmerkungen</label>
      <textarea formControlName="customer_note"></textarea>
    </div>
  </div>
  <div>
    <h4>Zahlungsmethoden</h4>
    <div
      *ngIf="paymentMethods$ | async as paymentMethods"
      class="paymentMethods"
    >
      <label *ngFor="let pm of paymentMethods"
        >{{ pm.label
        }}<input
          formControlName="payment_method"
          type="radio"
          [value]="pm.method_key"
      /></label>
    </div>
  </div>
  <div
    *ngIf="shipmentOptions$ | async as shipmentOptions"
    class="shipmentOptions"
  >
    <ng-container *ngIf="shipmentOptions.length > 1">
      <h4>Versand</h4>
      <div class="shipmentOptions">
        <label *ngFor="let so of shipmentOptions"
          >{{ so.label }}
          <ng-container *ngIf="so.price"
            >{{ so.price.getAmountGross() | number: '1.2' }} €</ng-container
          ><input
            formControlName="shipment_options"
            type="radio"
            [value]="so.id"
          />
        </label>
      </div>
    </ng-container>
  </div>
</form>
