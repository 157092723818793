import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategory, IProduct, ITax, ITaxgroup, Product } from '@app/data-interfaces';
import { PosService } from '@app/shared/data-pos';
import { ProductService } from '@app/shared/data-products';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { RequestService } from '@pisci/requestManager';
import { SettingsService } from '@cw/data-settings';
import { cloneDeep, isArray, isString, sortBy } from 'lodash';
import { forkJoin, map, mergeMap, Observable, of, startWith, tap } from 'rxjs';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { ofType } from '@ngneat/effects';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProducerService } from '@cw/data-producers';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  public Editor = ClassicEditor;
  product$: Observable<IProduct | null>;
  categories$: Observable<ICategory[]>;
  taxgroups$: Observable<ITaxgroup[]>;
  producers$;
  product: any;
  isVoucherProduct = false;
  isProductPriceEditable = false;
  taxRate = '';
  category_id: number | null | string = null;
  fileToUpload: File | null = null;
  producer_ids: any[] = [];

  tagCtrl = new UntypedFormControl();

  producerCtrl = new UntypedFormControl(null);

  filteredTags: Observable<any[]>;

  tags: any[] = [];

  allTags: any[] = [];

  @ViewChild('tagInput') tagInput?: ElementRef;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private catalogService: ProductCatalogService,
    private producerService: ProducerService,
    private settingsService: SettingsService,
    private rm: RequestService,
    private router: Router
  ) {
    this.categories$ = this.catalogService.selectCategories();
    this.producers$ = this.producerService.getProducers();
    this.taxgroups$ = this.settingsService
      .selectTaxgroups()
      .pipe(map((keyvalue) => Object.values(keyvalue)));
    this.product$ = this.route.params.pipe(
      mergeMap<any,Observable<IProduct|null>>((params: any):Observable<IProduct|null> => {
        if (params['id']) {
          if (params['id'] == 'new') {
            return of({
              name: 'test',
              sku: 'test',
              data: {},
              ean: '',
              status: 'published',
              description: '.',
              subscription_available: false,
              availability: 1,
              short_description: '.',
              price: {
                amountNet: 1.0,
                amountGross: 1.2,
                taxes: {
                  '20': { tax: 0.2, amountNet: 1.0}
                },
              },
            } as any);
          }
          return this.productService.getProductForAdmin(params["id"]).pipe(
            map((products) => {
             const found = products;


                found.images = sortBy(
                  found.images,
                  (image) => image.ordering
                );
                return found as IProduct;

            }),
            tap((product) => {
              if (product.producers && product.producers?.length > 0) {
                this.producerCtrl.patchValue(product.producers[0]);
              }
            })
          );
        }
        return of(null);
      })
    );

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        (fruit ? this.filterTag(fruit) : this.allTags.slice()).map(
          (tag) => tag.name
        )
      )
    );

    this.product$.subscribe((product) => {
      this.product = product;
      if (product?.tags) {
        this.tags = product?.tags.map((tag) => tag.name) ?? [];
      } else {
        this.tags = [];
      }

      if (product?.price && product.price.taxes && product?.price.taxes[0].taxgroup.tax) {
        this.taxRate = product?.price.taxes[0].taxgroups_id + '';
      }
      if (product?.categories) {
        this.category_id = product?.categories[0]?.id ?? null;
      }
      if (isArray(product?.data) && product?.data.length == 0) {
        product.data = {};
      }
      if (product?.data.shipping_description == undefined && product) {
        product.data.shipping_description = '';
      }
      console.log(product);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.product.images,
      event.previousIndex,
      event.currentIndex
    );
  }

  deleteImage(image: any) {
    this.rm
      .delete('product/' + this.product.id + '/images/' + image.id)
      .subscribe(() => {
        const i = this.product.images.findIndex(
          (img: any) => img.id === image.id
        );
        this.product.images.splice(i, 1);
      });
  }

  save() {
    this.settingsService
      .selectTaxgroups()
      .pipe(
        mergeMap((taxgroups) => {
          this.product.price.amount_gross =
            this.product.price.amount_gross.replace(',', '.');

          const grossPrice = Number.parseFloat(this.product.price.amount_gross);
          const found = Object.values(taxgroups).filter(
            (group: any) => group.id == Number.parseInt(this.taxRate)
          );
          let taxRate;
          if (isString(found[0].tax)) {
            taxRate = Number.parseInt(found[0].tax);
          } else {
            taxRate = found[0].tax;
          }
          const taxSyncRequest = (response: any) => {
            const tagIds: any[] = [];
            this.tags.forEach((tag) => {
              const tagFound = this.allTags.find(
                (tagFromAll) => tag === tagFromAll.name
              );
              if (tagFound) {
                tagIds.push(tagFound.id);
              }
            });
            return this.rm.patch('product/' + response.id + '/tags/sync', {
              resources: tagIds,
            });
          };
          let categorySyncRequest = (response: any) => of(response);
          if (this.category_id) {
            categorySyncRequest = (response: any) => {
              response.id;
              return this.rm.patch(
                'product/' + response.id + '/categories/sync',
                { resources: [this.category_id] }
              );
            };
          } else {
            categorySyncRequest = (response: any) => {
              response.id;
              return this.rm.patch(
                'product/' + response.id + '/categories/sync',
                { resources: [] }
              );
            };
          }

          const body = cloneDeep(this.product);
          console.log('product save', body);

          const amountNet = this.cleanNumber(
            (grossPrice * 100) / (100 + taxRate)
          );
          console.log(grossPrice);
          const tax = this.cleanNumber(grossPrice - amountNet);
          body.price = { amountGross: grossPrice, amountNet, taxes: [] };
          if (body.price.taxes.length > 0) {
            body.price.taxes = [];
          }
          body.price.taxes.push({
            taxgroups_id: found[0].id,
          });

          if (
            this.isProductPriceEditable ||
            this.product.data.price_changeable
          ) {
            body.data.price_changeable = true;
            body.data.taxgroups_id = found[0].id;
          }
          body.id = undefined;
          body.categories = undefined;
          body.tags = undefined;
          body.images = undefined;
          body.variations = undefined;
          body.producers = undefined;
          if (this.product.id) {
            return this.rm.put('product/' + this.product.id, body).pipe(
              mergeMap((product) => {
                //this.product = product;
                return forkJoin([
                  categorySyncRequest(product),
                  taxSyncRequest(product),
                  this.saveProducer(product),
                  this.saveImageOrder(product, this.product.images),
                  this.uploadFileToActivity(product),
                ]);
              })
            );
          } else {
            return this.rm.post('product', body).pipe(
              mergeMap((product) => {
                //this.product = product;
                return forkJoin([
                  categorySyncRequest(product),
                  taxSyncRequest(product),
                  this.saveProducer(product),
                  this.saveImageOrder(product, this.product.images),
                  this.uploadFileToActivity(product),
                ]);
              })
            );
          }
          return of(1);
        })
      )
      .subscribe(() => {
        this.router.navigateByUrl('/products');
      });
  }

  deleteProduct() {
    const result = confirm('Wirklich löschen?');
    if (result == false) {
      return;
    }
    this.rm.delete('product/' + this.product.id).subscribe(() => {
      this.router.navigateByUrl('/products');
    });
  }

  makeVoucher() {
    this.isVoucherProduct = true;
    this.save();
  }
  makePriceEditable() {
    this.isProductPriceEditable = true;
    this.save();
  }

  ngOnInit(): void {
    this.catalogService.loadCategories().subscribe();
    this.catalogService.selectTags().subscribe((tags) => {
      this.allTags = tags;
    });
    this.catalogService.loadTags().subscribe();
  }

  private cleanNumber(value: number): number {
    const f = Math.pow(10, 2);
    return Math.round(value * f) / f;
  }

  handleFileInput(files: Event | FileList) {
    if (files instanceof FileList) {
      this.fileToUpload = files.item(0);
    }
    if (files instanceof Event) {
      const event: any = files as any;
      this.fileToUpload = event.target.files[0];
    }
  }

  saveImageOrder(product: any, images: any[]) {
    if (images === undefined || images.length === 0) {
      return of([]);
    }
    let i = 0;
    const body: any = { resources: {} };
    images.forEach((image) => {
      image.ordering = i++;

      body.resources[image.id] = image;
    });

    return this.rm.patch('product/' + product.id + '/images/batch', body);
  }

  uploadFileToActivity(product: any) {
    if (this.fileToUpload != null) {
      return this.productService.uploadImage(product, this.fileToUpload);
    } else {
      return of([]);
    }
  }

  saveProducer(product: any) {
    if (this.producerCtrl.value) {
      return this.producerService.syncProducerToProduct(
        [this.producerCtrl.value],
        product.id
      );
    } else {
      return this.producerService.syncProducerToProduct([], product.id);
    }
  }

  /** Start Tag "subcomponent" */

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;

  separatorKeysCodes = [ENTER, COMMA];

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  removeTag(fruit: any): void {
    const index = this.tags.indexOf(fruit);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  filterTag(name: string) {
    return this.allTags.filter(
      (tag) => tag.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    if (this.tagInput) this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }
  /**
   * End Tag "subcomponent"
   */
}
