<table>
  <thead>
    <th>Produkt</th>
    <th>Lager ID</th>
    <th>Anzahl</th>
  </thead>
  <tr (click)="clickStock(stock)" *ngFor="let stock of stocks$ | async">
    <td>{{ stock.product?.name }}</td>
    <td>{{ getWareHouseName(stock.warehouse_id) }}</td>
    <td>{{ stock.amount }}</td>
  </tr>
</table>
<button (click)="clickAddStock()">
  <mat-icon>add</mat-icon> Neuer Eintrag
</button>
