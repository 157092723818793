import { Component, inject } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '@pisci/data-orders';
import { VoucherService } from '@app/shared/data-vouchers';

@Component({
  selector: 'app-voucher-orders',
  templateUrl: './voucher-orders.component.html',
  styleUrls: ['./voucher-orders.component.scss']
})
export class VoucherOrdersComponent {
  orders$: Observable<any[]> = of([]);
  route$ = inject(ActivatedRoute);
  constructor(private voucherService: VoucherService) {
    this.route$.params.subscribe((params) => {
      this.orders$ = this.voucherService.getVoucherDetails(params["id"]).pipe(
        map((voucher:any) => {
          return voucher.line_items.filter((item:any) => {
            return item.lineable_type == "order";
          }).map((item:any) => {
            return item.lineable;

          })
        })
      );
    });
  }
}
