import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Checkout2Component } from './checkout2/checkout2.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [Checkout2Component],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [Checkout2Component],
})
export class FeatureCheckout2Module {}
