import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '@cw/data-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { mergeMap, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-shipping-method-detail',
  templateUrl: './shipping-method-detail.component.html',
  styleUrls: ['./shipping-method-detail.component.css'],
})
export class ShippingMethodDetailComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  method: any = {
    name: '',
    label: '',
  };
  taxgroups$;
  price = { amountGross: 0, taxgroups_id: 0 };
  constructor(
    private route: ActivatedRoute,

    private router: Router,
    private rm: RequestService,
    private ss: SettingsService
  ) {
    this.taxgroups$ = this.ss.selectTaxgroups();
    this.route.params
      .pipe(
        untilDestroyed(this),
        mergeMap((params) => {
          if (params['id'] === 'new') {
            return of(null);
          }
          return this.rm.get('/shipmentOptions/' + params['id']);
        })
      )
      .subscribe((response: any) => {
        if (response == null) {
          return;
        }
        this.method = response;
        this.price.amountGross = response.price.amount_gross;
        this.price.taxgroups_id = response.price.taxes[0].taxgroups_id;
      });
  }

  ngOnInit(): void {}

  clickSave() {
    this.upsertShipmentOptionsWithPrice(this.method, this.price).subscribe(
      () => {
        this.router.navigateByUrl('shipmentOptions');
      }
    );
  }

  upsertShipmentOptionsWithPrice(body: any, price: any) {
    let priceRequest;
    let priceBody = {
      amountGross: price.amountGross,
      taxes: [{ taxgroups_id: price.taxgroups_id }],
    };
    if (body.price_id) {
      priceRequest = this.rm.put('price/' + this.method.price_id, priceBody);
    } else {
      priceRequest = this.rm.post('price', priceBody);
    }
    return priceRequest.pipe(
      mergeMap((price: any) => {
        let requestBody: any = _.pick(body, [
          'name',
          'label',
          'shipment_rules',
          'price_rules',
        ]);
        requestBody.price_id = price.id;
        if (body.id) {
          return this.rm.put('shipmentOptions/' + body.id, requestBody);
        } else {
          return this.rm.post('shipmentOptions/', requestBody);
        }
      })
    );
  }
}
