<div class="cw-modal" [formGroup]="intervalQuantityForm">
    <div class="cw-modal-header"><h4 class="title">Menge ändern</h4>
      <button matButton (click)="clickClose()">
        <mat-icon>close</mat-icon>
      </button>
      </div>
    <div>
        Die hier geänderte Menge bezieht sich auf den aktuellen Einkauf.
    </div>
    <span>Produkt:</span><span class="product">{{ subscriptionLine.name }}</span
    ><br />


    <label for="subAmount">Menge</label>
    <app-number-button-input
      id="subAmount"
      formControlName="quantity"
    ></app-number-button-input>
  <div class="actionbar">
    <button class="pause" (click)="clickPause()">Produkt diese Woche pausieren</button>
    <!--<mat-slide-toggle>Abomenge soll angepasst werden.</mat-slide-toggle>-->
    <button  (click)="clickSave()">Speichern</button>
  </div>
  </div>
