<mat-spinner *ngIf="loading"></mat-spinner>
<div>
  <h4>Suche Nach Bestellungen</h4>
  <input type="search" [formControl]="searchForm" />
  <div *ngFor="let r of result" (click)="clickSelect(r)">
    {{ r.id }} {{ r.number }} {{ r.created_at }}
  </div>
  <div *ngIf="selected">Ausgewählt: {{ selected.id }}</div>
</div>
<button mat-raised-button (click)="clickSave()">Hinzufügen</button>
