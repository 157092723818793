import { Component, ComponentRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, mergeMap, Observable, shareReplay, tap } from 'rxjs';
import { RequestService } from '@pisci/requestManager';
import { ICustomer, IProduct, ISubscription, Subscription } from '@app/data-interfaces';
import { filterNil } from '@ngneat/elf';
import { ProductService } from '@app/shared/data-products';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionModalComponent } from '@cw-addtocart';
import { take } from 'rxjs/operators';
import { ModalProductSearchComponent } from '../../products/modal-product-search/modal-product-search.component';
import {
  AdminOrderForCustomerComponent
} from '../../../../../../../libs/shared/admin-order-manager/src/lib/admin-order-for-customer/admin-order-for-customer.component';
import { CustomerChangePasswordComponent } from '../customer-change-password/customer-change-password.component';


@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent {
  customer$: Observable<ICustomer>;
  subscriptions$: Observable<Subscription[]>;
  products$: Observable<IProduct[]>;

  @ViewChild(AdminOrderForCustomerComponent)
  adminCart?: AdminOrderForCustomerComponent;

  constructor(route: ActivatedRoute,
              private rm: RequestService,
              private productService: ProductService,
              private modalService: MatDialog) {

    this.customer$ = route.params.pipe(map(p => p['id'])).pipe(mergeMap(id => {
      return this.rm.get<ICustomer>('/customer/' + id);
    }), filterNil(), shareReplay(1));

    this.subscriptions$ = this.customer$.pipe(mergeMap(c => {
      return this.rm.get<Subscription[]>('/customer/' + c.id + '/subscriptions');
    }), filterNil(), shareReplay(1));
    this.products$ = this.subscriptions$.pipe(
      map(s => s.map(s => s.product_id)),
      tap(ids => {
        this.productService.loadByIds(ids).subscribe();
      }),
      mergeMap(ids => this.productService.selectByIds(ids)),
      filterNil(),
      shareReplay(1));

  }

  editSubscription(sub: ISubscription) {
    this.products$.pipe(map(products => {
      return products.find(p => p.id == sub.product_id);
    }), take(1)).subscribe(product => {
      const modalRef = this.modalService.open(
        SubscriptionModalComponent,
        {
          data: {
            product: product,
            quantity: sub.quantity,
            subscription: sub,
            useCustomResult: true,
            showDatePicker: true
          }
        });
      modalRef.afterClosed().subscribe(result => {
        alert(result.subscription.id);
      });

    });

  }

  openProductSearchModal() {
    let ref = this.modalService.open(ModalProductSearchComponent, {
      data: { admin: true }
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.adminCart?.addProductToCart(result);
        console.log(result);
      }
    });

  }

  orderForCustomer() {
    this.adminCart?.orderCart();
    return false;
  }

  clickChangePassword(customer: any) {
    this.modalService.open(CustomerChangePasswordComponent, { data: customer });

  }

  logMeInAs(customer: ICustomer) {
    const user = (customer as any).users[0];
    this.rm.post("/auth/generateLoginAs", { user_id: user.id }).subscribe((res) => {
      console.log(res)
      if(res.redirect_url && res.auth_token) {
        window.open(res.redirect_url+'?auth_token='+res.auth_token);
      }
    });
  }
}
