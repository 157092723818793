import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Observable, Subject } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '@app/shared/data-products';
import { OrderService } from '@pisci/data-orders';
import { IOrder } from '@app/data-interfaces';

@Component({
  selector: 'cw-base-order-list',
  standalone: true,
  imports: [CommonModule, AgGridModule],
  templateUrl: './base-order-list.component.html',
  styleUrls: ['./base-order-list.component.scss']
})
export class BaseOrderListComponent {

  @Input({required:true})
  orders: IOrder[] = [];
  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    { field: 'id' },

    {
      field: 'order_number'
    },
    {
      field: 'customer_id'
      //, valueGetter: (params) => this.getCustomerName(params)
    },
    { field: 'total_amount' },

    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/orders/' + data.id);
            }
          },
          {
            label: 'Kunde',
            action: (data: any) => {
              this.router.navigateByUrl('/customers/' + data.customer_id);
            }
          }
        ]
      }
    }
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  // Data that gets displayed in the grid
  public rowData$: Subject<any[]> = new Subject()
  ;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private orderService: OrderService, private router: Router, private productService: ProductService, private route: ActivatedRoute) {
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$.next(this.orders);
    this.agGrid.api.sizeColumnsToFit()
  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }
}
