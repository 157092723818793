import * as i0 from '@angular/core';
import { Injectable, InjectionToken, makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, inject, NgModule, Inject, Directive } from '@angular/core';
import * as i1 from '@ngneat/effects';
import { Actions as Actions$1, isEffect, actions, initEffects, createEffectFn } from '@ngneat/effects';
import { Subject } from 'rxjs';
class Actions extends Actions$1 {}
Actions.ɵfac = /* @__PURE__ */(() => {
  let ɵActions_BaseFactory;
  return function Actions_Factory(t) {
    return (ɵActions_BaseFactory || (ɵActions_BaseFactory = i0.ɵɵgetInheritedFactory(Actions)))(t || Actions);
  };
})();
Actions.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Actions,
  factory: Actions.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Actions, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const EFFECTS_PROVIDERS = new InjectionToken('@ngneat/effects Effects providers');
const EFFECTS_MANAGER = new InjectionToken('@ngneat/effects Effects Manager');
function getEffectPropsMap(instance) {
  return Object.entries(instance).reduce((map, [key, value]) => {
    if (isEffect(value)) {
      map.set(key, value);
    }
    return map;
  }, new Map());
}
const providedEffectsSourceInstances = new Map();
function getProvidedEffect(sourceInstance, effectToken) {
  return providedEffectsSourceInstances.get(sourceInstance)?.get(effectToken)?.effect;
}
function generateProvidedEffectToken(provider, effectKey) {
  return `${provider.name} - ${effectKey}`;
}
function isEffectProvided(sourceInstance, effectToken) {
  return !!providedEffectsSourceInstances.get(sourceInstance)?.has(effectToken);
}
function provideEffect(sourceInstance, effectToken, effect) {
  const providedEffectsMapBySourceInstance = providedEffectsSourceInstances.get(sourceInstance);
  if (providedEffectsMapBySourceInstance) {
    providedEffectsMapBySourceInstance.set(effectToken, {
      sources: 1,
      effect
    });
  } else {
    providedEffectsSourceInstances.set(sourceInstance, new Map([[effectToken, {
      sources: 1,
      effect
    }]]));
  }
}
function increaseProvidedEffectSources(sourceInstance, effectToken) {
  const providedEffectsMapBySourceInstance = providedEffectsSourceInstances.get(sourceInstance);
  const providedEffect = providedEffectsMapBySourceInstance?.get(effectToken);
  if (providedEffectsMapBySourceInstance && providedEffect) {
    const sources = providedEffect.sources ?? 0;
    providedEffectsMapBySourceInstance.set(effectToken, {
      ...providedEffect,
      sources: sources + 1
    });
  }
}
function decreaseProvidedEffectSources(sourceInstance, effectToken) {
  const providedEffectsMapBySourceInstance = providedEffectsSourceInstances.get(sourceInstance);
  const providedEffect = providedEffectsMapBySourceInstance?.get(effectToken);
  if (providedEffectsMapBySourceInstance && providedEffect) {
    const sources = providedEffect.sources ?? 0;
    if (sources === 0 || sources - 1 === 0) {
      providedEffectsMapBySourceInstance.delete(effectToken);
      if (!providedEffectsMapBySourceInstance.size) {
        providedEffectsSourceInstances.delete(sourceInstance);
      }
    } else {
      providedEffectsMapBySourceInstance.set(effectToken, {
        ...providedEffect,
        sources: sources - 1
      });
    }
  }
}

/**
 * Can be called at the root and feature levels.
 */
function provideEffects(...providers) {
  return makeEnvironmentProviders([...providers, {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      const manager = inject(EFFECTS_MANAGER, {
        optional: true
      });
      if (!manager) {
        throw new TypeError("Effects manager wasn't found. Have you forgotten to provide it? Please call `provideEffectsManager` in the root providers.");
      }
      registerEffectFromProviders(providers, manager);
    }
  }]);
}
function registerEffectFromProviders(providers, manager) {
  new Set(providers).forEach(provider => {
    const instance = inject(provider);
    const effects = [];
    getEffectPropsMap(instance).forEach((effect, key) => {
      const sourceInstance = Object.getPrototypeOf(instance);
      const token = generateProvidedEffectToken(provider, key);
      if (isEffectProvided(sourceInstance, token)) {
        increaseProvidedEffectSources(sourceInstance, token);
      } else {
        provideEffect(sourceInstance, token, effect);
        effects.push(effect);
      }
    });
    manager.registerEffects(effects);
  });
}

/**
 * @deprecated Please consider using `provideEffectManager` and `provideEffects` functions instead. This module will be
 *   deleted in the future.
 */
class EffectsNgModule {
  constructor(manager, providers) {
    registerEffectFromProviders(flatten(providers), manager);
  }
  static forRoot(providers, config) {
    return {
      ngModule: EffectsNgModule,
      providers: [{
        provide: Actions,
        useValue: config?.customActionsStream || actions
      }, {
        provide: EFFECTS_MANAGER,
        useFactory: () => initEffects(config)
      }, ...providers, {
        provide: EFFECTS_PROVIDERS,
        multi: true,
        useValue: providers
      }]
    };
  }
  static forFeature(providers) {
    return EffectsNgModule.forRoot(providers);
  }
}
EffectsNgModule.ɵfac = function EffectsNgModule_Factory(t) {
  return new (t || EffectsNgModule)(i0.ɵɵinject(EFFECTS_MANAGER), i0.ɵɵinject(EFFECTS_PROVIDERS));
};
EffectsNgModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: EffectsNgModule
});
EffectsNgModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EffectsNgModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i1.EffectsManager,
      decorators: [{
        type: Inject,
        args: [EFFECTS_MANAGER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [EFFECTS_PROVIDERS]
      }]
    }];
  }, null);
})();
function flatten(arr) {
  return arr.reduce((acc, cur) => acc.concat(Array.isArray(cur) ? flatten(cur) : cur), []);
}
class EffectFn {
  constructor() {
    this.destroy = new Subject();
    this.destroy$ = this.destroy.asObservable();
  }
  createEffectFn(factoryFn) {
    return createEffectFn(factoryFn)(this.destroy$);
  }
  ngOnDestroy() {
    this.destroy.next(true);
  }
}
EffectFn.ɵfac = function EffectFn_Factory(t) {
  return new (t || EffectFn)();
};
EffectFn.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: EffectFn,
  factory: EffectFn.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EffectFn, [{
    type: Injectable
  }], null, null);
})();

/**
 * Must be called at the root level.
 */
function provideEffectsManager(config) {
  const {
    dispatchByDefault = false,
    customActionsStream = actions
  } = config || {};
  const manager = initEffects({
    dispatchByDefault,
    customActionsStream
  });
  return makeEnvironmentProviders([{
    provide: Actions,
    useValue: customActionsStream
  }, {
    provide: EFFECTS_MANAGER,
    useValue: manager
  }]);
}
const EFFECTS_DIRECTIVE_PROVIDERS = new InjectionToken('@ngneat/effects Effects directive providers');
function provideDirectiveEffects(...providers) {
  return [...providers, {
    provide: EFFECTS_DIRECTIVE_PROVIDERS,
    useValue: providers
  }];
}
class EffectsDirective {
  constructor() {
    this.providers = inject(EFFECTS_DIRECTIVE_PROVIDERS, {
      self: true,
      optional: true
    });
    this.manager = inject(EFFECTS_MANAGER, {
      optional: true
    });
    this.sourceInstancesWithProvidersEffectsTokens = new Map();
    if (!this.manager) {
      throw new TypeError("Effects manager wasn't found. Have you forgotten to provide it? Please call `provideEffectsManager` in the root providers.");
    }
    if (!this.providers) {
      throw new TypeError('No one effect was provided on a directive level. Please use `EffectsDirective` along with `provideDirectiveEffects`');
    }
    const effects = [];
    new Set(this.providers).forEach(provider => {
      const instance = inject(provider, {
        self: true
      });
      getEffectPropsMap(instance).forEach((effect, key) => {
        const sourceInstance = Object.getPrototypeOf(instance);
        const token = generateProvidedEffectToken(provider, key);
        this.sourceInstancesWithProvidersEffectsTokens.set(Object.getPrototypeOf(instance), token);
        if (isEffectProvided(sourceInstance, token)) {
          increaseProvidedEffectSources(sourceInstance, token);
        } else {
          provideEffect(sourceInstance, token, effect);
          effects.push(effect);
        }
      });
    });
    if (effects.length) {
      this.manager?.registerEffects(effects);
    }
  }
  ngOnDestroy() {
    this.unregisterEffect();
  }
  unregisterEffect() {
    const effects = [...this.sourceInstancesWithProvidersEffectsTokens.entries()].reduce((effects, [sourceInstance, token]) => {
      const effect = getProvidedEffect(sourceInstance, token);
      decreaseProvidedEffectSources(sourceInstance, token);
      if (effect && !isEffectProvided(sourceInstance, token)) {
        effects.push(effect);
      }
      return effects;
    }, []);
    this.manager?.removeEffects(effects);
  }
}
EffectsDirective.ɵfac = function EffectsDirective_Factory(t) {
  return new (t || EffectsDirective)();
};
EffectsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: EffectsDirective,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EffectsDirective, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Actions, EffectFn, EffectsDirective, EffectsNgModule, provideDirectiveEffects, provideEffects, provideEffectsManager };
