import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseOrderListComponent } from '@cw/ui-base-views';
import { mergeMap, Observable, of, shareReplay, tap, withLatestFrom } from 'rxjs';
import { IOrder } from '@app/data-interfaces';
import { SubscriptionService } from '@app/shared/data-cart2';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '@pisci/data-orders';

@Component({
  selector: 'app-subscription-detail',
  standalone: true,
  imports: [CommonModule, BaseOrderListComponent],
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss']
})
export class SubscriptionDetailComponent {
  subscriptionService = inject(SubscriptionService);
  orderService = inject(OrderService);



  subscription$ = inject(ActivatedRoute).params.pipe(tap( () => {
    this.subscriptionService.loadMySubscriptions().subscribe();
  }),mergeMap((params:any) => {
    if(params['id']) {
      return this.subscriptionService.selectSubscription(params["id"]);
    }
    return of();
  }), shareReplay(1));
  orders$ = this.subscription$.pipe(mergeMap(sub => this.orderService.scopeSearch("ofSubscription", [sub.id.toString()])));
}
