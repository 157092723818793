import { IPrice, Price } from './price';

export interface ILineItem {
  id?: string;
  amount_net: string;
  amount_gross: string;
  tax_total: string;
  currency: string;
  price: IPrice;
  name: string;
  quantity: number;
}

export interface LineItem {
  id?: string;
  amount_net: string;
  amount_gross: string;
  tax_total: string;
  currency: string;
  price: Price;
  name: string;
  quantity: number;
}
