<form [formGroup]="form">
  <div formGroupName="billing_address">
    <h4>Rechnungsadresse</h4>
    <div>
      <label>Name (Vorname Nachname oder Firmenbezeichnung)</label
      ><input type="text" formControlName="name" />
    </div>

    <div class="street">
      <div>
        <label
        >Straße
          <span *ngIf="isRequiredField('billing_address.street')"
          >*</span
          ></label
        ><input type="text" formControlName="street" />
      </div>
      <div>
        <label
        >Hausnummer
          <span *ngIf="isRequiredField('billing_address.street_number')"
          >*</span
          > </label
        ><input type="text" formControlName="street_number" />
      </div>
    </div>
    <div>
      <label
      >Zusatz
        <span *ngIf="isRequiredField('billing_address.street_2')"
        >*</span
        ></label
      ><input type="text" formControlName="street_2" />
    </div>
    <div>
      <label
      >Plz
        <span *ngIf="isRequiredField('billing_address.zip')">*</span></label
      ><input type="text" formControlName="zip" />
    </div>
    <div>
      <label
      >Ort
        <span *ngIf="isRequiredField('billing_address.city')">*</span></label
      ><input type="text" formControlName="city" />
    </div>
  </div>

  <div>
    <h4>Lieferadresse</h4>

    <div [hidden]="shippingEqualsInvoice" formGroupName="shipping_address">
      <div>
        <label
        >Name
          <span *ngIf="isRequiredField('shipping_address.name')">*</span></label
        ><input type="text" formControlName="name" />
      </div>
      <div>
        <label
        >Straße
          <span *ngIf="isRequiredField('shipping_address.street')"
          >*</span
          ></label
        ><input type="text" formControlName="street" />
      </div>
      <div>
        <label
        >Hausnummer&nbsp;<span
          *ngIf="isRequiredField('shipping_address.street_number')"
        >*</span
        ></label
        ><input type="text" formControlName="street_number" />
      </div>
      <div>
        <label
        >Zusatz
          <span *ngIf="isRequiredField('shipping_address.street_2')"
          >*</span
          ></label
        ><input type="text" formControlName="street_2" />
      </div>
      <div>
        <label
        >Plz
          <span *ngIf="isRequiredField('shipping_address.zip')">*</span></label
        ><input type="text" formControlName="zip" />
      </div>
      <div>
        <label
        >Ort
          <span *ngIf="isRequiredField('shipping_address.city')">*</span></label
        ><input type="text" formControlName="city" />
      </div>
    </div>
    <label class="isEquals"
    >Gleich wie Rechnungsadresse<input
      type="checkbox"
      formControlName="sameAddress"
      type="checkbox"
    /></label>
  </div>
</form>
