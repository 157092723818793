import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategory, IVoucher } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { VoucherService } from '@app/shared/data-vouchers';
import { Observable, of } from 'rxjs';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.scss'],
})
export class VoucherDetailComponent implements OnInit {
  voucher$?: Observable<Partial<IVoucher>>;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private voucherService: VoucherService
  ) {
    this.route.params.subscribe((params: any) => {
      if (params['id'] !== 'new') {
        this.voucher$ = this.voucherService.getVoucher(
          params['id']
        ) as Observable<ICategory>;
      } else {
        this.voucher$ = of({
          code: '',
          value: 0,
          speciel_rules: {},
          maximum_uses: 1,
          use_count: 0,
          type: 'value',
        });
      }
    });
  }

  ngOnInit(): void {}
  saveVoucher(voucher: Partial<IVoucher>): void {
    this.loading = true;
    this.voucherService.upsertVoucher(voucher).subscribe(() => {
      this.loading = false;
      this.router.navigateByUrl('vouchers');
    });
  }

  deleteVoucher(voucher: Partial<IVoucher>) {
    let result = confirm('Wollen Sie den Gutschein wirklich löschen?');
    if (result && voucher.id) {
      this.voucherService.deleteVoucher(voucher).subscribe(() => {
        this.router.navigateByUrl('vouchers');
      });
    }
  }
}
