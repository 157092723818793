<div class="modal">
  <h1>Abo Generieren Optionen</h1>
  <div *ngIf="$dates |async as dates">
    <mat-form-field>
      <mat-label>Choose a date</mat-label>
      <select [(ngModel)]="date">
        <option *ngFor="let dateV of dates" [value]="dateV">{{ dateV }}</option>
      </select>
    </mat-form-field>
  </div>
  <div>
    <button class="btn btn-primary" (click)="generateSubscription()">Generieren</button>
  </div>
</div>
