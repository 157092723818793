<mat-list>
  <mat-list-item *ngFor="let warehouse of warehouses$ | async"
    >#{{ warehouse.id }} {{ warehouse.name
    }}<button (click)="clickEdit(warehouse)" mat-button>
      <mat-icon>edit</mat-icon>
    </button></mat-list-item
  >
</mat-list>
<button mat-raised-button (click)="clickAddWarehouse()">
  <mat-icon>add_circle</mat-icon>Lager hinzufügen
</button>
