

  <div class="actionbar">

    <button mat-button (click)="clickInvoices()">Rechnungen Anzeigen</button>
  </div>
  <input placeholder="Suche" [formControl]="searchControl" />
  <ag-grid-angular
    style="width: 100%; height: 100%"
    class="ag-theme-alpine"
    [getRowId]="getRowId"
    [pagination]='true'
    [paginationAutoPageSize]=true
    [rowSelection]="'multiple'"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    (cellClicked)="onCellClicked($event)"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>

