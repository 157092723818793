import { Injectable } from '@angular/core';
import { IStock, IWarehouse } from '@app/data-interfaces';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntities,
  selectEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { map, tap } from 'rxjs';

const stocksStore = createStore({ name: 'stocks' }, withEntities<IStock>());

@Injectable({
  providedIn: 'root',
})
export class StocksService {
  constructor(private rs: RequestService) {}
  load() {
    return this.rs.getAllPages('stocks').pipe(
      tap((response) => {
        stocksStore.update(setEntities(response));
      })
    );
  }

  loadById(id: string) {
    return this.rs.get('stocks/' + id).pipe(
      tap((response: any) => {
        stocksStore.update(upsertEntities([response]));
      })
    );
  }

  select() {
    return stocksStore.pipe(selectAllEntities());
  }
  selectById(id: any) {
    return stocksStore.pipe(selectEntity(id));
  }

  upsert(data: Partial<IStock>) {
    return this.rs
      .post('stocks', data)
      .pipe(tap((result: any) => this.loadById(result.id).subscribe()));
  }
  getCurrent(productId: any, warehouseId: any) {
    return this.rs
      .post('stocks/search', {
        scopes: [{ name: 'current', parameters: [productId, warehouseId] }],
      })
      .pipe(map((r: any) => r.data[0] ?? null));
  }

  getStocksHistory(productId: any, warehouseId: any) {
    return this.rs.post('stocks_history/search', {
      filters: [
        {
          field: 'product_id',
          operator: '=',
          value: productId,
        },
        {
          field: 'warehouse_id',
          operator: '=',
          value: warehouseId,
        },
      ],
    });
  }
}
