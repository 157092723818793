import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-order-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-tooltip.component.html',
  styleUrls: ['./order-tooltip.component.scss']
})
export class OrderTooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data!: any;
  public color!: string;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;

    this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    this.color = this.params.color || '#999';
  }

}
