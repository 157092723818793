<h1>Abo Urlaube</h1>
<div class="toolbar">
  <button mat-button (click)="onEdit()">Bearbeiten</button>
  <button mat-button (click)="onDelete()">Löschen</button>
  <button mat-button (click)="onAdd()">Neu</button>
</div>
  <ag-grid-angular
  style="width: 100%; height: 500px;"
  class="ag-theme-alpine"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [rowSelection]="rowSelection"
  [rowMultiSelectWithClick]="true"
  [animateRows]="true"
  [pagination]="true"
  [paginationPageSize]="10"
  [paginationAutoPageSize]="true"
  (gridReady)="onGridReady($event)"
></ag-grid-angular>
