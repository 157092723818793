import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PackagingGroupService } from '@app/shared/data-packaging-groups';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-packaging-group-detail',
  templateUrl: './packaging-group-detail.component.html',
  styleUrls: ['./packaging-group-detail.component.scss'],
})
export class PackagingGroupDetailComponent implements OnInit {
  form = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(''),
    order: new UntypedFormControl(),
  });
  @Input()
  public item: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { item: any } | null,
    private dataService: PackagingGroupService,
    private modalInstance: MatDialogRef<PackagingGroupDetailComponent>
  ) {
    if (data) this.item = data.item;
  }

  ngOnInit(): void {
    this.form.patchValue(this.item);
  }

  clickSave() {
    this.dataService.save(this.form.value).subscribe(() => {
      this.modalInstance.close();
    });
  }
}
