import { Component, OnInit } from '@angular/core';
import { ITag } from '@app/data-interfaces';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagComponent implements OnInit {
  editingCategory?: ITag;
  addingCategory = false;
  tags$: Observable<ITag[]>;
  constructor(private categoryService: ProductCatalogService) {
    this.tags$ = this.categoryService.selectTags();
    this.categoryService.loadTags().subscribe();
  }

  editTag(category: ITag) {
    this.editingCategory = category;
  }
  isEditingTag(category: ITag) {
    return this.editingCategory === category;
  }

  addTag() {
    this.addingCategory = true;
  }
  saveTag(category: ITag) {}

  removeTag(category: ITag) {}

  ngOnInit(): void {}
}
