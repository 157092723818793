import { Injectable } from '@angular/core';
import { RequestService } from './request.service';

import jwt_decode from 'jwt-decode';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(
    private request: RequestService,
    private authService: AuthService
  ) {}
  getUser() {
    return this.request.getMe();
  }

  login(email: string, password: string) {
    return this.request.login({ email, password });
  }

  isLoggedIn() {
    if (this.isTokenExpired(this.authService.getValue().jwt)) {
      this.authService.updateProps({ jwt: '' });
      return false;
    }
    return this.authService.getValue().jwt != '';
  }

  isTokenExpired(token?: string): boolean {
    //if(!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date | undefined {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) return;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }
}
