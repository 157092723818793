import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '@pisci/requestManager';
import { AuthQuery } from 'libs/shared/request-manager/src/lib/auth.query';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  email = '';
  password = '';
  loggedIn = false;
  tenant_id = '';
  constructor(
    private rm: RequestService,
    private authQuery: AuthQuery,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.authQuery.getValue().jwt) {
      this.router.navigateByUrl('products');
    }
  }

  login() {
    this.rm.setTenantId(this.tenant_id);
    this.rm
      .login({ email: this.email, password: this.password })
      .subscribe((respo) => {
        console.log(respo);
        this.loggedIn = true;
        this.gotohome();
      });
  }
  isLoggedIn() {
    return this.authQuery.getValue().jwt !== null;
  }
  gotohome() {
    this.router.navigateByUrl('products');
  }
}
