import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WarehouseService } from '@app/shared/data-stockmanaging';
import { SettingsService, SettingsvalueService } from '@cw/data-settings';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  combineLatest,
  delay,
  filter,
  forkJoin,
  map,
  merge,
  of,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-stockssetting',
  templateUrl: './stockssetting.component.html',
  styleUrls: ['./stockssetting.component.scss'],
})
export class StockssettingComponent implements OnInit {
  private settingsService: SettingsvalueService = inject(SettingsvalueService);
  private warehouseService: WarehouseService = inject(WarehouseService);
  settings$;
  settings: any = {
    negative_allowed_default: null,
    change_quantity_to_stock_at_order: null,
    default_warehouse_id: null,
    enabled_global: null,
  };
  form = new UntypedFormGroup({});

  fields: FormlyFieldConfig[] = [
    {
      key: 'negative_allowed_default',

      type: 'toggle',
      props: {
        label: 'Negative Lagerstände erlaubt (Default Wert)',
      },
    },
    {
      key: 'change_quantity_to_stock_at_order',

      type: 'toggle',
      props: {
        label: 'Korrigiere Bestellung auf maximalen Lagerstand',
      },
    },
    {
      key: 'enabled_global',

      type: 'toggle',
      props: {
        label: 'Für alle Produkte aktivieren',
      },
    },
    {
      key: 'default_warehouse_id',

      type: 'select',
      props: {
        options: this.warehouseService.selectWarehouses(),
        valueProp: 'id',
        labelProp: 'name',
        label: 'Standard Lager',
      },
    },

    {
      key: 'time_of_change',
      type: 'select',
      props: {
        options: [
          { value: 'invoice', label: 'Rechnung' },
          { value: 'order', label: 'Bestellung' },
        ],

        label: 'Zeitpunkt der Änderung',
      },
    },
  ];
  constructor(

  ) {
    this.settings$ = this.settingsService.select().pipe(
      map((settings) =>
        settings.filter((setting: any) =>
          setting.key.startsWith('stockmanagement')
        )
      ),
      tap((settings) => {
        this.form.patchValue(
          settings.reduce((carry: any, setting) => {
            let key = setting.key.substring(setting.key.indexOf('.') + 1);
            carry[key] = setting.data;
            return carry;
          }, {})
        );
      })
    );
  }

  ngOnInit(): void {
    this.settingsService.load().subscribe();
    this.warehouseService.load().subscribe();
    this.settings$.subscribe((data) => console.log);
  }

  onSubmit(v: any) {
    console.log(v);
    for (let key in v) {
      if (v[key] === null || v[key] === undefined) {
        continue;
      }
      this.settingsService
        .saveSetting('stockmanagement.' + key, v[key])
        .subscribe();
    }
  }
}
