import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from '@pisci/requestManager';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-customer-change-password',
  standalone: true,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: './customer-change-password.component.html',
  styleUrls: ['./customer-change-password.component.scss']
})
export class CustomerChangePasswordComponent {
  customer:any;

  passwordForm = new FormGroup({
    password: new FormControl('',Validators.required),
    password_confirm: new FormControl('', [Validators.required]),
  })
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private rm: RequestService, private ref: MatDialogRef<CustomerChangePasswordComponent>) {
    this.customer = data;
  }
  save() {

    if(this.passwordForm.value.password !== this.passwordForm.value.password_confirm){
      alert('Passwörter stimmen nicht überein!');
      return;
    }
    const password =  this.passwordForm.value.password;
    if (password == '') {
      return;
    }

    if (this.customer.users[0].id) {
      this.rm
        .put('/user/' + this.customer.users[0].id, { password: password })
        .subscribe(() => {
          alert('Passwort geändert');
        });
    }
    this.ref.close();
    return false;
  }
}
