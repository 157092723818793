import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { omit } from 'lodash';

@Component({
  selector: 'app-subscription-vacation-edit-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
  templateUrl: './subscription-vacation-edit-modal.component.html',
  styleUrls: ['./subscription-vacation-edit-modal.component.scss'],
})
export class SubscriptionVacationEditModalComponent {
  private data: any = inject(MAT_DIALOG_DATA);
  private modalRef = inject(
    MatDialogRef<SubscriptionVacationEditModalComponent>,
  );
  private fb = inject(UntypedFormBuilder);
  protected form = this.fb.group({
    customer_id: this.fb.control(null, []),
    locked: this.fb.control(true ,[Validators.required]),
    start_date: this.fb.control("", [Validators.required]),
    end_date: this.fb.control("", [Validators.required]),
    all_customers: this.fb.control(false, []),
  });
  customers:any[] = [];
  constructor() {
    console.log(this.data);

    if(this.data){
      this.form.patchValue(this.data);
    }
  }

  onSave() {
    if (this.form.valid) {
      this.data = omit(this.form.value, ['all_customers']);
      if(this.form.value.all_customers){
        this.data.customer_id = null;
      }
      this.modalRef.close({ data: this.data, action: 'save' });
    }
  }
}
