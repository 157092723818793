<h1>Verpackungsgruppen</h1>
<button mat-raised-button routerLink="/packaging_groups">Übersicht</button>
<div *ngIf="list$ | async as list">
  <div *ngFor="let item of list" class="group">
    {{ item.name }}
    <ng-container *ngIf="item.products">
      <div *ngFor="let product of item.products">
        <ng-container *ngIf="product.name">
          {{ product.name }}
          <mat-icon (click)="clickDelete(item, product)">delete</mat-icon>
        </ng-container>
      </div>
    </ng-container>
    <mat-icon (click)="clickItem(item)">add</mat-icon>
  </div>
</div>
