import { Component, inject } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { debounceTime, forkJoin } from 'rxjs';
import { ProductService } from '@app/shared/data-products';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';

@Component({
  selector: 'app-product-batch-edit',
  templateUrl: './product-batch-edit.component.html',
  styleUrls: ['./product-batch-edit.component.scss']
})
export class ProductBatchEditComponent {
  productService = inject(ProductService);
  changes: {
    prices: { [key: string]: { amountGross: string, id: string, taxes: { taxgroups_id: string }[] } },
    data: { [key: string]: any },
    status: { [key: string]: any }
  } = { prices: {}, data: {}, status: {} };
  public columnDefs: ColDef[] = [
    { field: 'id' },
    { field: 'name', filter: 'agSetColumnFilter' },
    {
      headerName: 'Weight',
      editable: true, cellDataType: 'text',
      valueGetter: params => {
        return params.data.data.weight;
      },
      //valueFormatter: params => JSON.stringify(params.value),
      valueSetter: params => {
        try {
          if (!this.changes.data[params.data.id]) {
            this.changes.data[params.data.id] = params.data.data;
          }
          this.changes.data[params.data.id].weight = params.newValue;
          params.data.data.weight = params.newValue;
          return true;
        } catch (error) {
          console.error(error);
          params.data.data.weight = params.oldValue;
          return true;
        }
      }
    },
    {
      headerName: 'Units',
      editable: true, cellDataType: 'text',
      valueGetter: params => {
        return params.data.data.units;
      },
      //valueFormatter: params => JSON.stringify(params.value),
      valueSetter: params => {
        try {
          if (!this.changes.data[params.data.id]) {
            this.changes.data[params.data.id] = params.data.data;
          }
          this.changes.data[params.data.id].units = params.newValue;
          params.data.data.units = params.newValue;
          return true;
        } catch (error) {
          console.error(error);
          params.data.data.units = params.oldValue;
          return true;
        }
      }
    },
    {
      headerName: 'Unit',
      editable: true, cellDataType: 'text',
      valueGetter: params => {
        return params.data.data.unit;
      },
      //valueFormatter: params => JSON.stringify(params.value),
      valueSetter: params => {
        try {
          if (!this.changes.data[params.data.id]) {
            this.changes.data[params.data.id] = params.data.data;
          }
          this.changes.data[params.data.id].unit = params.newValue;
          params.data.data.unit = params.newValue;
          return true;
        } catch (error) {
          console.error(error);
          params.data.data.unit = params.oldValue;
          return true;
        }
      }
    },
    {
      field: 'status', editable: true, cellEditor: 'agSelectCellEditor', cellEditorParams: {
        values: ['published', 'variation', 'draft', 'internal']
      },
      valueSetter: (params) => {
        this.changes.status[params.data.id] = {
          status: params.newValue
        };
        params.data.status = params.newValue;
        return true;
      }
    },
    {
      field: 'price.amount_gross', editable: true, valueSetter: (params) => {
        this.changes.prices[params.data.id] = {
          amountGross: params.newValue,
          id: params.data.price.id,
          taxes: [{ taxgroups_id: params.data.price.taxes[0].taxgroups_id }]
        };
        params.data.price.amount_gross = params.newValue;
        return true;
      }
    }
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  rm = inject(RequestService);
  search = '';
  private gridApi!: GridApi;

  getRowId(params: any) {
    return params.data.id;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.setIsExternalFilterPresent(() => this.search != '');
    this.gridApi.setDoesExternalFilterPass((row) => JSON.stringify(row.data.name).toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) != -1);
    this.productService.getProductsForAdmin().subscribe(p => this.gridApi.setRowData(p));

  }

  triggerChange() {
    this.gridApi.onFilterChanged();
  }

  saveChanges() {

    Object.values(this.changes.prices).forEach(price => {
      this.rm.put('price/' + price.id, price).subscribe();
    });
    this.changes.prices = {};
    forkJoin(_.map(this.changes.data, (item, key) => {
      return this.productService.updateProduct(parseInt(key), { data: item });

    })).subscribe(() => this.changes.data = {});
    forkJoin(_.map(this.changes.status, (item, key) => {
      return this.productService.updateProduct(parseInt(key), item);

    })).subscribe(() => this.changes.status = {});
  }
}
