import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IProducer } from '@app/data-interfaces';
import { ProducerService } from '@cw/data-producers';

@Component({
  selector: 'app-producer',
  templateUrl: './producer.component.html',
  styleUrls: ['./producer.component.scss'],
})
export class ProducerComponent implements OnInit {
  producers$;
  searchControl = new UntypedFormControl('');

  searchText: string = '';
  public pageNumber: number = 0;
  limit: number = 50;
  constructor(private producerServicer: ProducerService) {
    this.producers$ = this.producerServicer.getProducers();
  }

  ngOnInit(): void {}

  getFilteredProducts(products: IProducer[]): IProducer[] {
    let result = products.filter(
      (product) =>
        product.name
          .toLocaleLowerCase()
          .indexOf(this.searchControl.value.toLocaleLowerCase()) !== -1
    );
    console.log(result);
    return result;
  }
  getPaginatedProducts(products: IProducer[]): IProducer[] {
    return products.slice(
      this.pageNumber * this.limit,
      (this.pageNumber + 1) * this.limit
    );
  }

  nextPage() {
    this.pageNumber++;
  }

  prevPage() {
    this.pageNumber--;
  }

  hasNextPage(products: IProducer[]): boolean {
    return this.pageNumber + 1 * this.limit < products.length;
  }

  identify(index: any, product: IProducer) {
    return product.id;
  }
}
