import { ApplicationRef } from '@angular/core';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { devTools } from '@ngneat/elf-devtools';
import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import { ClientSideRowModelModule } from 'ag-grid-community';

if (environment.production) {
  enableProdMode();
}
ModuleRegistry.registerModules([AllCommunityModule, ClientSideRowModelModule]);
// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: "legacy"});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {

// Register all community features

    devTools({
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  })
  .catch((err) => console.error(err));
