import { Component, OnInit } from '@angular/core';
import { defer, forkJoin, Observable, ObservedValueOf, of } from 'rxjs';
import { RequestService } from '@pisci/requestManager';
import { WorkBook, utils, read, WorkSheet } from 'xlsx';
//import * as JSZip from 'jszip';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import {
  catchError,
  delay,
  mergeMap,
  retry,
  retryWhen,
  take,
} from 'rxjs/operators';
import { ProductService } from '@app/shared/data-products';
import { cloneDeep } from 'lodash';
import { VoucherService } from '@app/shared/data-vouchers';
@Component({
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.scss'],
})
export class ImporterComponent implements OnInit {
  files: any;
  data: any;
  isPreviewed = false;
  loading = false;
  constructor(private voucherService: VoucherService) {}

  ngOnInit(): void {}

  setFiles(event: any) {
    this.files = event.target.files;
  }

  clickSubmitVouchers() {
    this.loading = true;

    forkJoin(
      this.data.map((voucherData: any) => {
        let body: any = {
          code: voucherData.code,
          type: voucherData.type,
          value: voucherData.value,
        };
        if (!body.type) {
          body.type = voucherData.discount_type;
        }
        if (voucherData.date_expires) {
          body.special_rules = { date_expires: voucherData.date_expires };
        }
        return this.voucherService.upsertVoucher(body).pipe(
          catchError((e) => {
            return of('not saved');
          })
        );
      })
    ).subscribe(() => {
      this.loading = false;
      alert('done');
    });
  }
  importVouchers() {
    for (let file of this.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (file.name.endsWith('xlsx') && !file.name.startsWith('~$')) {
          const bstr = e.target.result;
          const wb: WorkBook = read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws: WorkSheet = wb.Sheets[wsname];

          console.log(ws['!cols']);
          this.data = utils.sheet_to_json(ws, { header: 3 });
          console.log(this.data);

          this.isPreviewed = true;
        }
      };
      reader.readAsBinaryString(file);
    }
  }
}
