import { Injectable } from '@angular/core';

import { AuthService, AuthState } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthQuery {
  constructor(protected authService: AuthService) {}

  getValue() {
    return this.authService.getValue();
  }
  select(state: (state: AuthState) => any) {
    return this.authService.select(state);
  }
}
