<div class="actionbar">
  <button (click)="clickReport('action_orders_to_invoices', 'json')">
    Erstelle Rechnungen
  </button>
  <button (click)="clickReport('pdf_invoices')">Rechnungen drucken</button>
  <button (click)="clickReport('pdf_orders')">Lieferscheine drucken</button>
  <button (click)="clickReport('orders_by_product')">Excel Produktliste</button>
  <button (click)="clickReport('orders_by_customer')">Excel Kundenliste</button>
</div>
<div *ngIf="exports$ | async as exports">
  <div *ngFor="let e of exports">
    <input
      type="checkbox"
      [checked]="isSelected(e.id)"
      (change)="select(e.id, $event)"
      value="{{ e.id }}"
    />ExportSet Id{{ e.id }} - {{ e.type }}
    <div class="lines">
      <span class="line" *ngFor="let l of e.lines"
        >{{ l.lineable_type }}:&nbsp;{{ l.lineable_id }}
      </span>
    </div>
    <span class="actions"
      ><button (click)="clickAdd(e.id)" mat-raised-button>
        <mat-icon fontIcon="">add_circle</mat-icon>
      </button></span
    >
  </div>
</div>
