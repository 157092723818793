import { inject, Injectable } from '@angular/core';
import { RequestService } from '@pisci/requestManager';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionVacationService {
  rm = inject(RequestService)

  getVacations() {
    return this.rm.getAllPagesExpanding('subscription_vacation')
  }

  getVacation(id: string) {
    return this.rm.get(`subscription_vacation/${id}`)
  }

  createVacation(data: any) {
    return this.rm.post('subscription_vacation', data)
  }

  updateVacation(id: string, data: any) {
    return this.rm.put(`subscription_vacation/${id}`, data)
  }

  deleteVacation(id:string) {
    return this.rm.delete(`subscription_vacation/${id}`)
  }
}
