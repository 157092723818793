import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PackagingGroupService } from '@app/shared/data-packaging-groups';
import { PackagingGroupDetailComponent } from '../packaging-group-detail/packaging-group-detail.component';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-packaging-group',
  templateUrl: './packaging-group.component.html',
  styleUrls: ['./packaging-group.component.scss'],
})
export class PackagingGroupComponent implements OnInit {
  list$;
  constructor(
    private dataService: PackagingGroupService,
    private modalService: MatDialog
  ) {
    this.list$ = this.dataService.selectAll();
  }
  ngOnInit(): void {
    this.dataService.loadAll().subscribe();
  }

  clickAdd() {
    let ref = this.modalService.open(PackagingGroupDetailComponent);
  }

  clickItem(item: any) {
    let ref = this.modalService.open(PackagingGroupDetailComponent, {
      data: { item: item },
    });
  }
}
