import {createStore, select, setProp, withProps} from '@ngneat/elf';
import {
  deleteEntitiesByPredicate,
  entitiesPropsFactory,
  selectAllEntities,
  setEntities,
  updateEntities, updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';

import {localStorageStrategy, persistState} from '@ngneat/elf-persist-state';
import {
  ICartLine,
  ISubscription,
  ILineItemDTO,
  IPrice,
  IOrder,
  OrderDTO,
  IAddress,
  ISubscriptionAction, ISubscriptionLineItemDTO
} from '@app/data-interfaces';
import {Injectable} from "@angular/core";
import { removeEntities } from '@datorama/akita';

export interface CartEntityState {
  cartTotal: number;
  totalPrice?: IPrice;
  orderDraft?: IOrder;
  orderDTO: OrderDTO | undefined;
  shippingAddress?: IAddress;
  billingAddress?: IAddress;
  sameAddress?: boolean;
  userId?: number;
  customerId?: number;
  deliveryDate?: Date | null;
  data?: any;
  totalDiscount?: number;
  latestId: number;
  shipmentOption?: {
    id: number;
    price: IPrice;
    name: string;
  };
  paymentMethod?: string;
  vouchers: any[];
  customer_note?: string;
  subscription_changes: ISubscriptionAction[];
  subscriptions: ISubscription[];
  errors?:any;
}

export const {subscriptionsEntitiesRef, withSubscriptionsEntities} =
  entitiesPropsFactory('subscriptions');

export const {subscriptionLineEntitiesRef, withSubscriptionLineEntities} =
  entitiesPropsFactory('subscriptionLine');

export const {subscriptionLineChangesEntitiesRef, withSubscriptionLineChangesEntities} =
  entitiesPropsFactory('subscriptionLineChanges');


export const cartStore = createStore(
  {name: 'cart'},
  withEntities<ICartLine>(),
  withSubscriptionsEntities<ISubscription>(),
  withSubscriptionLineEntities<ISubscriptionLineItemDTO>(),
  withSubscriptionLineChangesEntities<ISubscriptionLineItemDTO>(),
  withProps<CartEntityState>({
    cartTotal: 0,
    orderDTO: undefined,
    latestId: 0,
    vouchers: [],
    subscription_changes: [],
    subscriptions: [],
  }),
);

export const persist = persistState(cartStore, {
  key: 'cartStore',
  storage: localStorageStrategy,
  preStoreInit: (value) => {
    if(value.orderDTO !== undefined) {
      value.orderDTO = undefined;
    }
    return value;
  }
});

persist.initialized$.subscribe(() => {
  const foundNull = cartStore.getValue().vouchers.findIndex((v: any) => v == null);
  if (foundNull !== -1) {
    cartStore.update(setProp('vouchers', []));
  }
});

@Injectable({
  providedIn: 'root'
})
export class CartStore {
  selectSubscriptionLineChanges() {
    return cartStore.pipe(selectAllEntities({ref: subscriptionLineChangesEntitiesRef}));
  }

  updateSubscriptionLineChangeByPredicate(predicate: (entity: ISubscriptionLineItemDTO) => boolean, callback: (entity: ISubscriptionLineItemDTO) => ISubscriptionLineItemDTO) {
    cartStore.update(updateEntitiesByPredicate(predicate, callback, {ref: subscriptionLineChangesEntitiesRef}));
  }
  updateSubscriptionLineChanges(id:number|string, line:ISubscriptionLineItemDTO) {
    cartStore.update(deleteEntitiesByPredicate((entity) => entity.subscription_id === line.subscription_id, {ref: subscriptionLineChangesEntitiesRef}),
    upsertEntities(line,{
        ref: subscriptionLineChangesEntitiesRef
      })
    );
  }

  setSubscriptionLineChanges(lines:ISubscriptionLineItemDTO[]) {
    cartStore.update(
      setEntities(lines, {ref: subscriptionLineChangesEntitiesRef})
    )
  }

  setSubscriptionLines(lines:ISubscriptionLineItemDTO[]) {
    cartStore.update(
      setEntities(lines, {ref: subscriptionLineEntitiesRef})
    )
  }

  removeSubscriptionChangeWithId(referenceId: any) {
    cartStore.update(
      setProp('subscription_changes', changes => {
        changes = changes.filter((change) => !(change.subscription && change.subscription?.id === referenceId) );
        return changes;
      }
    ));
  }

  removeSubscriptionLineChangeWithId(referenceId: any) {
    cartStore.update(
      deleteEntitiesByPredicate((entity) => entity.subscription_id === referenceId  ,  {ref: subscriptionLineChangesEntitiesRef})
    );
  }


  removeProductChangeWithId(referenceId: any) {
    cartStore.update(
      deleteEntitiesByPredicate((entity) => entity.productId === referenceId)
    );

  }
}
