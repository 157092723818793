import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@app/shared/data-pages';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, mergeMap, Observable, tap } from 'rxjs';
import _ from "lodash";
import { Editor, Toolbar } from 'ngx-editor';
@UntilDestroy()
@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.scss'],
})
export class PageDetailComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  public editor?: Editor;
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    // or, set options for link:
    //[{ link: { showOpenInNewTab: false } }, 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear', 'indent', 'outdent'],
    ['superscript', 'subscript'],
    ['undo', 'redo'],
  ];
  id?: number;
  formGroup = this.fb.group({
    title: this.fb.control('', Validators.required),
    body: this.fb.control('', Validators.required),
    data: this.fb.group({}),
  });
  constructor(
    private ps: PageService,
    private route: ActivatedRoute,

    private router: Router,
  ) {
    this.route.params
      .pipe(
        untilDestroyed(this),
        filter((params) => {
          if (params['id'] === 'new') return false;
          return true;
        }),
        mergeMap((params) => this.ps.selectPage(params['id'])),
        tap((page: any) => {
          this.id = page.id;
          this.formGroup.patchValue(page);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }

  clickSave() {
    let body = _.omit(this.formGroup.value, ['data']);
    if (this.id) {
      body = { ...body, id: this.id };
    }
    this.ps.upsertPage(body).subscribe(() => {
      this.router.navigateByUrl('pages');
    });
  }
}
