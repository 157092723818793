import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMiniCartModule } from '@app/ui-cart';
import { CartService } from '@app/shared/data-cart2';
import { IProduct } from '@app/data-interfaces';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Checkout2Component, FeatureCheckout2Module } from '@app/feature-checkout2';
import { BaseDuoAddressFormComponent } from '@cw/ui-base-views';

@Component({
  selector: 'app-admin-order-for-customer',
  standalone: true,
  imports: [CommonModule, UiMiniCartModule, UiMiniCartModule, ReactiveFormsModule, FeatureCheckout2Module, BaseDuoAddressFormComponent],
  templateUrl: './admin-order-for-customer.component.html',
  styleUrls: ['./admin-order-for-customer.component.scss']
})
export class AdminOrderForCustomerComponent implements OnInit {

  changeAddressControl = new FormControl(1);
  noteControl = new FormControl()
  addressControl = new FormControl();
  @Input()
  customerId?: number;

  showCart = false;

  cartService = inject(CartService);
  modalService = inject(MatDialog);

  addProductToCart(product: IProduct) {
    this.cartService.addProductQuantity(product, 1);
  }

  orderCart() {
    console.log(this.changeAddressControl.value);
    console.log(this.addressControl.value);
    this.cartService.setAddresses(this.addressControl.value.billing_address, this.addressControl.value.shipping_address);
    if(this.noteControl.value )
    {
      this.cartService.setCustomerNote(this.noteControl.value)
    }

    this.cartService.createOrderForCart().subscribe();
  }

  ngOnInit(): void {
    if (this.customerId) {
      this.showCart = true;
      this.cartService.setCustomerId(this.customerId);
    }
  }


}


