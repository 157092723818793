import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { InvoiceService } from '@app/shared/data-invoice';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';
import { isArray, isObject, isString } from 'lodash';
import { catchError, combineLatest, debounceTime, map, mergeMap, Observable, of, startWith, switchMap } from 'rxjs';
import { IOrder } from '@app/data-interfaces';
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { format, parseISO } from 'date-fns';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {
  searchControl = new UntypedFormControl('');
  orders$: Observable<IOrder[]>;
  router = inject(Router);
  public pageNumber = 0;
  limit = 50;
  orderFormGroup = new UntypedFormGroup({
    orderIds: new UntypedFormArray([])
  });
  ordersData: any[] = [];


  public columnDefs: ColDef[] = [
    {
      field: 'id'

    },
    {
      field: 'number'

    },
    { field: 'billing_address.name' },

    {
      field: 'billing_address.city'

    },
    {
      field: 'created_at',
      valueFormatter: (params) => {
        return format(parseISO(params.value), 'dd.MM.yyyy');
      }
    },
    { field: 'total' },

    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/invoices/' + data.id);
            }
          }
        ]
      }
    }
  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  // Data that gets displayed in the grid
  private gridApi!: GridApi;

  constructor(
    private orderService: InvoiceService,
    private rm: RequestService
  ) {
    this.orders$ = combineLatest([
      this.searchControl.valueChanges.pipe(debounceTime(1000), startWith(''))
    ]).pipe(
      switchMap(([search]) => {
        if (search == '') {
          return this.orderService.getAllInvoices();
        } else {
          return this.orderService.searchInvoices(search);
        }

      }),
      takeUntilDestroyed()
    );

  }

  getRowId(params: any) {
    return params.data.id;
  }

  onCellClicked(e: CellClickedEvent): void {
    // this.router.navigateByUrl('invoice/'+e.data.id);
  }

  clickInvoices() {
    const ids = this.gridApi.getSelectedRows().map(row => row.id);
    return this.rm
      .postGetFile('/invoices/pdf', {
        ids
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      )
      .subscribe((response: any) => {
        const blob = new Blob([response.body], {
          type: response.headers.get('content-type')
        });
        //  let fileName =  response.headers.get('content-disposition').split(';')[0];
        const fileName = 'report.pdf';
        const file = new File([blob], fileName, {
          type: response.headers.get('content-type')
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.orders$.pipe(debounceTime(1)).subscribe(orders => this.gridApi.setRowData(orders));
  }


}
