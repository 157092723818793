<ng-container *ngIf="list == false">

<button mat-button [matMenuTriggerFor]="menu">Abo Optionen</button>
<mat-menu #menu="matMenu">
  <button mat-button (click)="generateDraftOrders()">Abo Bestellungen generieren</button>
  <button mat-button (click)="closeDrafts()">Offene Abo Bestellungen abschließen</button>
</mat-menu>

</ng-container>

