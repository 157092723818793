import { Component, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IProduct } from '@app/data-interfaces';
import { ProductService } from '@app/shared/data-products';
import { combineLatest, debounceTime, mergeMap, Observable, startWith, Subject, switchMap } from 'rxjs';
import { CellClickedEvent, ColDef, GridApi, GridReadyEvent, TooltipShowEvent } from 'ag-grid-community';
import { OrderTooltipComponent } from '../../orders/order-tooltip/order-tooltip.component';
import { format, parseISO } from 'date-fns';
import { DataTableActionButtonComponent } from '@cw/ui-core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProductBatchModalComponent } from '../product-batch-modal/product-batch-modal.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent {
  searchControl = new UntypedFormControl('');
  products$: Observable<IProduct[]>;
  searchText = '';
  public pageNumber = 0;
  limit = 50;
  router = inject(Router);
  dialog = inject(MatDialog);

  public columnDefs: ColDef[] = [
    {
      field: 'select_column',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      field: 'id',
      tooltipComponent: OrderTooltipComponent,
      tooltipValueGetter: (data) => {
        console.log(data);
        return JSON.stringify(data.data);
      },
      onCellClicked: (data) => {
        this.router.navigateByUrl('/products/' + data.data.id);

      }
    },
    { field: 'status' },
    { field: 'name' },
    { field: 'availability',
      valueFormatter: (params) => {
        return params.value==1 ? 'Available' : 'Not Available';}
    },
    { field: 'is_subscribable' },


    {
      field: 'created_at',
      valueFormatter: (params) => {
        return format(parseISO(params.value), 'dd.MM.yyyy');
      }
    },


    {
      field: 'action',
      cellRenderer: DataTableActionButtonComponent,
      cellRendererParams: {
        buttons: [
          {
            label: 'Detail',
            action: (data: any) => {
              this.router.navigateByUrl('/products/' + data.id);
            }
          }
        ]
      }
    }
  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };
  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;
  private gridApi!: GridApi;
  private refresh$ =  new Subject();


  constructor(private productCatalog: ProductService) {
    this.products$ = combineLatest([this.refresh$.pipe(startWith(null)), this.searchControl.valueChanges.pipe(startWith(''))])
        .pipe(switchMap(([_,searchValue]) => {
          if (searchValue) {
            return this.productCatalog.searchProductsForAdmin(searchValue);
          }
          return this.productCatalog.getProductsForAdmin();
        }));

    this.searchControl.valueChanges.subscribe(() => {
      this.pageNumber = 0;
    });
  }

  onCellClicked(e: CellClickedEvent): void {
    if(e.colDef.field == "select_column") {
      this.gridApi.setNodesSelected({nodes: this.gridApi.getSelectedNodes().concat([e.node]), newValue:true});
      return;
    }
    // this.router.navigateByUrl('invoice/'+e.data.id);
  }

  showRowTooltip($event: TooltipShowEvent<any>) {
    // $event.tooltipGui.innerHTML = $event.context;
  }

  getRowId(params: any) {
    return params.data.id;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.products$.pipe(debounceTime(1)).subscribe(data => this.gridApi.setGridOption("rowData",data));
  }

  getFilteredProducts(products: IProduct[]): IProduct[] {
    const result = products.filter(
      (product) =>
        product.name
          .toLocaleLowerCase()
          .indexOf(this.searchControl.value.toLocaleLowerCase()) !== -1
    );
    console.log(result);
    return result;
  }

  getPaginatedProducts(products: IProduct[]): IProduct[] {
    return products.slice(
      this.pageNumber * this.limit,
      (this.pageNumber + 1) * this.limit
    );
  }

  nextPage() {
    this.pageNumber++;
  }

  prevPage() {
    this.pageNumber--;
  }

  hasNextPage(products: IProduct[]): boolean {
    return this.pageNumber + 1 * this.limit < products.length;
  }

  identify(index: any, product: IProduct) {
    return product.id;
  }

  batchEdit() {
    const ref = this.dialog.open(ProductBatchModalComponent, {
      data: { ids: this.gridApi.getSelectedRows().map((i) => i.id) }
    });
    ref.afterClosed().subscribe(() => {
      this.gridApi.deselectAll();
      this.refresh$.next(1);
    });
  }
}
