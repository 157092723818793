import { Injectable } from '@angular/core';
import { RequestService } from '@pisci/requestManager';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private rm: RequestService) {}

  public selectPages() {
    return this.rm.getAllPages('page');
  }

  public selectPage(id: number) {
    return this.rm.get('page/' + id);
  }

  public upsertPage(page: any) {
    if (page.id) {
      return this.rm.put('page/' + page.id, _.omit(page, ['id']));
    } else {
      return this.rm.post('page', page);
    }
  }
}
