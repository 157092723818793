<div
  class="product-form"
  *ngIf="
    product && (!product.data || product.data?.is_voucher_product !== true)
  "
>
  <div>
    <mat-form-field
      ><mat-label>Status</mat-label
      ><mat-select [(ngModel)]="product.status">
        <mat-option value="published">Veröffentlicht</mat-option>
        <mat-option value="draft">Entwurf</mat-option>
        <mat-option value="internal">Intern</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
    ><mat-label>Availability</mat-label
    ><mat-select [(ngModel)]="product.availability">
      <mat-option [value]="1">Verfügbar</mat-option>
      <mat-option [value]="0">Nicht verfügbar</mat-option>
    </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
    ><mat-label>Abo Status</mat-label
    ><mat-select [(ngModel)]="product.subscription_available">
      <mat-option [value]="true">Abo Produkt</mat-option>
      <mat-option [value]="false">Abo nicht möglich</mat-option>
    </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      ><mat-label>Name</mat-label><input matInput [(ngModel)]="product.name"
    /></mat-form-field>
  </div>
  <div>
    <mat-form-field
      ><mat-label>Bruttopreis</mat-label
      ><input matInput [(ngModel)]="product.price.amount_gross"
    /></mat-form-field>
  </div>
  <div>
    <mat-form-field
      ><mat-label>Barcode</mat-label><input matInput [(ngModel)]="product.ean"
    /></mat-form-field>
  </div>
  <div>
    <mat-form-field
      ><mat-label>Kurzbeschreibung</mat-label
      ><input matInput [(ngModel)]="product.short_description"
    /></mat-form-field>
  </div>
  <div>
    <mat-form-field
      ><mat-label>Beschreibung Lieferschein</mat-label
      ><textarea
        matInput
        [(ngModel)]="product.data.shipping_description"
      ></textarea>
    </mat-form-field>
  </div>
  <div>
    <ckeditor [(ngModel)]="product.description" [editor]="Editor"></ckeditor>
  </div>
  <div *ngIf="taxgroups$ | async as taxgroups">
    <mat-form-field
      ><mat-label>Steuersatz</mat-label
      ><mat-select [(ngModel)]="taxRate">
        <mat-option *ngFor="let tax of taxgroups" value="{{ tax.id }}">{{
          tax.label
        }}</mat-option>
      </mat-select></mat-form-field
    >
  </div>
  <div>
    <div *ngIf="producers$ | async as producers">
      <mat-form-field>
        <mat-label> Produzent</mat-label>
        <mat-select [formControl]="producerCtrl">
          <mat-option [value]="null">Keiner</mat-option>
          <mat-option *ngFor="let cat of producers" [value]="cat.id">
            {{ cat.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <div *ngIf="categories$ | async as categories">
      <mat-form-field>
        <mat-label> Kategorie</mat-label>
        <mat-select [(ngModel)]="category_id">
          <mat-option [value]="null">Standard</mat-option>
          <mat-option *ngFor="let cat of categories" [value]="cat.id">
            {{ cat.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field class="demo-chip-list">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let tag of tags"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeTag(tag)"
        >
          {{ tag }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Tags..."
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectedTag($event)"
      >
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          {{ tag }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="image-wrapper">
    <h4>Bild</h4>
    <p>Das erste Bild wird als Bild in der Übersicht angezeigt</p>
    <div
      *ngIf="product.images"
      class="image-list"
      cdkDropList
      [cdkDropListData]="product.images"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="draggable-image" *ngFor="let image of product.images" cdkDrag>
        <img [src]="'https://api.logistikmanager.pisci.eu/' + image.path" />
        <img
          [style.width]="'150px'"
          *cdkDragPreview
          [src]="'https://api.logistikmanager.pisci.eu/' + image.path"
          class="dragImage"
        />
        <button mat-button color="warn" (click)="deleteImage(image)">
          Bild löschen
        </button>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      </div>
    </div>
    Bild hochladen und dann speichern.
    <input type="file" id="file" (change)="handleFileInput($event)" />
  </div>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
  <button mat-raised-button color="warn" (click)="deleteProduct()">
    Löschen
  </button>
  <div hidden>
    <button (click)="makeVoucher()">In Gutscheinprodukt umwandeln</button>
    <button (click)="makePriceEditable()">Preis Editierbar machen</button>
  </div>

  <div *ngIf="product && product.data?.is_voucher_product == true">
    Darf nicht bearbeitet werden.
  </div>
</div>
