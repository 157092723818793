import {IPrice, Price} from './price';
import {IProducer} from './producer';
import {IStock} from './stockmanaging';

export interface ISupplier {
  id: number;
  name: string;
  pivot?: {
    sku: string;
  };
}

export interface ITag {
  id: number;
  name: string;
  media: any;
}

export interface IProduct {
  id: number;
  name: string;
  sku?: string;
  data: any;
  status: string;
  description: string;
  short_description: string;
  categories: any[];
  tags: ITag[];
  images: any[];
  price: IPrice | Price |Partial<IPrice>;
  stackable?: boolean;
  ean?: string;
  stocks?: IStock[];
  suppliers?: ISupplier[];
  producers?: number[] | IProducer[] | (number | IProducer)[];
  variations?: IProduct[];
  updated_at: string;
  created_at: string;
  stock_status?: string;
  stock_amount?: number;
  subscription_available?: boolean;
  is_subscribable: boolean;
}

export class Product implements IProduct {
  id: number;
  name: string;
  sku?: string;
  data: any;
  status: string;
  description: string;
  short_description: string;
  categories: any[];
  tags: ITag[];
  images: any[];
  price: Price;
  stackable?: boolean;
  ean?: string;
  stocks?: IStock[];
  suppliers?: ISupplier[];
  producers?: number[] | IProducer[] | (number | IProducer)[];
  variations?: IProduct[];
  updated_at: string;
  created_at: string;
  stock_status?: string;
  stock_amount?: number;
  subscription_available?: boolean | undefined;
  is_subscribable = false;

  constructor(iproduct: IProduct) {
    this.id = iproduct.id;
    this.name = iproduct.name;
    this.sku = iproduct.sku;
    this.data = iproduct.data;
    this.status = iproduct.status;
    this.description = iproduct.description;
    this.short_description = iproduct.short_description;
    this.categories = iproduct.categories;
    this.tags = iproduct.tags;
    this.images = iproduct.images;
    this.price = new Price(iproduct.price);
    this.stackable = iproduct.stackable;
    this.ean = iproduct.ean;
    this.stocks = iproduct.stocks;
    this.suppliers = iproduct.suppliers;
    this.producers = iproduct.producers;
    this.variations = iproduct.variations;
    this.updated_at = iproduct.updated_at;
    this.created_at = iproduct.created_at;
    this.stock_status = iproduct.stock_status;
    this.stock_amount = iproduct.stock_amount;
    this.subscription_available = iproduct.subscription_available;
    this.is_subscribable = iproduct.is_subscribable;

  }


}
