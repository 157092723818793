<h3>Deine Aboänderungen</h3>
<div class="cartWrapper">
  <table class="cartOverview">
    <thead>
      <th class="name">Name</th>
      <th class="quantity">Menge</th>

      <th class="tax">Intervall</th>
      <th class="price">Startdatum</th>
      <th>Aktion</th>
      <th></th>
    </thead>

    <ng-container *ngIf="changes$ | async as changes">
      <ng-container *ngIf="products$ | async as products">
        <tbody *ngIf="changes">
          <tr *ngFor="let element of changes; let index = index">
            <td class="name">
              {{ getProductName(products, element.subscription.product_id) }}
            </td>
            <td class="quantity">{{ element.subscription.quantity }}</td>

            <td>
              {{ element.subscription.interval_days }}
            </td>
            <td>
              {{ element.subscription.startingFrom }}
            </td>
            <td>{{ element.action }}</td>
            <td>
              <button
                class="btn_remove clickable"
                (click)="removeLineClicked(index)"
              >
                <img alt="remove" *ngIf="removeIconPath" src="{{ removeIconPath }}" />
                <mat-icon *ngIf="!removeIconPath" fontIcon="delete"
                  >delete</mat-icon
                >
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container></ng-container
    >
  </table>
</div>
