<div *ngIf="voucher$ | async as v">
  <div>
    <mat-form-field>
      <mat-select [(ngModel)]="v.type">
        <mat-option value="value">Wertgutschein</mat-option>
        <mat-option value="discount_percent"
          >Rabattgutschein (Prozentual)</mat-option
        >
        <mat-option value="discount_value">Rabattgutschein (Wert)</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Code</mat-label>
      <input matInput placeholder="code" [(ngModel)]="v.code" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Wert</mat-label>
      <input matInput placeholder="wert" [(ngModel)]="v.value" type="number" />
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" (click)="saveVoucher(v)">
      Speichern
    </button>
    <button mat-raised-button color="secondary" routerLink="/vouchers">
      Zurück
    </button>
    <button mat-raised-button color="error" (click)="deleteVoucher(v)">
      Löschen
    </button>
  </div>
</div>
