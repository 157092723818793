<div>
  <mat-form-field appearance="fill">
    <mat-label>Mandant</mat-label>
    <input [(ngModel)]="tenant_id" matInput />
  </mat-form-field>
</div>
<div>
  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input [(ngModel)]="email" matInput />
  </mat-form-field>
</div>
<div>
  <mat-form-field appearance="fill">
    <mat-label>Password</mat-label>
    <input type="password" [(ngModel)]="password" matInput />
  </mat-form-field>
</div>
<div>
  <button mat-button color="primary" (click)="login()">Einloggen</button>
</div>
