import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICustomer } from '@app/data-interfaces';

@Component({
  selector: 'cw-base-view-customer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './base-view-customer.component.html',
  styleUrls: ['./base-view-customer.component.scss']
})
export class BaseViewCustomerComponent {
  @Input({required:true}) customer!: ICustomer;
}
