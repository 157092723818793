import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { RequestService } from '@pisci/requestManager';
import {
  selectAllEntities,
  selectEntityByPredicate,
  setEntities, upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { filter, map, tap } from 'rxjs';
interface ISettingsValue {
  id: number;
  key: string;
  data: any;
  hidden: boolean;
}

const svStore = createStore(
  { name: 'settingsvalue' },
  withEntities<ISettingsValue>()
);

@Injectable({
  providedIn: 'root',
})
export class SettingsvalueService {
  constructor(private rm: RequestService) {}

  select() {
    return svStore.pipe(selectAllEntities());
  }
  selectKey(keySearch: string) {
    return svStore.pipe(
      selectAllEntities(),
      map((sv) => {
        const result = sv.filter((e) => e.key == keySearch);
        return result[0];
      }),
      filter( e => e !== undefined)
    );
  }

  loadKey(key: string) {
    return this.rm.searchAllPagesExpanding('settingsValue/search', {
      filters: [{
        operator: `=`,
        field: 'key',
        value: key
      }],

    }).pipe(
      tap((r: any) => {
        svStore.update(upsertEntities(r));
      })
    );
  }
  load() {
    return this.rm.getAllPages('settingsValue').pipe(
      tap((r: any) => {
        svStore.update(setEntities(r));
      })
    );
  }

  saveSetting(key: string, value: any) {
    return this.rm.post('settingsValue', { key, data: value });
    console.log('dummySave', key, value);
  }
}
