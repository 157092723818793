<dl>
  <dt>id</dt>
  <dd>{{customer.id}}</dd>
  <dt>Email</dt>
  <dd>  {{customer.email}}</dd>
  <dt>Vorname</dt>
  <dd>{{customer.firstname}}</dd>
  <dt>Nachname</dt>
  <dd>  {{customer.lastname}}</dd>
  <dt>Geburtsdatum</dt>
  <dd> {{customer.date_of_birth}}</dd>

  <dt>Geschlecht</dt>
  <dd>  {{customer.gender}}</dd>
  <dt>Telefonnummer</dt>
  <dd> {{customer.phone_number}}</dd>


</dl>

