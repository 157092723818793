<div *ngIf="category$ | async as category">
  <div>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput placeholder="name" [(ngModel)]="category.name" />
    </mat-form-field>
    <div>
      <mat-form-field>
        <mat-select [(ngModel)]="category.visibility">
          <mat-option value="">Standard</mat-option>
          <mat-option value="hidden">Versteckt</mat-option>
          <mat-option value="internal">Intern</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="actions">
    <button mat-raised-button color="primary" (click)="saveCategory(category)">
      Speichern
    </button>
    <button mat-raised-button color="secondary" routerLink="/categories">
      Zurück
    </button>
    <button mat-raised-button color="error" (click)="deleteCategory(category)">
      Löschen
    </button>
  </div>
</div>
