<div class="wrapper">
  <h4>Produkt Suchen und anklicken</h4>
  <form>
    <input [formControl]="searchControl" />
  </form>
  <div *ngIf="results$ | async as result" class="result">
    <div (click)="clickResult(product)" *ngFor="let product of result">
      <h4>{{ product.name }}</h4>
      <span>{{ product.price.amount_gross }} €</span>
    </div>
  </div>
</div>
