<header>
  <div><h1>PISCI Admin</h1></div>
  <div (click)="clickLogout()">logout</div>
</header>
<button mat-raised-button (click)="menuToggle()">Menü</button>
<div class="main" [ngClass]="{'menu-active':showMenu}">

<app-menu></app-menu>
  <main><router-outlet></router-outlet></main>
</div>
<div class="loading" *ngIf="loadingService.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
