import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IWarehouse } from '@app/data-interfaces';
import { WarehouseService } from '@app/shared/data-stockmanaging';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
})
export class WarehouseComponent implements OnInit {
  warehouses$?: Observable<any[]>;
  constructor(
    private warehouseService: WarehouseService,
    private router: Router
  ) {
    this.warehouses$ = this.warehouseService.selectWarehouses();
  }

  ngOnInit(): void {
    this.warehouseService.load().subscribe();
  }

  clickAddWarehouse() {
    this.router.navigateByUrl('stockmanaging/warehouse/new');
  }
  clickEdit(warehouse: IWarehouse) {
    this.router.navigateByUrl('stockmanaging/warehouse/' + warehouse.id);
  }
}
