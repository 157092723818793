import { Injectable } from '@angular/core';

import { shareReplay, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { syncState } from 'elf-sync-state';
import { dispatch } from '@ngneat/effects';
import { authLogoutDoneAction } from './auth.action';

export interface AuthState {
  jwt: string;
  tenant_id: string | null;
  user: any;
  expires?: number;
}
const authStore = createStore(
  { name: 'auth' },
  withProps<AuthState>({
    jwt: '',
    tenant_id: null,
    user: null,
  }),
);

export const persist = persistState(authStore, {
  key: 'auth',
  storage: localStorageStrategy,

});
syncState(authStore);

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  onSubject = new Subject<{ key: string | null; value: string }>();
  tenant_id: string | null = null;
  jwt = '';

  constructor() {

    this.select((state) => state.jwt).subscribe((jwt) => {
      this.jwt = jwt;
    });
    this.select((state) => state.tenant_id).subscribe((tenant_id) => {
      this.tenant_id = tenant_id;
    });
  }


  onJwtRestored() {
    return persist.initialized$;
  }

  getValue() {
    return authStore.getValue();
  }

  select(stateFunction: (state: AuthState) => any) {
    return authStore.pipe(select(stateFunction));
  }

  selectIsLoggedIn() {
    return authStore.pipe(
      select((state) => state.user),
      map((user) => user !== null),
    );
  }

  setTenantId(tenant_id: string) {
    authStore.update(setProps({ tenant_id: tenant_id }));
    this.tenant_id = tenant_id;
  }

  setJwt(jwt: string, expires: number) {
    //const jwt_decoded = jwt_decode<JwtPayload>(jwt);
    authStore.update(setProps({ jwt, expires }));
    this.jwt = jwt;
  }

  updateProps(props: Partial<AuthState>) {
    authStore.update(setProps(props));
  }

  deleteLoginData() {
    authStore.update(
      setProps({ jwt: '', user: null, tenant_id: this.tenant_id }),
    );
    //this.store.destroy();
  }

  logout() {
    this.deleteLoginData();
    dispatch(authLogoutDoneAction());
  }

  startMessageListening() {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  userConfirmedEvent() {
    return this.onSubject.asObservable().pipe(
      tap((event) => {
        console.log(event);
      }),
      filter((value) => {
        return value.key == 'message' && value.value == 'user_confirmed';
      }),
    );
  }

  rerouteEvent() {
    return this.onSubject.asObservable().pipe(
      filter((value) => {
        return value.key == 'message' && value.value == 'route_checkout';
      }),
    );
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage && event.newValue !== null) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({ key: event.key, value: v });
    }
  }
}
