import { Component, EventEmitter, forwardRef, inject, Input, NgZone, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import {
  AbstractControl, ControlValueAccessor,
  FormControlStatus,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule, UntypedFormArray,
  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors,
  Validators
} from '@angular/forms';
import { combineLatest, debounceTime, distinctUntilChanged, filter, Observable, of, take, tap } from 'rxjs';
import { Checkout2Service } from '@app/feature-checkout2';
import { addressesChanged, CartService, dataChanged } from '@app/shared/data-cart2';
import { Actions } from '@ngneat/effects-ng';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngneat/effects';
import { emitOnce } from '@ngneat/elf';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-base-duo-address-form',
  standalone: true,
  imports: [CommonModule, PaginatorModule, ReactiveFormsModule],
  templateUrl: './base-duo-address-form.component.html',
  styleUrls: ['./base-duo-address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseDuoAddressFormComponent),
      multi: true
    }
  ]
})
@UntilDestroy()
export class BaseDuoAddressFormComponent implements ControlValueAccessor {
  @Input()
  mode = 'action';
  @Input()
  flowControl?: Observable<string>;
  @Output()
  formChanged = new EventEmitter();
  shippingEqualsInvoice = true;

  @Input()
  allowedZips: string[] = [];
  val = ''; // this is the updated value that the class accesses
  private fb = inject(UntypedFormBuilder);
  form = this.fb.group({
    billing_address: this.getAddressForm(),
    shipping_address: this.getAddressForm(),
    sameAddress: this.fb.control(true)
  });

  constructor() {
    this.form.valueChanges.pipe(debounceTime(100)).subscribe((form) => {
      this.formChanged.next(form);
      this.onChange(form);
      this.onTouch(form);
    });


    this.initShippingEqualsPipe();

    console.log(this.form);
  }

  set value(val: any) {
    if (val === null)
    return;// this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.form.setValue(val);
    this.onChange(val);
    this.onTouch(val);
  }

  onChange = (val: any) => {
  };

  onTouch = (val: any) => {
  };

// this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

// upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

// upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  isRequiredField(field: string) {
    const form_field = this.form.get(field);
    if (!form_field?.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  getErrorMessage(controlName: string) {
    return this.form.get(controlName)?.errors;
  }

  hasErrors() {
    return this.form.touched && !this.form.valid;
  }

  getErrors(): { [key: string]: any } {
    let errors = {};
    this.aggregateErrors(this.form, errors);
    return errors;
  }

  public aggregateErrors(
    form: UntypedFormGroup | UntypedFormArray,
    errors: ValidationErrors
  ): void {
    Object.keys(form.controls).forEach((key: string) => {
      const abstractControl = form.get(key);

      if (
        abstractControl instanceof UntypedFormGroup ||
        abstractControl instanceof UntypedFormArray
      ) {
        this.aggregateErrors(abstractControl, errors);
      }

      if (
        abstractControl instanceof UntypedFormControl &&
        abstractControl.errors
      ) {
        errors[key] = abstractControl.errors;
      }
    });
  }

  initShippingEqualsPipe() {
    this.form.valueChanges.pipe(distinctUntilChanged((a, b) => isEqual(a.sameAddress, b.sameAddress)), untilDestroyed(this)).subscribe(v => {
      this.shippingEqualsInvoice = v.sameAddress;
    });
    this.form.valueChanges
      .pipe(
        debounceTime(100),
        filter(() => this.shippingEqualsInvoice),
        distinctUntilChanged((a, b) => isEqual(a.billing_address, b.billing_address)),
        tap((form) => {
          console.log(form);
          this.form.patchValue(
            {
              shipping_address: this.form.value.billing_address
            },
            {}
          );
          return of(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getAddressForm() {
    return this.fb.group({
      firstname: this.fb.control(''),
      lastname: this.fb.control(''),
      name: this.fb.control(''),
      street: this.fb.control('', Validators.required),
      street_number: this.fb.control('', Validators.required),
      street_2: this.fb.control(''),
      zip: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      country: this.fb.control('AT')
    });
  }


}
